*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 8px 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, h5, h4, h3, h2, h1 {
  margin-top: 0;
  margin-bottom: 4px;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1 {
    font-size: 2.5rem;
  }
}

h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2 {
    font-size: 2rem;
  }
}

h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3 {
    font-size: 1.75rem;
  }
}

h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4 {
    font-size: 1.5rem;
  }
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 0.875em;
}

mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 32px;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1500px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1460px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1500px;
  --bs-breakpoint-fullhd: 1920px;
}

.row {
  --bs-gutter-x: 32px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 8px;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 8px;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 16px;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 16px;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 24px;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 24px;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 32px;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 32px;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 40px;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 40px;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 48px;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 48px;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 56px;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 56px;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 64px;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 64px;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 8px;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 8px;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 16px;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 16px;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 24px;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 24px;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 32px;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 32px;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 40px;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 40px;
  }
  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 48px;
  }
  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 48px;
  }
  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 56px;
  }
  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 56px;
  }
  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 64px;
  }
  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 64px;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 8px;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 8px;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 16px;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 16px;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 24px;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 24px;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 32px;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 32px;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 40px;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 40px;
  }
  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 48px;
  }
  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 48px;
  }
  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 56px;
  }
  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 56px;
  }
  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 64px;
  }
  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 64px;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 8px;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 8px;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 16px;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 16px;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 24px;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 24px;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 32px;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 32px;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 40px;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 40px;
  }
  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 48px;
  }
  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 48px;
  }
  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 56px;
  }
  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 56px;
  }
  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 64px;
  }
  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 64px;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 8px;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 8px;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 16px;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 16px;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 24px;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 24px;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 32px;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 32px;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 40px;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 40px;
  }
  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 48px;
  }
  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 48px;
  }
  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 56px;
  }
  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 56px;
  }
  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 64px;
  }
  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 64px;
  }
}
@media (min-width: 1500px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 8px;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 8px;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 16px;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 16px;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 24px;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 24px;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 32px;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 32px;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 40px;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 40px;
  }
  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 48px;
  }
  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 48px;
  }
  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 56px;
  }
  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 56px;
  }
  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 64px;
  }
  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 64px;
  }
}
@media (min-width: 1920px) {
  .col-fullhd {
    flex: 1 0 0%;
  }
  .row-cols-fullhd-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-fullhd-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-fullhd-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-fullhd-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-fullhd-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-fullhd-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-fullhd-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-fullhd-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-fullhd-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-fullhd-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-fullhd-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-fullhd-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-fullhd-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-fullhd-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-fullhd-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-fullhd-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-fullhd-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-fullhd-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-fullhd-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-fullhd-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-fullhd-0 {
    margin-left: 0;
  }
  .offset-fullhd-1 {
    margin-left: 8.33333333%;
  }
  .offset-fullhd-2 {
    margin-left: 16.66666667%;
  }
  .offset-fullhd-3 {
    margin-left: 25%;
  }
  .offset-fullhd-4 {
    margin-left: 33.33333333%;
  }
  .offset-fullhd-5 {
    margin-left: 41.66666667%;
  }
  .offset-fullhd-6 {
    margin-left: 50%;
  }
  .offset-fullhd-7 {
    margin-left: 58.33333333%;
  }
  .offset-fullhd-8 {
    margin-left: 66.66666667%;
  }
  .offset-fullhd-9 {
    margin-left: 75%;
  }
  .offset-fullhd-10 {
    margin-left: 83.33333333%;
  }
  .offset-fullhd-11 {
    margin-left: 91.66666667%;
  }
  .g-fullhd-0,
  .gx-fullhd-0 {
    --bs-gutter-x: 0;
  }
  .g-fullhd-0,
  .gy-fullhd-0 {
    --bs-gutter-y: 0;
  }
  .g-fullhd-1,
  .gx-fullhd-1 {
    --bs-gutter-x: 8px;
  }
  .g-fullhd-1,
  .gy-fullhd-1 {
    --bs-gutter-y: 8px;
  }
  .g-fullhd-2,
  .gx-fullhd-2 {
    --bs-gutter-x: 16px;
  }
  .g-fullhd-2,
  .gy-fullhd-2 {
    --bs-gutter-y: 16px;
  }
  .g-fullhd-3,
  .gx-fullhd-3 {
    --bs-gutter-x: 24px;
  }
  .g-fullhd-3,
  .gy-fullhd-3 {
    --bs-gutter-y: 24px;
  }
  .g-fullhd-4,
  .gx-fullhd-4 {
    --bs-gutter-x: 32px;
  }
  .g-fullhd-4,
  .gy-fullhd-4 {
    --bs-gutter-y: 32px;
  }
  .g-fullhd-5,
  .gx-fullhd-5 {
    --bs-gutter-x: 40px;
  }
  .g-fullhd-5,
  .gy-fullhd-5 {
    --bs-gutter-y: 40px;
  }
  .g-fullhd-6,
  .gx-fullhd-6 {
    --bs-gutter-x: 48px;
  }
  .g-fullhd-6,
  .gy-fullhd-6 {
    --bs-gutter-y: 48px;
  }
  .g-fullhd-7,
  .gx-fullhd-7 {
    --bs-gutter-x: 56px;
  }
  .g-fullhd-7,
  .gy-fullhd-7 {
    --bs-gutter-y: 56px;
  }
  .g-fullhd-8,
  .gx-fullhd-8 {
    --bs-gutter-x: 64px;
  }
  .g-fullhd-8,
  .gy-fullhd-8 {
    --bs-gutter-y: 64px;
  }
}
.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.align-baseline {
  vertical-align: baseline;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.align-text-bottom {
  vertical-align: text-bottom;
}

.align-text-top {
  vertical-align: text-top;
}

.float-start {
  float: left;
}

.float-end {
  float: right;
}

.float-none {
  float: none;
}

.object-fit-contain {
  object-fit: contain;
}

.object-fit-cover {
  object-fit: cover;
}

.object-fit-fill {
  object-fit: fill;
}

.object-fit-scale {
  object-fit: scale-down;
}

.object-fit-none {
  object-fit: none;
}

.opacity-0 {
  opacity: 0;
}

.opacity-25 {
  opacity: 0.25;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-100 {
  opacity: 1;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-x-visible {
  overflow-x: visible;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-y-visible {
  overflow-y: visible;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

.d-block {
  display: block;
}

.d-grid {
  display: grid;
}

.d-inline-grid {
  display: inline-grid;
}

.d-table {
  display: table;
}

.d-table-row {
  display: table-row;
}

.d-table-cell {
  display: table-cell;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.d-none {
  display: none;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
}

.shadow-none {
  box-shadow: none;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.position-sticky {
  position: sticky;
}

.top-0 {
  top: 0;
}

.top-50 {
  top: 50%;
}

.top-100 {
  top: 100%;
}

.bottom-0 {
  bottom: 0;
}

.bottom-50 {
  bottom: 50%;
}

.bottom-100 {
  bottom: 100%;
}

.start-0 {
  left: 0;
}

.start-50 {
  left: 50%;
}

.start-100 {
  left: 100%;
}

.end-0 {
  right: 0;
}

.end-50 {
  right: 50%;
}

.end-100 {
  right: 100%;
}

.translate-middle {
  transform: translate(-50%, -50%);
}

.translate-middle-x {
  transform: translateX(-50%);
}

.translate-middle-y {
  transform: translateY(-50%);
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.border-0 {
  border: 0;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.border-top-0 {
  border-top: 0;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.border-end-0 {
  border-right: 0;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.border-bottom-0 {
  border-bottom: 0;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.border-start-0 {
  border-left: 0;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity));
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity));
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity));
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity));
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity));
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity));
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity));
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity));
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity));
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity));
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle);
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle);
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle);
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle);
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle);
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle);
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle);
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle);
}

.border-1 {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-3 {
  border-width: 3px;
}

.border-4 {
  border-width: 4px;
}

.border-5 {
  border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}

.w-auto {
  width: auto;
}

.mw-100 {
  max-width: 100%;
}

.vw-100 {
  width: 100vw;
}

.min-vw-100 {
  min-width: 100vw;
}

.h-25 {
  height: 25%;
}

.h-50 {
  height: 50%;
}

.h-75 {
  height: 75%;
}

.h-100 {
  height: 100%;
}

.h-auto {
  height: auto;
}

.mh-100 {
  max-height: 100%;
}

.vh-100 {
  height: 100vh;
}

.min-vh-100 {
  min-height: 100vh;
}

.flex-fill {
  flex: 1 1 auto;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-stretch {
  align-items: stretch;
}

.align-content-start {
  align-content: flex-start;
}

.align-content-end {
  align-content: flex-end;
}

.align-content-center {
  align-content: center;
}

.align-content-between {
  align-content: space-between;
}

.align-content-around {
  align-content: space-around;
}

.align-content-stretch {
  align-content: stretch;
}

.align-self-auto {
  align-self: auto;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-end {
  align-self: flex-end;
}

.align-self-center {
  align-self: center;
}

.align-self-baseline {
  align-self: baseline;
}

.align-self-stretch {
  align-self: stretch;
}

.order-first {
  order: -1;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-last {
  order: 6;
}

.m-0 {
  margin: 0;
}

.m-1 {
  margin: 8px;
}

.m-2 {
  margin: 16px;
}

.m-3 {
  margin: 24px;
}

.m-4 {
  margin: 32px;
}

.m-5 {
  margin: 40px;
}

.m-6 {
  margin: 48px;
}

.m-7 {
  margin: 56px;
}

.m-8 {
  margin: 64px;
}

.m-auto {
  margin: auto;
}

.mx-0 {
  margin-right: 0;
  margin-left: 0;
}

.mx-1 {
  margin-right: 8px;
  margin-left: 8px;
}

.mx-2 {
  margin-right: 16px;
  margin-left: 16px;
}

.mx-3 {
  margin-right: 24px;
  margin-left: 24px;
}

.mx-4 {
  margin-right: 32px;
  margin-left: 32px;
}

.mx-5 {
  margin-right: 40px;
  margin-left: 40px;
}

.mx-6 {
  margin-right: 48px;
  margin-left: 48px;
}

.mx-7 {
  margin-right: 56px;
  margin-left: 56px;
}

.mx-8 {
  margin-right: 64px;
  margin-left: 64px;
}

.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-1 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.my-2 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.my-3 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.my-4 {
  margin-top: 32px;
  margin-bottom: 32px;
}

.my-5 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.my-6 {
  margin-top: 48px;
  margin-bottom: 48px;
}

.my-7 {
  margin-top: 56px;
  margin-bottom: 56px;
}

.my-8 {
  margin-top: 64px;
  margin-bottom: 64px;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 8px;
}

.mt-2 {
  margin-top: 16px;
}

.mt-3 {
  margin-top: 24px;
}

.mt-4 {
  margin-top: 32px;
}

.mt-5 {
  margin-top: 40px;
}

.mt-6 {
  margin-top: 48px;
}

.mt-7 {
  margin-top: 56px;
}

.mt-8 {
  margin-top: 64px;
}

.mt-auto {
  margin-top: auto;
}

.me-0 {
  margin-right: 0;
}

.me-1 {
  margin-right: 8px;
}

.me-2 {
  margin-right: 16px;
}

.me-3 {
  margin-right: 24px;
}

.me-4 {
  margin-right: 32px;
}

.me-5 {
  margin-right: 40px;
}

.me-6 {
  margin-right: 48px;
}

.me-7 {
  margin-right: 56px;
}

.me-8 {
  margin-right: 64px;
}

.me-auto {
  margin-right: auto;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 8px;
}

.mb-2 {
  margin-bottom: 16px;
}

.mb-3 {
  margin-bottom: 24px;
}

.mb-4 {
  margin-bottom: 32px;
}

.mb-5 {
  margin-bottom: 40px;
}

.mb-6 {
  margin-bottom: 48px;
}

.mb-7 {
  margin-bottom: 56px;
}

.mb-8 {
  margin-bottom: 64px;
}

.mb-auto {
  margin-bottom: auto;
}

.ms-0 {
  margin-left: 0;
}

.ms-1 {
  margin-left: 8px;
}

.ms-2 {
  margin-left: 16px;
}

.ms-3 {
  margin-left: 24px;
}

.ms-4 {
  margin-left: 32px;
}

.ms-5 {
  margin-left: 40px;
}

.ms-6 {
  margin-left: 48px;
}

.ms-7 {
  margin-left: 56px;
}

.ms-8 {
  margin-left: 64px;
}

.ms-auto {
  margin-left: auto;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: 8px;
}

.p-2 {
  padding: 16px;
}

.p-3 {
  padding: 24px;
}

.p-4 {
  padding: 32px;
}

.p-5 {
  padding: 40px;
}

.p-6 {
  padding: 48px;
}

.p-7 {
  padding: 56px;
}

.p-8 {
  padding: 64px;
}

.px-0 {
  padding-right: 0;
  padding-left: 0;
}

.px-1 {
  padding-right: 8px;
  padding-left: 8px;
}

.px-2 {
  padding-right: 16px;
  padding-left: 16px;
}

.px-3 {
  padding-right: 24px;
  padding-left: 24px;
}

.px-4 {
  padding-right: 32px;
  padding-left: 32px;
}

.px-5 {
  padding-right: 40px;
  padding-left: 40px;
}

.px-6 {
  padding-right: 48px;
  padding-left: 48px;
}

.px-7 {
  padding-right: 56px;
  padding-left: 56px;
}

.px-8 {
  padding-right: 64px;
  padding-left: 64px;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.py-2 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.py-3 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.py-4 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.py-5 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.py-6 {
  padding-top: 48px;
  padding-bottom: 48px;
}

.py-7 {
  padding-top: 56px;
  padding-bottom: 56px;
}

.py-8 {
  padding-top: 64px;
  padding-bottom: 64px;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: 8px;
}

.pt-2 {
  padding-top: 16px;
}

.pt-3 {
  padding-top: 24px;
}

.pt-4 {
  padding-top: 32px;
}

.pt-5 {
  padding-top: 40px;
}

.pt-6 {
  padding-top: 48px;
}

.pt-7 {
  padding-top: 56px;
}

.pt-8 {
  padding-top: 64px;
}

.pe-0 {
  padding-right: 0;
}

.pe-1 {
  padding-right: 8px;
}

.pe-2 {
  padding-right: 16px;
}

.pe-3 {
  padding-right: 24px;
}

.pe-4 {
  padding-right: 32px;
}

.pe-5 {
  padding-right: 40px;
}

.pe-6 {
  padding-right: 48px;
}

.pe-7 {
  padding-right: 56px;
}

.pe-8 {
  padding-right: 64px;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: 8px;
}

.pb-2 {
  padding-bottom: 16px;
}

.pb-3 {
  padding-bottom: 24px;
}

.pb-4 {
  padding-bottom: 32px;
}

.pb-5 {
  padding-bottom: 40px;
}

.pb-6 {
  padding-bottom: 48px;
}

.pb-7 {
  padding-bottom: 56px;
}

.pb-8 {
  padding-bottom: 64px;
}

.ps-0 {
  padding-left: 0;
}

.ps-1 {
  padding-left: 8px;
}

.ps-2 {
  padding-left: 16px;
}

.ps-3 {
  padding-left: 24px;
}

.ps-4 {
  padding-left: 32px;
}

.ps-5 {
  padding-left: 40px;
}

.ps-6 {
  padding-left: 48px;
}

.ps-7 {
  padding-left: 56px;
}

.ps-8 {
  padding-left: 64px;
}

.gap-0 {
  gap: 0;
}

.gap-1 {
  gap: 8px;
}

.gap-2 {
  gap: 16px;
}

.gap-3 {
  gap: 24px;
}

.gap-4 {
  gap: 32px;
}

.gap-5 {
  gap: 40px;
}

.gap-6 {
  gap: 48px;
}

.gap-7 {
  gap: 56px;
}

.gap-8 {
  gap: 64px;
}

.row-gap-0 {
  row-gap: 0;
}

.row-gap-1 {
  row-gap: 8px;
}

.row-gap-2 {
  row-gap: 16px;
}

.row-gap-3 {
  row-gap: 24px;
}

.row-gap-4 {
  row-gap: 32px;
}

.row-gap-5 {
  row-gap: 40px;
}

.row-gap-6 {
  row-gap: 48px;
}

.row-gap-7 {
  row-gap: 56px;
}

.row-gap-8 {
  row-gap: 64px;
}

.column-gap-0 {
  column-gap: 0;
}

.column-gap-1 {
  column-gap: 8px;
}

.column-gap-2 {
  column-gap: 16px;
}

.column-gap-3 {
  column-gap: 24px;
}

.column-gap-4 {
  column-gap: 32px;
}

.column-gap-5 {
  column-gap: 40px;
}

.column-gap-6 {
  column-gap: 48px;
}

.column-gap-7 {
  column-gap: 56px;
}

.column-gap-8 {
  column-gap: 64px;
}

.font-monospace {
  font-family: var(--bs-font-monospace);
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw);
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw);
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw);
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw);
}

.fs-5 {
  font-size: 1.25rem;
}

.fs-6 {
  font-size: 1rem;
}

.fst-italic {
  font-style: italic;
}

.fst-normal {
  font-style: normal;
}

.fw-lighter {
  font-weight: lighter;
}

.fw-light {
  font-weight: 300;
}

.fw-normal {
  font-weight: 400;
}

.fw-medium {
  font-weight: 500;
}

.fw-semibold {
  font-weight: 600;
}

.fw-bold {
  font-weight: 700;
}

.fw-bolder {
  font-weight: bolder;
}

.lh-1 {
  line-height: 1;
}

.lh-sm {
  line-height: 1.25;
}

.lh-base {
  line-height: 1.5;
}

.lh-lg {
  line-height: 2;
}

.text-start {
  text-align: left;
}

.text-end {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-decoration-none {
  text-decoration: none;
}

.text-decoration-underline {
  text-decoration: underline;
}

.text-decoration-line-through {
  text-decoration: line-through;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-wrap {
  white-space: normal;
}

.text-nowrap {
  white-space: nowrap;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word;
  word-break: break-word;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity));
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity));
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity));
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity));
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity));
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity));
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity));
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity));
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity));
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity));
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity));
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color);
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5);
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5);
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color);
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color);
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color);
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis);
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis);
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis);
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis);
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis);
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis);
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis);
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis);
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em;
}

.link-offset-2 {
  text-underline-offset: 0.25em;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em;
}

.link-offset-3 {
  text-underline-offset: 0.375em;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity));
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity));
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity));
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity));
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity));
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity));
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity));
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity));
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1));
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity));
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity));
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity));
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity));
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity));
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity));
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity));
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity));
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity));
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity));
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity));
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity));
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity));
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle);
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle);
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle);
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle);
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle);
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle);
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle);
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle);
}

.bg-gradient {
  background-image: var(--bs-gradient);
}

.user-select-all {
  user-select: all;
}

.user-select-auto {
  user-select: auto;
}

.user-select-none {
  user-select: none;
}

.pe-none {
  pointer-events: none;
}

.pe-auto {
  pointer-events: auto;
}

.rounded {
  border-radius: var(--bs-border-radius);
}

.rounded-0 {
  border-radius: 0;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm);
}

.rounded-2 {
  border-radius: var(--bs-border-radius);
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg);
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl);
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl);
}

.rounded-circle {
  border-radius: 50%;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill);
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius);
  border-top-right-radius: var(--bs-border-radius);
}

.rounded-top-0 {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm);
  border-top-right-radius: var(--bs-border-radius-sm);
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius);
  border-top-right-radius: var(--bs-border-radius);
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg);
  border-top-right-radius: var(--bs-border-radius-lg);
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl);
  border-top-right-radius: var(--bs-border-radius-xl);
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl);
  border-top-right-radius: var(--bs-border-radius-xxl);
}

.rounded-top-circle {
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill);
  border-top-right-radius: var(--bs-border-radius-pill);
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius);
  border-bottom-right-radius: var(--bs-border-radius);
}

.rounded-end-0 {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm);
  border-bottom-right-radius: var(--bs-border-radius-sm);
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius);
  border-bottom-right-radius: var(--bs-border-radius);
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg);
  border-bottom-right-radius: var(--bs-border-radius-lg);
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl);
  border-bottom-right-radius: var(--bs-border-radius-xl);
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl);
  border-bottom-right-radius: var(--bs-border-radius-xxl);
}

.rounded-end-circle {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill);
  border-bottom-right-radius: var(--bs-border-radius-pill);
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius);
  border-bottom-left-radius: var(--bs-border-radius);
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm);
  border-bottom-left-radius: var(--bs-border-radius-sm);
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius);
  border-bottom-left-radius: var(--bs-border-radius);
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg);
  border-bottom-left-radius: var(--bs-border-radius-lg);
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl);
  border-bottom-left-radius: var(--bs-border-radius-xl);
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl);
  border-bottom-left-radius: var(--bs-border-radius-xxl);
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill);
  border-bottom-left-radius: var(--bs-border-radius-pill);
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius);
  border-top-left-radius: var(--bs-border-radius);
}

.rounded-start-0 {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm);
  border-top-left-radius: var(--bs-border-radius-sm);
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius);
  border-top-left-radius: var(--bs-border-radius);
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg);
  border-top-left-radius: var(--bs-border-radius-lg);
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl);
  border-top-left-radius: var(--bs-border-radius-xl);
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl);
  border-top-left-radius: var(--bs-border-radius-xxl);
}

.rounded-start-circle {
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill);
  border-top-left-radius: var(--bs-border-radius-pill);
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.z-n1 {
  z-index: -1;
}

.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left;
  }
  .float-sm-end {
    float: right;
  }
  .float-sm-none {
    float: none;
  }
  .object-fit-sm-contain {
    object-fit: contain;
  }
  .object-fit-sm-cover {
    object-fit: cover;
  }
  .object-fit-sm-fill {
    object-fit: fill;
  }
  .object-fit-sm-scale {
    object-fit: scale-down;
  }
  .object-fit-sm-none {
    object-fit: none;
  }
  .d-sm-inline {
    display: inline;
  }
  .d-sm-inline-block {
    display: inline-block;
  }
  .d-sm-block {
    display: block;
  }
  .d-sm-grid {
    display: grid;
  }
  .d-sm-inline-grid {
    display: inline-grid;
  }
  .d-sm-table {
    display: table;
  }
  .d-sm-table-row {
    display: table-row;
  }
  .d-sm-table-cell {
    display: table-cell;
  }
  .d-sm-flex {
    display: flex;
  }
  .d-sm-inline-flex {
    display: inline-flex;
  }
  .d-sm-none {
    display: none;
  }
  .flex-sm-fill {
    flex: 1 1 auto;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-sm-grow-0 {
    flex-grow: 0;
  }
  .flex-sm-grow-1 {
    flex-grow: 1;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1;
  }
  .flex-sm-wrap {
    flex-wrap: wrap;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .justify-content-sm-start {
    justify-content: flex-start;
  }
  .justify-content-sm-end {
    justify-content: flex-end;
  }
  .justify-content-sm-center {
    justify-content: center;
  }
  .justify-content-sm-between {
    justify-content: space-between;
  }
  .justify-content-sm-around {
    justify-content: space-around;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly;
  }
  .align-items-sm-start {
    align-items: flex-start;
  }
  .align-items-sm-end {
    align-items: flex-end;
  }
  .align-items-sm-center {
    align-items: center;
  }
  .align-items-sm-baseline {
    align-items: baseline;
  }
  .align-items-sm-stretch {
    align-items: stretch;
  }
  .align-content-sm-start {
    align-content: flex-start;
  }
  .align-content-sm-end {
    align-content: flex-end;
  }
  .align-content-sm-center {
    align-content: center;
  }
  .align-content-sm-between {
    align-content: space-between;
  }
  .align-content-sm-around {
    align-content: space-around;
  }
  .align-content-sm-stretch {
    align-content: stretch;
  }
  .align-self-sm-auto {
    align-self: auto;
  }
  .align-self-sm-start {
    align-self: flex-start;
  }
  .align-self-sm-end {
    align-self: flex-end;
  }
  .align-self-sm-center {
    align-self: center;
  }
  .align-self-sm-baseline {
    align-self: baseline;
  }
  .align-self-sm-stretch {
    align-self: stretch;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-last {
    order: 6;
  }
  .m-sm-0 {
    margin: 0;
  }
  .m-sm-1 {
    margin: 8px;
  }
  .m-sm-2 {
    margin: 16px;
  }
  .m-sm-3 {
    margin: 24px;
  }
  .m-sm-4 {
    margin: 32px;
  }
  .m-sm-5 {
    margin: 40px;
  }
  .m-sm-6 {
    margin: 48px;
  }
  .m-sm-7 {
    margin: 56px;
  }
  .m-sm-8 {
    margin: 64px;
  }
  .m-sm-auto {
    margin: auto;
  }
  .mx-sm-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-sm-1 {
    margin-right: 8px;
    margin-left: 8px;
  }
  .mx-sm-2 {
    margin-right: 16px;
    margin-left: 16px;
  }
  .mx-sm-3 {
    margin-right: 24px;
    margin-left: 24px;
  }
  .mx-sm-4 {
    margin-right: 32px;
    margin-left: 32px;
  }
  .mx-sm-5 {
    margin-right: 40px;
    margin-left: 40px;
  }
  .mx-sm-6 {
    margin-right: 48px;
    margin-left: 48px;
  }
  .mx-sm-7 {
    margin-right: 56px;
    margin-left: 56px;
  }
  .mx-sm-8 {
    margin-right: 64px;
    margin-left: 64px;
  }
  .mx-sm-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-sm-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-sm-1 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .my-sm-2 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .my-sm-3 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .my-sm-4 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .my-sm-5 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .my-sm-6 {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .my-sm-7 {
    margin-top: 56px;
    margin-bottom: 56px;
  }
  .my-sm-8 {
    margin-top: 64px;
    margin-bottom: 64px;
  }
  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-sm-0 {
    margin-top: 0;
  }
  .mt-sm-1 {
    margin-top: 8px;
  }
  .mt-sm-2 {
    margin-top: 16px;
  }
  .mt-sm-3 {
    margin-top: 24px;
  }
  .mt-sm-4 {
    margin-top: 32px;
  }
  .mt-sm-5 {
    margin-top: 40px;
  }
  .mt-sm-6 {
    margin-top: 48px;
  }
  .mt-sm-7 {
    margin-top: 56px;
  }
  .mt-sm-8 {
    margin-top: 64px;
  }
  .mt-sm-auto {
    margin-top: auto;
  }
  .me-sm-0 {
    margin-right: 0;
  }
  .me-sm-1 {
    margin-right: 8px;
  }
  .me-sm-2 {
    margin-right: 16px;
  }
  .me-sm-3 {
    margin-right: 24px;
  }
  .me-sm-4 {
    margin-right: 32px;
  }
  .me-sm-5 {
    margin-right: 40px;
  }
  .me-sm-6 {
    margin-right: 48px;
  }
  .me-sm-7 {
    margin-right: 56px;
  }
  .me-sm-8 {
    margin-right: 64px;
  }
  .me-sm-auto {
    margin-right: auto;
  }
  .mb-sm-0 {
    margin-bottom: 0;
  }
  .mb-sm-1 {
    margin-bottom: 8px;
  }
  .mb-sm-2 {
    margin-bottom: 16px;
  }
  .mb-sm-3 {
    margin-bottom: 24px;
  }
  .mb-sm-4 {
    margin-bottom: 32px;
  }
  .mb-sm-5 {
    margin-bottom: 40px;
  }
  .mb-sm-6 {
    margin-bottom: 48px;
  }
  .mb-sm-7 {
    margin-bottom: 56px;
  }
  .mb-sm-8 {
    margin-bottom: 64px;
  }
  .mb-sm-auto {
    margin-bottom: auto;
  }
  .ms-sm-0 {
    margin-left: 0;
  }
  .ms-sm-1 {
    margin-left: 8px;
  }
  .ms-sm-2 {
    margin-left: 16px;
  }
  .ms-sm-3 {
    margin-left: 24px;
  }
  .ms-sm-4 {
    margin-left: 32px;
  }
  .ms-sm-5 {
    margin-left: 40px;
  }
  .ms-sm-6 {
    margin-left: 48px;
  }
  .ms-sm-7 {
    margin-left: 56px;
  }
  .ms-sm-8 {
    margin-left: 64px;
  }
  .ms-sm-auto {
    margin-left: auto;
  }
  .p-sm-0 {
    padding: 0;
  }
  .p-sm-1 {
    padding: 8px;
  }
  .p-sm-2 {
    padding: 16px;
  }
  .p-sm-3 {
    padding: 24px;
  }
  .p-sm-4 {
    padding: 32px;
  }
  .p-sm-5 {
    padding: 40px;
  }
  .p-sm-6 {
    padding: 48px;
  }
  .p-sm-7 {
    padding: 56px;
  }
  .p-sm-8 {
    padding: 64px;
  }
  .px-sm-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-sm-1 {
    padding-right: 8px;
    padding-left: 8px;
  }
  .px-sm-2 {
    padding-right: 16px;
    padding-left: 16px;
  }
  .px-sm-3 {
    padding-right: 24px;
    padding-left: 24px;
  }
  .px-sm-4 {
    padding-right: 32px;
    padding-left: 32px;
  }
  .px-sm-5 {
    padding-right: 40px;
    padding-left: 40px;
  }
  .px-sm-6 {
    padding-right: 48px;
    padding-left: 48px;
  }
  .px-sm-7 {
    padding-right: 56px;
    padding-left: 56px;
  }
  .px-sm-8 {
    padding-right: 64px;
    padding-left: 64px;
  }
  .py-sm-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-sm-1 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .py-sm-2 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .py-sm-3 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .py-sm-4 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .py-sm-5 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .py-sm-6 {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .py-sm-7 {
    padding-top: 56px;
    padding-bottom: 56px;
  }
  .py-sm-8 {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .pt-sm-0 {
    padding-top: 0;
  }
  .pt-sm-1 {
    padding-top: 8px;
  }
  .pt-sm-2 {
    padding-top: 16px;
  }
  .pt-sm-3 {
    padding-top: 24px;
  }
  .pt-sm-4 {
    padding-top: 32px;
  }
  .pt-sm-5 {
    padding-top: 40px;
  }
  .pt-sm-6 {
    padding-top: 48px;
  }
  .pt-sm-7 {
    padding-top: 56px;
  }
  .pt-sm-8 {
    padding-top: 64px;
  }
  .pe-sm-0 {
    padding-right: 0;
  }
  .pe-sm-1 {
    padding-right: 8px;
  }
  .pe-sm-2 {
    padding-right: 16px;
  }
  .pe-sm-3 {
    padding-right: 24px;
  }
  .pe-sm-4 {
    padding-right: 32px;
  }
  .pe-sm-5 {
    padding-right: 40px;
  }
  .pe-sm-6 {
    padding-right: 48px;
  }
  .pe-sm-7 {
    padding-right: 56px;
  }
  .pe-sm-8 {
    padding-right: 64px;
  }
  .pb-sm-0 {
    padding-bottom: 0;
  }
  .pb-sm-1 {
    padding-bottom: 8px;
  }
  .pb-sm-2 {
    padding-bottom: 16px;
  }
  .pb-sm-3 {
    padding-bottom: 24px;
  }
  .pb-sm-4 {
    padding-bottom: 32px;
  }
  .pb-sm-5 {
    padding-bottom: 40px;
  }
  .pb-sm-6 {
    padding-bottom: 48px;
  }
  .pb-sm-7 {
    padding-bottom: 56px;
  }
  .pb-sm-8 {
    padding-bottom: 64px;
  }
  .ps-sm-0 {
    padding-left: 0;
  }
  .ps-sm-1 {
    padding-left: 8px;
  }
  .ps-sm-2 {
    padding-left: 16px;
  }
  .ps-sm-3 {
    padding-left: 24px;
  }
  .ps-sm-4 {
    padding-left: 32px;
  }
  .ps-sm-5 {
    padding-left: 40px;
  }
  .ps-sm-6 {
    padding-left: 48px;
  }
  .ps-sm-7 {
    padding-left: 56px;
  }
  .ps-sm-8 {
    padding-left: 64px;
  }
  .gap-sm-0 {
    gap: 0;
  }
  .gap-sm-1 {
    gap: 8px;
  }
  .gap-sm-2 {
    gap: 16px;
  }
  .gap-sm-3 {
    gap: 24px;
  }
  .gap-sm-4 {
    gap: 32px;
  }
  .gap-sm-5 {
    gap: 40px;
  }
  .gap-sm-6 {
    gap: 48px;
  }
  .gap-sm-7 {
    gap: 56px;
  }
  .gap-sm-8 {
    gap: 64px;
  }
  .row-gap-sm-0 {
    row-gap: 0;
  }
  .row-gap-sm-1 {
    row-gap: 8px;
  }
  .row-gap-sm-2 {
    row-gap: 16px;
  }
  .row-gap-sm-3 {
    row-gap: 24px;
  }
  .row-gap-sm-4 {
    row-gap: 32px;
  }
  .row-gap-sm-5 {
    row-gap: 40px;
  }
  .row-gap-sm-6 {
    row-gap: 48px;
  }
  .row-gap-sm-7 {
    row-gap: 56px;
  }
  .row-gap-sm-8 {
    row-gap: 64px;
  }
  .column-gap-sm-0 {
    column-gap: 0;
  }
  .column-gap-sm-1 {
    column-gap: 8px;
  }
  .column-gap-sm-2 {
    column-gap: 16px;
  }
  .column-gap-sm-3 {
    column-gap: 24px;
  }
  .column-gap-sm-4 {
    column-gap: 32px;
  }
  .column-gap-sm-5 {
    column-gap: 40px;
  }
  .column-gap-sm-6 {
    column-gap: 48px;
  }
  .column-gap-sm-7 {
    column-gap: 56px;
  }
  .column-gap-sm-8 {
    column-gap: 64px;
  }
  .text-sm-start {
    text-align: left;
  }
  .text-sm-end {
    text-align: right;
  }
  .text-sm-center {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left;
  }
  .float-md-end {
    float: right;
  }
  .float-md-none {
    float: none;
  }
  .object-fit-md-contain {
    object-fit: contain;
  }
  .object-fit-md-cover {
    object-fit: cover;
  }
  .object-fit-md-fill {
    object-fit: fill;
  }
  .object-fit-md-scale {
    object-fit: scale-down;
  }
  .object-fit-md-none {
    object-fit: none;
  }
  .d-md-inline {
    display: inline;
  }
  .d-md-inline-block {
    display: inline-block;
  }
  .d-md-block {
    display: block;
  }
  .d-md-grid {
    display: grid;
  }
  .d-md-inline-grid {
    display: inline-grid;
  }
  .d-md-table {
    display: table;
  }
  .d-md-table-row {
    display: table-row;
  }
  .d-md-table-cell {
    display: table-cell;
  }
  .d-md-flex {
    display: flex;
  }
  .d-md-inline-flex {
    display: inline-flex;
  }
  .d-md-none {
    display: none;
  }
  .flex-md-fill {
    flex: 1 1 auto;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-grow-0 {
    flex-grow: 0;
  }
  .flex-md-grow-1 {
    flex-grow: 1;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1;
  }
  .flex-md-wrap {
    flex-wrap: wrap;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .justify-content-md-start {
    justify-content: flex-start;
  }
  .justify-content-md-end {
    justify-content: flex-end;
  }
  .justify-content-md-center {
    justify-content: center;
  }
  .justify-content-md-between {
    justify-content: space-between;
  }
  .justify-content-md-around {
    justify-content: space-around;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly;
  }
  .align-items-md-start {
    align-items: flex-start;
  }
  .align-items-md-end {
    align-items: flex-end;
  }
  .align-items-md-center {
    align-items: center;
  }
  .align-items-md-baseline {
    align-items: baseline;
  }
  .align-items-md-stretch {
    align-items: stretch;
  }
  .align-content-md-start {
    align-content: flex-start;
  }
  .align-content-md-end {
    align-content: flex-end;
  }
  .align-content-md-center {
    align-content: center;
  }
  .align-content-md-between {
    align-content: space-between;
  }
  .align-content-md-around {
    align-content: space-around;
  }
  .align-content-md-stretch {
    align-content: stretch;
  }
  .align-self-md-auto {
    align-self: auto;
  }
  .align-self-md-start {
    align-self: flex-start;
  }
  .align-self-md-end {
    align-self: flex-end;
  }
  .align-self-md-center {
    align-self: center;
  }
  .align-self-md-baseline {
    align-self: baseline;
  }
  .align-self-md-stretch {
    align-self: stretch;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-last {
    order: 6;
  }
  .m-md-0 {
    margin: 0;
  }
  .m-md-1 {
    margin: 8px;
  }
  .m-md-2 {
    margin: 16px;
  }
  .m-md-3 {
    margin: 24px;
  }
  .m-md-4 {
    margin: 32px;
  }
  .m-md-5 {
    margin: 40px;
  }
  .m-md-6 {
    margin: 48px;
  }
  .m-md-7 {
    margin: 56px;
  }
  .m-md-8 {
    margin: 64px;
  }
  .m-md-auto {
    margin: auto;
  }
  .mx-md-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-md-1 {
    margin-right: 8px;
    margin-left: 8px;
  }
  .mx-md-2 {
    margin-right: 16px;
    margin-left: 16px;
  }
  .mx-md-3 {
    margin-right: 24px;
    margin-left: 24px;
  }
  .mx-md-4 {
    margin-right: 32px;
    margin-left: 32px;
  }
  .mx-md-5 {
    margin-right: 40px;
    margin-left: 40px;
  }
  .mx-md-6 {
    margin-right: 48px;
    margin-left: 48px;
  }
  .mx-md-7 {
    margin-right: 56px;
    margin-left: 56px;
  }
  .mx-md-8 {
    margin-right: 64px;
    margin-left: 64px;
  }
  .mx-md-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-md-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-md-1 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .my-md-2 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .my-md-3 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .my-md-4 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .my-md-5 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .my-md-6 {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .my-md-7 {
    margin-top: 56px;
    margin-bottom: 56px;
  }
  .my-md-8 {
    margin-top: 64px;
    margin-bottom: 64px;
  }
  .my-md-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-md-0 {
    margin-top: 0;
  }
  .mt-md-1 {
    margin-top: 8px;
  }
  .mt-md-2 {
    margin-top: 16px;
  }
  .mt-md-3 {
    margin-top: 24px;
  }
  .mt-md-4 {
    margin-top: 32px;
  }
  .mt-md-5 {
    margin-top: 40px;
  }
  .mt-md-6 {
    margin-top: 48px;
  }
  .mt-md-7 {
    margin-top: 56px;
  }
  .mt-md-8 {
    margin-top: 64px;
  }
  .mt-md-auto {
    margin-top: auto;
  }
  .me-md-0 {
    margin-right: 0;
  }
  .me-md-1 {
    margin-right: 8px;
  }
  .me-md-2 {
    margin-right: 16px;
  }
  .me-md-3 {
    margin-right: 24px;
  }
  .me-md-4 {
    margin-right: 32px;
  }
  .me-md-5 {
    margin-right: 40px;
  }
  .me-md-6 {
    margin-right: 48px;
  }
  .me-md-7 {
    margin-right: 56px;
  }
  .me-md-8 {
    margin-right: 64px;
  }
  .me-md-auto {
    margin-right: auto;
  }
  .mb-md-0 {
    margin-bottom: 0;
  }
  .mb-md-1 {
    margin-bottom: 8px;
  }
  .mb-md-2 {
    margin-bottom: 16px;
  }
  .mb-md-3 {
    margin-bottom: 24px;
  }
  .mb-md-4 {
    margin-bottom: 32px;
  }
  .mb-md-5 {
    margin-bottom: 40px;
  }
  .mb-md-6 {
    margin-bottom: 48px;
  }
  .mb-md-7 {
    margin-bottom: 56px;
  }
  .mb-md-8 {
    margin-bottom: 64px;
  }
  .mb-md-auto {
    margin-bottom: auto;
  }
  .ms-md-0 {
    margin-left: 0;
  }
  .ms-md-1 {
    margin-left: 8px;
  }
  .ms-md-2 {
    margin-left: 16px;
  }
  .ms-md-3 {
    margin-left: 24px;
  }
  .ms-md-4 {
    margin-left: 32px;
  }
  .ms-md-5 {
    margin-left: 40px;
  }
  .ms-md-6 {
    margin-left: 48px;
  }
  .ms-md-7 {
    margin-left: 56px;
  }
  .ms-md-8 {
    margin-left: 64px;
  }
  .ms-md-auto {
    margin-left: auto;
  }
  .p-md-0 {
    padding: 0;
  }
  .p-md-1 {
    padding: 8px;
  }
  .p-md-2 {
    padding: 16px;
  }
  .p-md-3 {
    padding: 24px;
  }
  .p-md-4 {
    padding: 32px;
  }
  .p-md-5 {
    padding: 40px;
  }
  .p-md-6 {
    padding: 48px;
  }
  .p-md-7 {
    padding: 56px;
  }
  .p-md-8 {
    padding: 64px;
  }
  .px-md-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-md-1 {
    padding-right: 8px;
    padding-left: 8px;
  }
  .px-md-2 {
    padding-right: 16px;
    padding-left: 16px;
  }
  .px-md-3 {
    padding-right: 24px;
    padding-left: 24px;
  }
  .px-md-4 {
    padding-right: 32px;
    padding-left: 32px;
  }
  .px-md-5 {
    padding-right: 40px;
    padding-left: 40px;
  }
  .px-md-6 {
    padding-right: 48px;
    padding-left: 48px;
  }
  .px-md-7 {
    padding-right: 56px;
    padding-left: 56px;
  }
  .px-md-8 {
    padding-right: 64px;
    padding-left: 64px;
  }
  .py-md-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-md-1 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .py-md-2 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .py-md-3 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .py-md-4 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .py-md-5 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .py-md-6 {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .py-md-7 {
    padding-top: 56px;
    padding-bottom: 56px;
  }
  .py-md-8 {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .pt-md-0 {
    padding-top: 0;
  }
  .pt-md-1 {
    padding-top: 8px;
  }
  .pt-md-2 {
    padding-top: 16px;
  }
  .pt-md-3 {
    padding-top: 24px;
  }
  .pt-md-4 {
    padding-top: 32px;
  }
  .pt-md-5 {
    padding-top: 40px;
  }
  .pt-md-6 {
    padding-top: 48px;
  }
  .pt-md-7 {
    padding-top: 56px;
  }
  .pt-md-8 {
    padding-top: 64px;
  }
  .pe-md-0 {
    padding-right: 0;
  }
  .pe-md-1 {
    padding-right: 8px;
  }
  .pe-md-2 {
    padding-right: 16px;
  }
  .pe-md-3 {
    padding-right: 24px;
  }
  .pe-md-4 {
    padding-right: 32px;
  }
  .pe-md-5 {
    padding-right: 40px;
  }
  .pe-md-6 {
    padding-right: 48px;
  }
  .pe-md-7 {
    padding-right: 56px;
  }
  .pe-md-8 {
    padding-right: 64px;
  }
  .pb-md-0 {
    padding-bottom: 0;
  }
  .pb-md-1 {
    padding-bottom: 8px;
  }
  .pb-md-2 {
    padding-bottom: 16px;
  }
  .pb-md-3 {
    padding-bottom: 24px;
  }
  .pb-md-4 {
    padding-bottom: 32px;
  }
  .pb-md-5 {
    padding-bottom: 40px;
  }
  .pb-md-6 {
    padding-bottom: 48px;
  }
  .pb-md-7 {
    padding-bottom: 56px;
  }
  .pb-md-8 {
    padding-bottom: 64px;
  }
  .ps-md-0 {
    padding-left: 0;
  }
  .ps-md-1 {
    padding-left: 8px;
  }
  .ps-md-2 {
    padding-left: 16px;
  }
  .ps-md-3 {
    padding-left: 24px;
  }
  .ps-md-4 {
    padding-left: 32px;
  }
  .ps-md-5 {
    padding-left: 40px;
  }
  .ps-md-6 {
    padding-left: 48px;
  }
  .ps-md-7 {
    padding-left: 56px;
  }
  .ps-md-8 {
    padding-left: 64px;
  }
  .gap-md-0 {
    gap: 0;
  }
  .gap-md-1 {
    gap: 8px;
  }
  .gap-md-2 {
    gap: 16px;
  }
  .gap-md-3 {
    gap: 24px;
  }
  .gap-md-4 {
    gap: 32px;
  }
  .gap-md-5 {
    gap: 40px;
  }
  .gap-md-6 {
    gap: 48px;
  }
  .gap-md-7 {
    gap: 56px;
  }
  .gap-md-8 {
    gap: 64px;
  }
  .row-gap-md-0 {
    row-gap: 0;
  }
  .row-gap-md-1 {
    row-gap: 8px;
  }
  .row-gap-md-2 {
    row-gap: 16px;
  }
  .row-gap-md-3 {
    row-gap: 24px;
  }
  .row-gap-md-4 {
    row-gap: 32px;
  }
  .row-gap-md-5 {
    row-gap: 40px;
  }
  .row-gap-md-6 {
    row-gap: 48px;
  }
  .row-gap-md-7 {
    row-gap: 56px;
  }
  .row-gap-md-8 {
    row-gap: 64px;
  }
  .column-gap-md-0 {
    column-gap: 0;
  }
  .column-gap-md-1 {
    column-gap: 8px;
  }
  .column-gap-md-2 {
    column-gap: 16px;
  }
  .column-gap-md-3 {
    column-gap: 24px;
  }
  .column-gap-md-4 {
    column-gap: 32px;
  }
  .column-gap-md-5 {
    column-gap: 40px;
  }
  .column-gap-md-6 {
    column-gap: 48px;
  }
  .column-gap-md-7 {
    column-gap: 56px;
  }
  .column-gap-md-8 {
    column-gap: 64px;
  }
  .text-md-start {
    text-align: left;
  }
  .text-md-end {
    text-align: right;
  }
  .text-md-center {
    text-align: center;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left;
  }
  .float-lg-end {
    float: right;
  }
  .float-lg-none {
    float: none;
  }
  .object-fit-lg-contain {
    object-fit: contain;
  }
  .object-fit-lg-cover {
    object-fit: cover;
  }
  .object-fit-lg-fill {
    object-fit: fill;
  }
  .object-fit-lg-scale {
    object-fit: scale-down;
  }
  .object-fit-lg-none {
    object-fit: none;
  }
  .d-lg-inline {
    display: inline;
  }
  .d-lg-inline-block {
    display: inline-block;
  }
  .d-lg-block {
    display: block;
  }
  .d-lg-grid {
    display: grid;
  }
  .d-lg-inline-grid {
    display: inline-grid;
  }
  .d-lg-table {
    display: table;
  }
  .d-lg-table-row {
    display: table-row;
  }
  .d-lg-table-cell {
    display: table-cell;
  }
  .d-lg-flex {
    display: flex;
  }
  .d-lg-inline-flex {
    display: inline-flex;
  }
  .d-lg-none {
    display: none;
  }
  .flex-lg-fill {
    flex: 1 1 auto;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-grow-0 {
    flex-grow: 0;
  }
  .flex-lg-grow-1 {
    flex-grow: 1;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1;
  }
  .flex-lg-wrap {
    flex-wrap: wrap;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .justify-content-lg-start {
    justify-content: flex-start;
  }
  .justify-content-lg-end {
    justify-content: flex-end;
  }
  .justify-content-lg-center {
    justify-content: center;
  }
  .justify-content-lg-between {
    justify-content: space-between;
  }
  .justify-content-lg-around {
    justify-content: space-around;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly;
  }
  .align-items-lg-start {
    align-items: flex-start;
  }
  .align-items-lg-end {
    align-items: flex-end;
  }
  .align-items-lg-center {
    align-items: center;
  }
  .align-items-lg-baseline {
    align-items: baseline;
  }
  .align-items-lg-stretch {
    align-items: stretch;
  }
  .align-content-lg-start {
    align-content: flex-start;
  }
  .align-content-lg-end {
    align-content: flex-end;
  }
  .align-content-lg-center {
    align-content: center;
  }
  .align-content-lg-between {
    align-content: space-between;
  }
  .align-content-lg-around {
    align-content: space-around;
  }
  .align-content-lg-stretch {
    align-content: stretch;
  }
  .align-self-lg-auto {
    align-self: auto;
  }
  .align-self-lg-start {
    align-self: flex-start;
  }
  .align-self-lg-end {
    align-self: flex-end;
  }
  .align-self-lg-center {
    align-self: center;
  }
  .align-self-lg-baseline {
    align-self: baseline;
  }
  .align-self-lg-stretch {
    align-self: stretch;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-last {
    order: 6;
  }
  .m-lg-0 {
    margin: 0;
  }
  .m-lg-1 {
    margin: 8px;
  }
  .m-lg-2 {
    margin: 16px;
  }
  .m-lg-3 {
    margin: 24px;
  }
  .m-lg-4 {
    margin: 32px;
  }
  .m-lg-5 {
    margin: 40px;
  }
  .m-lg-6 {
    margin: 48px;
  }
  .m-lg-7 {
    margin: 56px;
  }
  .m-lg-8 {
    margin: 64px;
  }
  .m-lg-auto {
    margin: auto;
  }
  .mx-lg-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-lg-1 {
    margin-right: 8px;
    margin-left: 8px;
  }
  .mx-lg-2 {
    margin-right: 16px;
    margin-left: 16px;
  }
  .mx-lg-3 {
    margin-right: 24px;
    margin-left: 24px;
  }
  .mx-lg-4 {
    margin-right: 32px;
    margin-left: 32px;
  }
  .mx-lg-5 {
    margin-right: 40px;
    margin-left: 40px;
  }
  .mx-lg-6 {
    margin-right: 48px;
    margin-left: 48px;
  }
  .mx-lg-7 {
    margin-right: 56px;
    margin-left: 56px;
  }
  .mx-lg-8 {
    margin-right: 64px;
    margin-left: 64px;
  }
  .mx-lg-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-lg-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-lg-1 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .my-lg-2 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .my-lg-3 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .my-lg-4 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .my-lg-5 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .my-lg-6 {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .my-lg-7 {
    margin-top: 56px;
    margin-bottom: 56px;
  }
  .my-lg-8 {
    margin-top: 64px;
    margin-bottom: 64px;
  }
  .my-lg-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-lg-0 {
    margin-top: 0;
  }
  .mt-lg-1 {
    margin-top: 8px;
  }
  .mt-lg-2 {
    margin-top: 16px;
  }
  .mt-lg-3 {
    margin-top: 24px;
  }
  .mt-lg-4 {
    margin-top: 32px;
  }
  .mt-lg-5 {
    margin-top: 40px;
  }
  .mt-lg-6 {
    margin-top: 48px;
  }
  .mt-lg-7 {
    margin-top: 56px;
  }
  .mt-lg-8 {
    margin-top: 64px;
  }
  .mt-lg-auto {
    margin-top: auto;
  }
  .me-lg-0 {
    margin-right: 0;
  }
  .me-lg-1 {
    margin-right: 8px;
  }
  .me-lg-2 {
    margin-right: 16px;
  }
  .me-lg-3 {
    margin-right: 24px;
  }
  .me-lg-4 {
    margin-right: 32px;
  }
  .me-lg-5 {
    margin-right: 40px;
  }
  .me-lg-6 {
    margin-right: 48px;
  }
  .me-lg-7 {
    margin-right: 56px;
  }
  .me-lg-8 {
    margin-right: 64px;
  }
  .me-lg-auto {
    margin-right: auto;
  }
  .mb-lg-0 {
    margin-bottom: 0;
  }
  .mb-lg-1 {
    margin-bottom: 8px;
  }
  .mb-lg-2 {
    margin-bottom: 16px;
  }
  .mb-lg-3 {
    margin-bottom: 24px;
  }
  .mb-lg-4 {
    margin-bottom: 32px;
  }
  .mb-lg-5 {
    margin-bottom: 40px;
  }
  .mb-lg-6 {
    margin-bottom: 48px;
  }
  .mb-lg-7 {
    margin-bottom: 56px;
  }
  .mb-lg-8 {
    margin-bottom: 64px;
  }
  .mb-lg-auto {
    margin-bottom: auto;
  }
  .ms-lg-0 {
    margin-left: 0;
  }
  .ms-lg-1 {
    margin-left: 8px;
  }
  .ms-lg-2 {
    margin-left: 16px;
  }
  .ms-lg-3 {
    margin-left: 24px;
  }
  .ms-lg-4 {
    margin-left: 32px;
  }
  .ms-lg-5 {
    margin-left: 40px;
  }
  .ms-lg-6 {
    margin-left: 48px;
  }
  .ms-lg-7 {
    margin-left: 56px;
  }
  .ms-lg-8 {
    margin-left: 64px;
  }
  .ms-lg-auto {
    margin-left: auto;
  }
  .p-lg-0 {
    padding: 0;
  }
  .p-lg-1 {
    padding: 8px;
  }
  .p-lg-2 {
    padding: 16px;
  }
  .p-lg-3 {
    padding: 24px;
  }
  .p-lg-4 {
    padding: 32px;
  }
  .p-lg-5 {
    padding: 40px;
  }
  .p-lg-6 {
    padding: 48px;
  }
  .p-lg-7 {
    padding: 56px;
  }
  .p-lg-8 {
    padding: 64px;
  }
  .px-lg-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-lg-1 {
    padding-right: 8px;
    padding-left: 8px;
  }
  .px-lg-2 {
    padding-right: 16px;
    padding-left: 16px;
  }
  .px-lg-3 {
    padding-right: 24px;
    padding-left: 24px;
  }
  .px-lg-4 {
    padding-right: 32px;
    padding-left: 32px;
  }
  .px-lg-5 {
    padding-right: 40px;
    padding-left: 40px;
  }
  .px-lg-6 {
    padding-right: 48px;
    padding-left: 48px;
  }
  .px-lg-7 {
    padding-right: 56px;
    padding-left: 56px;
  }
  .px-lg-8 {
    padding-right: 64px;
    padding-left: 64px;
  }
  .py-lg-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-lg-1 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .py-lg-2 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .py-lg-3 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .py-lg-4 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .py-lg-5 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .py-lg-6 {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .py-lg-7 {
    padding-top: 56px;
    padding-bottom: 56px;
  }
  .py-lg-8 {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .pt-lg-0 {
    padding-top: 0;
  }
  .pt-lg-1 {
    padding-top: 8px;
  }
  .pt-lg-2 {
    padding-top: 16px;
  }
  .pt-lg-3 {
    padding-top: 24px;
  }
  .pt-lg-4 {
    padding-top: 32px;
  }
  .pt-lg-5 {
    padding-top: 40px;
  }
  .pt-lg-6 {
    padding-top: 48px;
  }
  .pt-lg-7 {
    padding-top: 56px;
  }
  .pt-lg-8 {
    padding-top: 64px;
  }
  .pe-lg-0 {
    padding-right: 0;
  }
  .pe-lg-1 {
    padding-right: 8px;
  }
  .pe-lg-2 {
    padding-right: 16px;
  }
  .pe-lg-3 {
    padding-right: 24px;
  }
  .pe-lg-4 {
    padding-right: 32px;
  }
  .pe-lg-5 {
    padding-right: 40px;
  }
  .pe-lg-6 {
    padding-right: 48px;
  }
  .pe-lg-7 {
    padding-right: 56px;
  }
  .pe-lg-8 {
    padding-right: 64px;
  }
  .pb-lg-0 {
    padding-bottom: 0;
  }
  .pb-lg-1 {
    padding-bottom: 8px;
  }
  .pb-lg-2 {
    padding-bottom: 16px;
  }
  .pb-lg-3 {
    padding-bottom: 24px;
  }
  .pb-lg-4 {
    padding-bottom: 32px;
  }
  .pb-lg-5 {
    padding-bottom: 40px;
  }
  .pb-lg-6 {
    padding-bottom: 48px;
  }
  .pb-lg-7 {
    padding-bottom: 56px;
  }
  .pb-lg-8 {
    padding-bottom: 64px;
  }
  .ps-lg-0 {
    padding-left: 0;
  }
  .ps-lg-1 {
    padding-left: 8px;
  }
  .ps-lg-2 {
    padding-left: 16px;
  }
  .ps-lg-3 {
    padding-left: 24px;
  }
  .ps-lg-4 {
    padding-left: 32px;
  }
  .ps-lg-5 {
    padding-left: 40px;
  }
  .ps-lg-6 {
    padding-left: 48px;
  }
  .ps-lg-7 {
    padding-left: 56px;
  }
  .ps-lg-8 {
    padding-left: 64px;
  }
  .gap-lg-0 {
    gap: 0;
  }
  .gap-lg-1 {
    gap: 8px;
  }
  .gap-lg-2 {
    gap: 16px;
  }
  .gap-lg-3 {
    gap: 24px;
  }
  .gap-lg-4 {
    gap: 32px;
  }
  .gap-lg-5 {
    gap: 40px;
  }
  .gap-lg-6 {
    gap: 48px;
  }
  .gap-lg-7 {
    gap: 56px;
  }
  .gap-lg-8 {
    gap: 64px;
  }
  .row-gap-lg-0 {
    row-gap: 0;
  }
  .row-gap-lg-1 {
    row-gap: 8px;
  }
  .row-gap-lg-2 {
    row-gap: 16px;
  }
  .row-gap-lg-3 {
    row-gap: 24px;
  }
  .row-gap-lg-4 {
    row-gap: 32px;
  }
  .row-gap-lg-5 {
    row-gap: 40px;
  }
  .row-gap-lg-6 {
    row-gap: 48px;
  }
  .row-gap-lg-7 {
    row-gap: 56px;
  }
  .row-gap-lg-8 {
    row-gap: 64px;
  }
  .column-gap-lg-0 {
    column-gap: 0;
  }
  .column-gap-lg-1 {
    column-gap: 8px;
  }
  .column-gap-lg-2 {
    column-gap: 16px;
  }
  .column-gap-lg-3 {
    column-gap: 24px;
  }
  .column-gap-lg-4 {
    column-gap: 32px;
  }
  .column-gap-lg-5 {
    column-gap: 40px;
  }
  .column-gap-lg-6 {
    column-gap: 48px;
  }
  .column-gap-lg-7 {
    column-gap: 56px;
  }
  .column-gap-lg-8 {
    column-gap: 64px;
  }
  .text-lg-start {
    text-align: left;
  }
  .text-lg-end {
    text-align: right;
  }
  .text-lg-center {
    text-align: center;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left;
  }
  .float-xl-end {
    float: right;
  }
  .float-xl-none {
    float: none;
  }
  .object-fit-xl-contain {
    object-fit: contain;
  }
  .object-fit-xl-cover {
    object-fit: cover;
  }
  .object-fit-xl-fill {
    object-fit: fill;
  }
  .object-fit-xl-scale {
    object-fit: scale-down;
  }
  .object-fit-xl-none {
    object-fit: none;
  }
  .d-xl-inline {
    display: inline;
  }
  .d-xl-inline-block {
    display: inline-block;
  }
  .d-xl-block {
    display: block;
  }
  .d-xl-grid {
    display: grid;
  }
  .d-xl-inline-grid {
    display: inline-grid;
  }
  .d-xl-table {
    display: table;
  }
  .d-xl-table-row {
    display: table-row;
  }
  .d-xl-table-cell {
    display: table-cell;
  }
  .d-xl-flex {
    display: flex;
  }
  .d-xl-inline-flex {
    display: inline-flex;
  }
  .d-xl-none {
    display: none;
  }
  .flex-xl-fill {
    flex: 1 1 auto;
  }
  .flex-xl-row {
    flex-direction: row;
  }
  .flex-xl-column {
    flex-direction: column;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-xl-grow-0 {
    flex-grow: 0;
  }
  .flex-xl-grow-1 {
    flex-grow: 1;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1;
  }
  .flex-xl-wrap {
    flex-wrap: wrap;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .justify-content-xl-start {
    justify-content: flex-start;
  }
  .justify-content-xl-end {
    justify-content: flex-end;
  }
  .justify-content-xl-center {
    justify-content: center;
  }
  .justify-content-xl-between {
    justify-content: space-between;
  }
  .justify-content-xl-around {
    justify-content: space-around;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly;
  }
  .align-items-xl-start {
    align-items: flex-start;
  }
  .align-items-xl-end {
    align-items: flex-end;
  }
  .align-items-xl-center {
    align-items: center;
  }
  .align-items-xl-baseline {
    align-items: baseline;
  }
  .align-items-xl-stretch {
    align-items: stretch;
  }
  .align-content-xl-start {
    align-content: flex-start;
  }
  .align-content-xl-end {
    align-content: flex-end;
  }
  .align-content-xl-center {
    align-content: center;
  }
  .align-content-xl-between {
    align-content: space-between;
  }
  .align-content-xl-around {
    align-content: space-around;
  }
  .align-content-xl-stretch {
    align-content: stretch;
  }
  .align-self-xl-auto {
    align-self: auto;
  }
  .align-self-xl-start {
    align-self: flex-start;
  }
  .align-self-xl-end {
    align-self: flex-end;
  }
  .align-self-xl-center {
    align-self: center;
  }
  .align-self-xl-baseline {
    align-self: baseline;
  }
  .align-self-xl-stretch {
    align-self: stretch;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-last {
    order: 6;
  }
  .m-xl-0 {
    margin: 0;
  }
  .m-xl-1 {
    margin: 8px;
  }
  .m-xl-2 {
    margin: 16px;
  }
  .m-xl-3 {
    margin: 24px;
  }
  .m-xl-4 {
    margin: 32px;
  }
  .m-xl-5 {
    margin: 40px;
  }
  .m-xl-6 {
    margin: 48px;
  }
  .m-xl-7 {
    margin: 56px;
  }
  .m-xl-8 {
    margin: 64px;
  }
  .m-xl-auto {
    margin: auto;
  }
  .mx-xl-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-xl-1 {
    margin-right: 8px;
    margin-left: 8px;
  }
  .mx-xl-2 {
    margin-right: 16px;
    margin-left: 16px;
  }
  .mx-xl-3 {
    margin-right: 24px;
    margin-left: 24px;
  }
  .mx-xl-4 {
    margin-right: 32px;
    margin-left: 32px;
  }
  .mx-xl-5 {
    margin-right: 40px;
    margin-left: 40px;
  }
  .mx-xl-6 {
    margin-right: 48px;
    margin-left: 48px;
  }
  .mx-xl-7 {
    margin-right: 56px;
    margin-left: 56px;
  }
  .mx-xl-8 {
    margin-right: 64px;
    margin-left: 64px;
  }
  .mx-xl-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-xl-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-xl-1 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .my-xl-2 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .my-xl-3 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .my-xl-4 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .my-xl-5 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .my-xl-6 {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .my-xl-7 {
    margin-top: 56px;
    margin-bottom: 56px;
  }
  .my-xl-8 {
    margin-top: 64px;
    margin-bottom: 64px;
  }
  .my-xl-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-xl-0 {
    margin-top: 0;
  }
  .mt-xl-1 {
    margin-top: 8px;
  }
  .mt-xl-2 {
    margin-top: 16px;
  }
  .mt-xl-3 {
    margin-top: 24px;
  }
  .mt-xl-4 {
    margin-top: 32px;
  }
  .mt-xl-5 {
    margin-top: 40px;
  }
  .mt-xl-6 {
    margin-top: 48px;
  }
  .mt-xl-7 {
    margin-top: 56px;
  }
  .mt-xl-8 {
    margin-top: 64px;
  }
  .mt-xl-auto {
    margin-top: auto;
  }
  .me-xl-0 {
    margin-right: 0;
  }
  .me-xl-1 {
    margin-right: 8px;
  }
  .me-xl-2 {
    margin-right: 16px;
  }
  .me-xl-3 {
    margin-right: 24px;
  }
  .me-xl-4 {
    margin-right: 32px;
  }
  .me-xl-5 {
    margin-right: 40px;
  }
  .me-xl-6 {
    margin-right: 48px;
  }
  .me-xl-7 {
    margin-right: 56px;
  }
  .me-xl-8 {
    margin-right: 64px;
  }
  .me-xl-auto {
    margin-right: auto;
  }
  .mb-xl-0 {
    margin-bottom: 0;
  }
  .mb-xl-1 {
    margin-bottom: 8px;
  }
  .mb-xl-2 {
    margin-bottom: 16px;
  }
  .mb-xl-3 {
    margin-bottom: 24px;
  }
  .mb-xl-4 {
    margin-bottom: 32px;
  }
  .mb-xl-5 {
    margin-bottom: 40px;
  }
  .mb-xl-6 {
    margin-bottom: 48px;
  }
  .mb-xl-7 {
    margin-bottom: 56px;
  }
  .mb-xl-8 {
    margin-bottom: 64px;
  }
  .mb-xl-auto {
    margin-bottom: auto;
  }
  .ms-xl-0 {
    margin-left: 0;
  }
  .ms-xl-1 {
    margin-left: 8px;
  }
  .ms-xl-2 {
    margin-left: 16px;
  }
  .ms-xl-3 {
    margin-left: 24px;
  }
  .ms-xl-4 {
    margin-left: 32px;
  }
  .ms-xl-5 {
    margin-left: 40px;
  }
  .ms-xl-6 {
    margin-left: 48px;
  }
  .ms-xl-7 {
    margin-left: 56px;
  }
  .ms-xl-8 {
    margin-left: 64px;
  }
  .ms-xl-auto {
    margin-left: auto;
  }
  .p-xl-0 {
    padding: 0;
  }
  .p-xl-1 {
    padding: 8px;
  }
  .p-xl-2 {
    padding: 16px;
  }
  .p-xl-3 {
    padding: 24px;
  }
  .p-xl-4 {
    padding: 32px;
  }
  .p-xl-5 {
    padding: 40px;
  }
  .p-xl-6 {
    padding: 48px;
  }
  .p-xl-7 {
    padding: 56px;
  }
  .p-xl-8 {
    padding: 64px;
  }
  .px-xl-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-xl-1 {
    padding-right: 8px;
    padding-left: 8px;
  }
  .px-xl-2 {
    padding-right: 16px;
    padding-left: 16px;
  }
  .px-xl-3 {
    padding-right: 24px;
    padding-left: 24px;
  }
  .px-xl-4 {
    padding-right: 32px;
    padding-left: 32px;
  }
  .px-xl-5 {
    padding-right: 40px;
    padding-left: 40px;
  }
  .px-xl-6 {
    padding-right: 48px;
    padding-left: 48px;
  }
  .px-xl-7 {
    padding-right: 56px;
    padding-left: 56px;
  }
  .px-xl-8 {
    padding-right: 64px;
    padding-left: 64px;
  }
  .py-xl-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-xl-1 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .py-xl-2 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .py-xl-3 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .py-xl-4 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .py-xl-5 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .py-xl-6 {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .py-xl-7 {
    padding-top: 56px;
    padding-bottom: 56px;
  }
  .py-xl-8 {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .pt-xl-0 {
    padding-top: 0;
  }
  .pt-xl-1 {
    padding-top: 8px;
  }
  .pt-xl-2 {
    padding-top: 16px;
  }
  .pt-xl-3 {
    padding-top: 24px;
  }
  .pt-xl-4 {
    padding-top: 32px;
  }
  .pt-xl-5 {
    padding-top: 40px;
  }
  .pt-xl-6 {
    padding-top: 48px;
  }
  .pt-xl-7 {
    padding-top: 56px;
  }
  .pt-xl-8 {
    padding-top: 64px;
  }
  .pe-xl-0 {
    padding-right: 0;
  }
  .pe-xl-1 {
    padding-right: 8px;
  }
  .pe-xl-2 {
    padding-right: 16px;
  }
  .pe-xl-3 {
    padding-right: 24px;
  }
  .pe-xl-4 {
    padding-right: 32px;
  }
  .pe-xl-5 {
    padding-right: 40px;
  }
  .pe-xl-6 {
    padding-right: 48px;
  }
  .pe-xl-7 {
    padding-right: 56px;
  }
  .pe-xl-8 {
    padding-right: 64px;
  }
  .pb-xl-0 {
    padding-bottom: 0;
  }
  .pb-xl-1 {
    padding-bottom: 8px;
  }
  .pb-xl-2 {
    padding-bottom: 16px;
  }
  .pb-xl-3 {
    padding-bottom: 24px;
  }
  .pb-xl-4 {
    padding-bottom: 32px;
  }
  .pb-xl-5 {
    padding-bottom: 40px;
  }
  .pb-xl-6 {
    padding-bottom: 48px;
  }
  .pb-xl-7 {
    padding-bottom: 56px;
  }
  .pb-xl-8 {
    padding-bottom: 64px;
  }
  .ps-xl-0 {
    padding-left: 0;
  }
  .ps-xl-1 {
    padding-left: 8px;
  }
  .ps-xl-2 {
    padding-left: 16px;
  }
  .ps-xl-3 {
    padding-left: 24px;
  }
  .ps-xl-4 {
    padding-left: 32px;
  }
  .ps-xl-5 {
    padding-left: 40px;
  }
  .ps-xl-6 {
    padding-left: 48px;
  }
  .ps-xl-7 {
    padding-left: 56px;
  }
  .ps-xl-8 {
    padding-left: 64px;
  }
  .gap-xl-0 {
    gap: 0;
  }
  .gap-xl-1 {
    gap: 8px;
  }
  .gap-xl-2 {
    gap: 16px;
  }
  .gap-xl-3 {
    gap: 24px;
  }
  .gap-xl-4 {
    gap: 32px;
  }
  .gap-xl-5 {
    gap: 40px;
  }
  .gap-xl-6 {
    gap: 48px;
  }
  .gap-xl-7 {
    gap: 56px;
  }
  .gap-xl-8 {
    gap: 64px;
  }
  .row-gap-xl-0 {
    row-gap: 0;
  }
  .row-gap-xl-1 {
    row-gap: 8px;
  }
  .row-gap-xl-2 {
    row-gap: 16px;
  }
  .row-gap-xl-3 {
    row-gap: 24px;
  }
  .row-gap-xl-4 {
    row-gap: 32px;
  }
  .row-gap-xl-5 {
    row-gap: 40px;
  }
  .row-gap-xl-6 {
    row-gap: 48px;
  }
  .row-gap-xl-7 {
    row-gap: 56px;
  }
  .row-gap-xl-8 {
    row-gap: 64px;
  }
  .column-gap-xl-0 {
    column-gap: 0;
  }
  .column-gap-xl-1 {
    column-gap: 8px;
  }
  .column-gap-xl-2 {
    column-gap: 16px;
  }
  .column-gap-xl-3 {
    column-gap: 24px;
  }
  .column-gap-xl-4 {
    column-gap: 32px;
  }
  .column-gap-xl-5 {
    column-gap: 40px;
  }
  .column-gap-xl-6 {
    column-gap: 48px;
  }
  .column-gap-xl-7 {
    column-gap: 56px;
  }
  .column-gap-xl-8 {
    column-gap: 64px;
  }
  .text-xl-start {
    text-align: left;
  }
  .text-xl-end {
    text-align: right;
  }
  .text-xl-center {
    text-align: center;
  }
}
@media (min-width: 1500px) {
  .float-xxl-start {
    float: left;
  }
  .float-xxl-end {
    float: right;
  }
  .float-xxl-none {
    float: none;
  }
  .object-fit-xxl-contain {
    object-fit: contain;
  }
  .object-fit-xxl-cover {
    object-fit: cover;
  }
  .object-fit-xxl-fill {
    object-fit: fill;
  }
  .object-fit-xxl-scale {
    object-fit: scale-down;
  }
  .object-fit-xxl-none {
    object-fit: none;
  }
  .d-xxl-inline {
    display: inline;
  }
  .d-xxl-inline-block {
    display: inline-block;
  }
  .d-xxl-block {
    display: block;
  }
  .d-xxl-grid {
    display: grid;
  }
  .d-xxl-inline-grid {
    display: inline-grid;
  }
  .d-xxl-table {
    display: table;
  }
  .d-xxl-table-row {
    display: table-row;
  }
  .d-xxl-table-cell {
    display: table-cell;
  }
  .d-xxl-flex {
    display: flex;
  }
  .d-xxl-inline-flex {
    display: inline-flex;
  }
  .d-xxl-none {
    display: none;
  }
  .flex-xxl-fill {
    flex: 1 1 auto;
  }
  .flex-xxl-row {
    flex-direction: row;
  }
  .flex-xxl-column {
    flex-direction: column;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .justify-content-xxl-start {
    justify-content: flex-start;
  }
  .justify-content-xxl-end {
    justify-content: flex-end;
  }
  .justify-content-xxl-center {
    justify-content: center;
  }
  .justify-content-xxl-between {
    justify-content: space-between;
  }
  .justify-content-xxl-around {
    justify-content: space-around;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly;
  }
  .align-items-xxl-start {
    align-items: flex-start;
  }
  .align-items-xxl-end {
    align-items: flex-end;
  }
  .align-items-xxl-center {
    align-items: center;
  }
  .align-items-xxl-baseline {
    align-items: baseline;
  }
  .align-items-xxl-stretch {
    align-items: stretch;
  }
  .align-content-xxl-start {
    align-content: flex-start;
  }
  .align-content-xxl-end {
    align-content: flex-end;
  }
  .align-content-xxl-center {
    align-content: center;
  }
  .align-content-xxl-between {
    align-content: space-between;
  }
  .align-content-xxl-around {
    align-content: space-around;
  }
  .align-content-xxl-stretch {
    align-content: stretch;
  }
  .align-self-xxl-auto {
    align-self: auto;
  }
  .align-self-xxl-start {
    align-self: flex-start;
  }
  .align-self-xxl-end {
    align-self: flex-end;
  }
  .align-self-xxl-center {
    align-self: center;
  }
  .align-self-xxl-baseline {
    align-self: baseline;
  }
  .align-self-xxl-stretch {
    align-self: stretch;
  }
  .order-xxl-first {
    order: -1;
  }
  .order-xxl-0 {
    order: 0;
  }
  .order-xxl-1 {
    order: 1;
  }
  .order-xxl-2 {
    order: 2;
  }
  .order-xxl-3 {
    order: 3;
  }
  .order-xxl-4 {
    order: 4;
  }
  .order-xxl-5 {
    order: 5;
  }
  .order-xxl-last {
    order: 6;
  }
  .m-xxl-0 {
    margin: 0;
  }
  .m-xxl-1 {
    margin: 8px;
  }
  .m-xxl-2 {
    margin: 16px;
  }
  .m-xxl-3 {
    margin: 24px;
  }
  .m-xxl-4 {
    margin: 32px;
  }
  .m-xxl-5 {
    margin: 40px;
  }
  .m-xxl-6 {
    margin: 48px;
  }
  .m-xxl-7 {
    margin: 56px;
  }
  .m-xxl-8 {
    margin: 64px;
  }
  .m-xxl-auto {
    margin: auto;
  }
  .mx-xxl-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-xxl-1 {
    margin-right: 8px;
    margin-left: 8px;
  }
  .mx-xxl-2 {
    margin-right: 16px;
    margin-left: 16px;
  }
  .mx-xxl-3 {
    margin-right: 24px;
    margin-left: 24px;
  }
  .mx-xxl-4 {
    margin-right: 32px;
    margin-left: 32px;
  }
  .mx-xxl-5 {
    margin-right: 40px;
    margin-left: 40px;
  }
  .mx-xxl-6 {
    margin-right: 48px;
    margin-left: 48px;
  }
  .mx-xxl-7 {
    margin-right: 56px;
    margin-left: 56px;
  }
  .mx-xxl-8 {
    margin-right: 64px;
    margin-left: 64px;
  }
  .mx-xxl-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-xxl-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-xxl-1 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .my-xxl-2 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .my-xxl-3 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .my-xxl-4 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .my-xxl-5 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .my-xxl-6 {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .my-xxl-7 {
    margin-top: 56px;
    margin-bottom: 56px;
  }
  .my-xxl-8 {
    margin-top: 64px;
    margin-bottom: 64px;
  }
  .my-xxl-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-xxl-0 {
    margin-top: 0;
  }
  .mt-xxl-1 {
    margin-top: 8px;
  }
  .mt-xxl-2 {
    margin-top: 16px;
  }
  .mt-xxl-3 {
    margin-top: 24px;
  }
  .mt-xxl-4 {
    margin-top: 32px;
  }
  .mt-xxl-5 {
    margin-top: 40px;
  }
  .mt-xxl-6 {
    margin-top: 48px;
  }
  .mt-xxl-7 {
    margin-top: 56px;
  }
  .mt-xxl-8 {
    margin-top: 64px;
  }
  .mt-xxl-auto {
    margin-top: auto;
  }
  .me-xxl-0 {
    margin-right: 0;
  }
  .me-xxl-1 {
    margin-right: 8px;
  }
  .me-xxl-2 {
    margin-right: 16px;
  }
  .me-xxl-3 {
    margin-right: 24px;
  }
  .me-xxl-4 {
    margin-right: 32px;
  }
  .me-xxl-5 {
    margin-right: 40px;
  }
  .me-xxl-6 {
    margin-right: 48px;
  }
  .me-xxl-7 {
    margin-right: 56px;
  }
  .me-xxl-8 {
    margin-right: 64px;
  }
  .me-xxl-auto {
    margin-right: auto;
  }
  .mb-xxl-0 {
    margin-bottom: 0;
  }
  .mb-xxl-1 {
    margin-bottom: 8px;
  }
  .mb-xxl-2 {
    margin-bottom: 16px;
  }
  .mb-xxl-3 {
    margin-bottom: 24px;
  }
  .mb-xxl-4 {
    margin-bottom: 32px;
  }
  .mb-xxl-5 {
    margin-bottom: 40px;
  }
  .mb-xxl-6 {
    margin-bottom: 48px;
  }
  .mb-xxl-7 {
    margin-bottom: 56px;
  }
  .mb-xxl-8 {
    margin-bottom: 64px;
  }
  .mb-xxl-auto {
    margin-bottom: auto;
  }
  .ms-xxl-0 {
    margin-left: 0;
  }
  .ms-xxl-1 {
    margin-left: 8px;
  }
  .ms-xxl-2 {
    margin-left: 16px;
  }
  .ms-xxl-3 {
    margin-left: 24px;
  }
  .ms-xxl-4 {
    margin-left: 32px;
  }
  .ms-xxl-5 {
    margin-left: 40px;
  }
  .ms-xxl-6 {
    margin-left: 48px;
  }
  .ms-xxl-7 {
    margin-left: 56px;
  }
  .ms-xxl-8 {
    margin-left: 64px;
  }
  .ms-xxl-auto {
    margin-left: auto;
  }
  .p-xxl-0 {
    padding: 0;
  }
  .p-xxl-1 {
    padding: 8px;
  }
  .p-xxl-2 {
    padding: 16px;
  }
  .p-xxl-3 {
    padding: 24px;
  }
  .p-xxl-4 {
    padding: 32px;
  }
  .p-xxl-5 {
    padding: 40px;
  }
  .p-xxl-6 {
    padding: 48px;
  }
  .p-xxl-7 {
    padding: 56px;
  }
  .p-xxl-8 {
    padding: 64px;
  }
  .px-xxl-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-xxl-1 {
    padding-right: 8px;
    padding-left: 8px;
  }
  .px-xxl-2 {
    padding-right: 16px;
    padding-left: 16px;
  }
  .px-xxl-3 {
    padding-right: 24px;
    padding-left: 24px;
  }
  .px-xxl-4 {
    padding-right: 32px;
    padding-left: 32px;
  }
  .px-xxl-5 {
    padding-right: 40px;
    padding-left: 40px;
  }
  .px-xxl-6 {
    padding-right: 48px;
    padding-left: 48px;
  }
  .px-xxl-7 {
    padding-right: 56px;
    padding-left: 56px;
  }
  .px-xxl-8 {
    padding-right: 64px;
    padding-left: 64px;
  }
  .py-xxl-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-xxl-1 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .py-xxl-2 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .py-xxl-3 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .py-xxl-4 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .py-xxl-5 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .py-xxl-6 {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .py-xxl-7 {
    padding-top: 56px;
    padding-bottom: 56px;
  }
  .py-xxl-8 {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .pt-xxl-0 {
    padding-top: 0;
  }
  .pt-xxl-1 {
    padding-top: 8px;
  }
  .pt-xxl-2 {
    padding-top: 16px;
  }
  .pt-xxl-3 {
    padding-top: 24px;
  }
  .pt-xxl-4 {
    padding-top: 32px;
  }
  .pt-xxl-5 {
    padding-top: 40px;
  }
  .pt-xxl-6 {
    padding-top: 48px;
  }
  .pt-xxl-7 {
    padding-top: 56px;
  }
  .pt-xxl-8 {
    padding-top: 64px;
  }
  .pe-xxl-0 {
    padding-right: 0;
  }
  .pe-xxl-1 {
    padding-right: 8px;
  }
  .pe-xxl-2 {
    padding-right: 16px;
  }
  .pe-xxl-3 {
    padding-right: 24px;
  }
  .pe-xxl-4 {
    padding-right: 32px;
  }
  .pe-xxl-5 {
    padding-right: 40px;
  }
  .pe-xxl-6 {
    padding-right: 48px;
  }
  .pe-xxl-7 {
    padding-right: 56px;
  }
  .pe-xxl-8 {
    padding-right: 64px;
  }
  .pb-xxl-0 {
    padding-bottom: 0;
  }
  .pb-xxl-1 {
    padding-bottom: 8px;
  }
  .pb-xxl-2 {
    padding-bottom: 16px;
  }
  .pb-xxl-3 {
    padding-bottom: 24px;
  }
  .pb-xxl-4 {
    padding-bottom: 32px;
  }
  .pb-xxl-5 {
    padding-bottom: 40px;
  }
  .pb-xxl-6 {
    padding-bottom: 48px;
  }
  .pb-xxl-7 {
    padding-bottom: 56px;
  }
  .pb-xxl-8 {
    padding-bottom: 64px;
  }
  .ps-xxl-0 {
    padding-left: 0;
  }
  .ps-xxl-1 {
    padding-left: 8px;
  }
  .ps-xxl-2 {
    padding-left: 16px;
  }
  .ps-xxl-3 {
    padding-left: 24px;
  }
  .ps-xxl-4 {
    padding-left: 32px;
  }
  .ps-xxl-5 {
    padding-left: 40px;
  }
  .ps-xxl-6 {
    padding-left: 48px;
  }
  .ps-xxl-7 {
    padding-left: 56px;
  }
  .ps-xxl-8 {
    padding-left: 64px;
  }
  .gap-xxl-0 {
    gap: 0;
  }
  .gap-xxl-1 {
    gap: 8px;
  }
  .gap-xxl-2 {
    gap: 16px;
  }
  .gap-xxl-3 {
    gap: 24px;
  }
  .gap-xxl-4 {
    gap: 32px;
  }
  .gap-xxl-5 {
    gap: 40px;
  }
  .gap-xxl-6 {
    gap: 48px;
  }
  .gap-xxl-7 {
    gap: 56px;
  }
  .gap-xxl-8 {
    gap: 64px;
  }
  .row-gap-xxl-0 {
    row-gap: 0;
  }
  .row-gap-xxl-1 {
    row-gap: 8px;
  }
  .row-gap-xxl-2 {
    row-gap: 16px;
  }
  .row-gap-xxl-3 {
    row-gap: 24px;
  }
  .row-gap-xxl-4 {
    row-gap: 32px;
  }
  .row-gap-xxl-5 {
    row-gap: 40px;
  }
  .row-gap-xxl-6 {
    row-gap: 48px;
  }
  .row-gap-xxl-7 {
    row-gap: 56px;
  }
  .row-gap-xxl-8 {
    row-gap: 64px;
  }
  .column-gap-xxl-0 {
    column-gap: 0;
  }
  .column-gap-xxl-1 {
    column-gap: 8px;
  }
  .column-gap-xxl-2 {
    column-gap: 16px;
  }
  .column-gap-xxl-3 {
    column-gap: 24px;
  }
  .column-gap-xxl-4 {
    column-gap: 32px;
  }
  .column-gap-xxl-5 {
    column-gap: 40px;
  }
  .column-gap-xxl-6 {
    column-gap: 48px;
  }
  .column-gap-xxl-7 {
    column-gap: 56px;
  }
  .column-gap-xxl-8 {
    column-gap: 64px;
  }
  .text-xxl-start {
    text-align: left;
  }
  .text-xxl-end {
    text-align: right;
  }
  .text-xxl-center {
    text-align: center;
  }
}
@media (min-width: 1920px) {
  .float-fullhd-start {
    float: left;
  }
  .float-fullhd-end {
    float: right;
  }
  .float-fullhd-none {
    float: none;
  }
  .object-fit-fullhd-contain {
    object-fit: contain;
  }
  .object-fit-fullhd-cover {
    object-fit: cover;
  }
  .object-fit-fullhd-fill {
    object-fit: fill;
  }
  .object-fit-fullhd-scale {
    object-fit: scale-down;
  }
  .object-fit-fullhd-none {
    object-fit: none;
  }
  .d-fullhd-inline {
    display: inline;
  }
  .d-fullhd-inline-block {
    display: inline-block;
  }
  .d-fullhd-block {
    display: block;
  }
  .d-fullhd-grid {
    display: grid;
  }
  .d-fullhd-inline-grid {
    display: inline-grid;
  }
  .d-fullhd-table {
    display: table;
  }
  .d-fullhd-table-row {
    display: table-row;
  }
  .d-fullhd-table-cell {
    display: table-cell;
  }
  .d-fullhd-flex {
    display: flex;
  }
  .d-fullhd-inline-flex {
    display: inline-flex;
  }
  .d-fullhd-none {
    display: none;
  }
  .flex-fullhd-fill {
    flex: 1 1 auto;
  }
  .flex-fullhd-row {
    flex-direction: row;
  }
  .flex-fullhd-column {
    flex-direction: column;
  }
  .flex-fullhd-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-fullhd-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-fullhd-grow-0 {
    flex-grow: 0;
  }
  .flex-fullhd-grow-1 {
    flex-grow: 1;
  }
  .flex-fullhd-shrink-0 {
    flex-shrink: 0;
  }
  .flex-fullhd-shrink-1 {
    flex-shrink: 1;
  }
  .flex-fullhd-wrap {
    flex-wrap: wrap;
  }
  .flex-fullhd-nowrap {
    flex-wrap: nowrap;
  }
  .flex-fullhd-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .justify-content-fullhd-start {
    justify-content: flex-start;
  }
  .justify-content-fullhd-end {
    justify-content: flex-end;
  }
  .justify-content-fullhd-center {
    justify-content: center;
  }
  .justify-content-fullhd-between {
    justify-content: space-between;
  }
  .justify-content-fullhd-around {
    justify-content: space-around;
  }
  .justify-content-fullhd-evenly {
    justify-content: space-evenly;
  }
  .align-items-fullhd-start {
    align-items: flex-start;
  }
  .align-items-fullhd-end {
    align-items: flex-end;
  }
  .align-items-fullhd-center {
    align-items: center;
  }
  .align-items-fullhd-baseline {
    align-items: baseline;
  }
  .align-items-fullhd-stretch {
    align-items: stretch;
  }
  .align-content-fullhd-start {
    align-content: flex-start;
  }
  .align-content-fullhd-end {
    align-content: flex-end;
  }
  .align-content-fullhd-center {
    align-content: center;
  }
  .align-content-fullhd-between {
    align-content: space-between;
  }
  .align-content-fullhd-around {
    align-content: space-around;
  }
  .align-content-fullhd-stretch {
    align-content: stretch;
  }
  .align-self-fullhd-auto {
    align-self: auto;
  }
  .align-self-fullhd-start {
    align-self: flex-start;
  }
  .align-self-fullhd-end {
    align-self: flex-end;
  }
  .align-self-fullhd-center {
    align-self: center;
  }
  .align-self-fullhd-baseline {
    align-self: baseline;
  }
  .align-self-fullhd-stretch {
    align-self: stretch;
  }
  .order-fullhd-first {
    order: -1;
  }
  .order-fullhd-0 {
    order: 0;
  }
  .order-fullhd-1 {
    order: 1;
  }
  .order-fullhd-2 {
    order: 2;
  }
  .order-fullhd-3 {
    order: 3;
  }
  .order-fullhd-4 {
    order: 4;
  }
  .order-fullhd-5 {
    order: 5;
  }
  .order-fullhd-last {
    order: 6;
  }
  .m-fullhd-0 {
    margin: 0;
  }
  .m-fullhd-1 {
    margin: 8px;
  }
  .m-fullhd-2 {
    margin: 16px;
  }
  .m-fullhd-3 {
    margin: 24px;
  }
  .m-fullhd-4 {
    margin: 32px;
  }
  .m-fullhd-5 {
    margin: 40px;
  }
  .m-fullhd-6 {
    margin: 48px;
  }
  .m-fullhd-7 {
    margin: 56px;
  }
  .m-fullhd-8 {
    margin: 64px;
  }
  .m-fullhd-auto {
    margin: auto;
  }
  .mx-fullhd-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-fullhd-1 {
    margin-right: 8px;
    margin-left: 8px;
  }
  .mx-fullhd-2 {
    margin-right: 16px;
    margin-left: 16px;
  }
  .mx-fullhd-3 {
    margin-right: 24px;
    margin-left: 24px;
  }
  .mx-fullhd-4 {
    margin-right: 32px;
    margin-left: 32px;
  }
  .mx-fullhd-5 {
    margin-right: 40px;
    margin-left: 40px;
  }
  .mx-fullhd-6 {
    margin-right: 48px;
    margin-left: 48px;
  }
  .mx-fullhd-7 {
    margin-right: 56px;
    margin-left: 56px;
  }
  .mx-fullhd-8 {
    margin-right: 64px;
    margin-left: 64px;
  }
  .mx-fullhd-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-fullhd-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-fullhd-1 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .my-fullhd-2 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .my-fullhd-3 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .my-fullhd-4 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .my-fullhd-5 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .my-fullhd-6 {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .my-fullhd-7 {
    margin-top: 56px;
    margin-bottom: 56px;
  }
  .my-fullhd-8 {
    margin-top: 64px;
    margin-bottom: 64px;
  }
  .my-fullhd-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-fullhd-0 {
    margin-top: 0;
  }
  .mt-fullhd-1 {
    margin-top: 8px;
  }
  .mt-fullhd-2 {
    margin-top: 16px;
  }
  .mt-fullhd-3 {
    margin-top: 24px;
  }
  .mt-fullhd-4 {
    margin-top: 32px;
  }
  .mt-fullhd-5 {
    margin-top: 40px;
  }
  .mt-fullhd-6 {
    margin-top: 48px;
  }
  .mt-fullhd-7 {
    margin-top: 56px;
  }
  .mt-fullhd-8 {
    margin-top: 64px;
  }
  .mt-fullhd-auto {
    margin-top: auto;
  }
  .me-fullhd-0 {
    margin-right: 0;
  }
  .me-fullhd-1 {
    margin-right: 8px;
  }
  .me-fullhd-2 {
    margin-right: 16px;
  }
  .me-fullhd-3 {
    margin-right: 24px;
  }
  .me-fullhd-4 {
    margin-right: 32px;
  }
  .me-fullhd-5 {
    margin-right: 40px;
  }
  .me-fullhd-6 {
    margin-right: 48px;
  }
  .me-fullhd-7 {
    margin-right: 56px;
  }
  .me-fullhd-8 {
    margin-right: 64px;
  }
  .me-fullhd-auto {
    margin-right: auto;
  }
  .mb-fullhd-0 {
    margin-bottom: 0;
  }
  .mb-fullhd-1 {
    margin-bottom: 8px;
  }
  .mb-fullhd-2 {
    margin-bottom: 16px;
  }
  .mb-fullhd-3 {
    margin-bottom: 24px;
  }
  .mb-fullhd-4 {
    margin-bottom: 32px;
  }
  .mb-fullhd-5 {
    margin-bottom: 40px;
  }
  .mb-fullhd-6 {
    margin-bottom: 48px;
  }
  .mb-fullhd-7 {
    margin-bottom: 56px;
  }
  .mb-fullhd-8 {
    margin-bottom: 64px;
  }
  .mb-fullhd-auto {
    margin-bottom: auto;
  }
  .ms-fullhd-0 {
    margin-left: 0;
  }
  .ms-fullhd-1 {
    margin-left: 8px;
  }
  .ms-fullhd-2 {
    margin-left: 16px;
  }
  .ms-fullhd-3 {
    margin-left: 24px;
  }
  .ms-fullhd-4 {
    margin-left: 32px;
  }
  .ms-fullhd-5 {
    margin-left: 40px;
  }
  .ms-fullhd-6 {
    margin-left: 48px;
  }
  .ms-fullhd-7 {
    margin-left: 56px;
  }
  .ms-fullhd-8 {
    margin-left: 64px;
  }
  .ms-fullhd-auto {
    margin-left: auto;
  }
  .p-fullhd-0 {
    padding: 0;
  }
  .p-fullhd-1 {
    padding: 8px;
  }
  .p-fullhd-2 {
    padding: 16px;
  }
  .p-fullhd-3 {
    padding: 24px;
  }
  .p-fullhd-4 {
    padding: 32px;
  }
  .p-fullhd-5 {
    padding: 40px;
  }
  .p-fullhd-6 {
    padding: 48px;
  }
  .p-fullhd-7 {
    padding: 56px;
  }
  .p-fullhd-8 {
    padding: 64px;
  }
  .px-fullhd-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-fullhd-1 {
    padding-right: 8px;
    padding-left: 8px;
  }
  .px-fullhd-2 {
    padding-right: 16px;
    padding-left: 16px;
  }
  .px-fullhd-3 {
    padding-right: 24px;
    padding-left: 24px;
  }
  .px-fullhd-4 {
    padding-right: 32px;
    padding-left: 32px;
  }
  .px-fullhd-5 {
    padding-right: 40px;
    padding-left: 40px;
  }
  .px-fullhd-6 {
    padding-right: 48px;
    padding-left: 48px;
  }
  .px-fullhd-7 {
    padding-right: 56px;
    padding-left: 56px;
  }
  .px-fullhd-8 {
    padding-right: 64px;
    padding-left: 64px;
  }
  .py-fullhd-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-fullhd-1 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .py-fullhd-2 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .py-fullhd-3 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .py-fullhd-4 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .py-fullhd-5 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .py-fullhd-6 {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .py-fullhd-7 {
    padding-top: 56px;
    padding-bottom: 56px;
  }
  .py-fullhd-8 {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .pt-fullhd-0 {
    padding-top: 0;
  }
  .pt-fullhd-1 {
    padding-top: 8px;
  }
  .pt-fullhd-2 {
    padding-top: 16px;
  }
  .pt-fullhd-3 {
    padding-top: 24px;
  }
  .pt-fullhd-4 {
    padding-top: 32px;
  }
  .pt-fullhd-5 {
    padding-top: 40px;
  }
  .pt-fullhd-6 {
    padding-top: 48px;
  }
  .pt-fullhd-7 {
    padding-top: 56px;
  }
  .pt-fullhd-8 {
    padding-top: 64px;
  }
  .pe-fullhd-0 {
    padding-right: 0;
  }
  .pe-fullhd-1 {
    padding-right: 8px;
  }
  .pe-fullhd-2 {
    padding-right: 16px;
  }
  .pe-fullhd-3 {
    padding-right: 24px;
  }
  .pe-fullhd-4 {
    padding-right: 32px;
  }
  .pe-fullhd-5 {
    padding-right: 40px;
  }
  .pe-fullhd-6 {
    padding-right: 48px;
  }
  .pe-fullhd-7 {
    padding-right: 56px;
  }
  .pe-fullhd-8 {
    padding-right: 64px;
  }
  .pb-fullhd-0 {
    padding-bottom: 0;
  }
  .pb-fullhd-1 {
    padding-bottom: 8px;
  }
  .pb-fullhd-2 {
    padding-bottom: 16px;
  }
  .pb-fullhd-3 {
    padding-bottom: 24px;
  }
  .pb-fullhd-4 {
    padding-bottom: 32px;
  }
  .pb-fullhd-5 {
    padding-bottom: 40px;
  }
  .pb-fullhd-6 {
    padding-bottom: 48px;
  }
  .pb-fullhd-7 {
    padding-bottom: 56px;
  }
  .pb-fullhd-8 {
    padding-bottom: 64px;
  }
  .ps-fullhd-0 {
    padding-left: 0;
  }
  .ps-fullhd-1 {
    padding-left: 8px;
  }
  .ps-fullhd-2 {
    padding-left: 16px;
  }
  .ps-fullhd-3 {
    padding-left: 24px;
  }
  .ps-fullhd-4 {
    padding-left: 32px;
  }
  .ps-fullhd-5 {
    padding-left: 40px;
  }
  .ps-fullhd-6 {
    padding-left: 48px;
  }
  .ps-fullhd-7 {
    padding-left: 56px;
  }
  .ps-fullhd-8 {
    padding-left: 64px;
  }
  .gap-fullhd-0 {
    gap: 0;
  }
  .gap-fullhd-1 {
    gap: 8px;
  }
  .gap-fullhd-2 {
    gap: 16px;
  }
  .gap-fullhd-3 {
    gap: 24px;
  }
  .gap-fullhd-4 {
    gap: 32px;
  }
  .gap-fullhd-5 {
    gap: 40px;
  }
  .gap-fullhd-6 {
    gap: 48px;
  }
  .gap-fullhd-7 {
    gap: 56px;
  }
  .gap-fullhd-8 {
    gap: 64px;
  }
  .row-gap-fullhd-0 {
    row-gap: 0;
  }
  .row-gap-fullhd-1 {
    row-gap: 8px;
  }
  .row-gap-fullhd-2 {
    row-gap: 16px;
  }
  .row-gap-fullhd-3 {
    row-gap: 24px;
  }
  .row-gap-fullhd-4 {
    row-gap: 32px;
  }
  .row-gap-fullhd-5 {
    row-gap: 40px;
  }
  .row-gap-fullhd-6 {
    row-gap: 48px;
  }
  .row-gap-fullhd-7 {
    row-gap: 56px;
  }
  .row-gap-fullhd-8 {
    row-gap: 64px;
  }
  .column-gap-fullhd-0 {
    column-gap: 0;
  }
  .column-gap-fullhd-1 {
    column-gap: 8px;
  }
  .column-gap-fullhd-2 {
    column-gap: 16px;
  }
  .column-gap-fullhd-3 {
    column-gap: 24px;
  }
  .column-gap-fullhd-4 {
    column-gap: 32px;
  }
  .column-gap-fullhd-5 {
    column-gap: 40px;
  }
  .column-gap-fullhd-6 {
    column-gap: 48px;
  }
  .column-gap-fullhd-7 {
    column-gap: 56px;
  }
  .column-gap-fullhd-8 {
    column-gap: 64px;
  }
  .text-fullhd-start {
    text-align: left;
  }
  .text-fullhd-end {
    text-align: right;
  }
  .text-fullhd-center {
    text-align: center;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem;
  }
  .fs-2 {
    font-size: 2rem;
  }
  .fs-3 {
    font-size: 1.75rem;
  }
  .fs-4 {
    font-size: 1.5rem;
  }
}
@media print {
  .d-print-inline {
    display: inline;
  }
  .d-print-inline-block {
    display: inline-block;
  }
  .d-print-block {
    display: block;
  }
  .d-print-grid {
    display: grid;
  }
  .d-print-inline-grid {
    display: inline-grid;
  }
  .d-print-table {
    display: table;
  }
  .d-print-table-row {
    display: table-row;
  }
  .d-print-table-cell {
    display: table-cell;
  }
  .d-print-flex {
    display: flex;
  }
  .d-print-inline-flex {
    display: inline-flex;
  }
  .d-print-none {
    display: none;
  }
}
/*! purgecss start ignore */
.container {
  padding-right: 26px;
  padding-left: 26px;
}
@media (max-width: 575.98px) {
  .container {
    padding-right: 16px;
    padding-left: 16px;
  }
}
@media (max-width: 359px) {
  .container {
    padding-right: 8px;
    padding-left: 8px;
  }
}

.fancybox__container {
  --fancybox-accent-color: var(--c-primary);
  --fancybox-thumbs-border-radius: 0;
}

body {
  color: #292934;
  font-family: "Noto Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale !important;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow-x: hidden;
}

figure {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
  font-weight: 700;
}

html {
  scroll-behavior: smooth;
  height: 100%;
}

iframe {
  display: block;
  max-width: 100%;
}

img {
  display: block;
  opacity: 1;
  transition: opacity 0.5s ease;
  height: auto;
  max-width: 100%;
}
img[data-src], img[data-srcset] {
  display: block;
  min-height: 1px;
  min-width: 1px;
}
img[data-src]:not(.loaded) {
  opacity: 0;
}

a {
  display: inline-block;
  color: var(--c-primary);
  transition: text-decoration-color 0.3s ease;
  text-decoration: underline;
}
a:hover, a:focus {
  color: var(--c-primary);
  text-decoration-color: transparent;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

main {
  display: block;
  overflow-x: hidden;
  flex-grow: 1;
}

:root {
  --c-primary: #0066b3;
  --c-primary-500: rgb(0, 96, 168);
}

strong,
b {
  font-weight: 700;
}

.entry-content .column {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 24px;
}
.entry-content .column img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  float: none !important;
}
@media (max-width: 991.98px) {
  .entry-content .column h2:first-of-type,
  .entry-content .column h3:first-of-type,
  .entry-content .column h4:first-of-type,
  .entry-content .column h5:first-of-type,
  .entry-content .column h6:first-of-type {
    margin-top: 0;
  }
}
.entry-content .columns-1 {
  display: block;
}
.entry-content .columns-1 .column {
  display: block;
}
.entry-content .columns-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}
@media (max-width: 991.98px) {
  .entry-content .columns-2 {
    display: flex;
    flex-direction: column;
  }
}
.entry-content .columns-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}
@media (max-width: 991.98px) {
  .entry-content .columns-3 {
    display: flex;
    flex-direction: column;
  }
}
.entry-content .columns-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}
@media (max-width: 1199.98px) {
  .entry-content .columns-4 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 991.98px) {
  .entry-content .columns-4 {
    display: flex;
    flex-direction: column;
  }
}

div.validator {
  height: 0;
  position: absolute;
  top: calc(100% + 2px);
  left: -1px;
}
div.validator > span.erorr-s {
  position: absolute;
  padding: 2px 8px;
  display: block;
  left: 0;
  top: 0;
  white-space: nowrap;
  border-radius: 0;
  color: #fff;
  font-size: 12px;
  background: #f93a3a;
  z-index: 99;
}

.alignnone {
  margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 48px auto;
}
.aligncenter img,
div.aligncenter img {
  margin-inline: auto;
}

.alignright {
  float: right;
  margin: 0 0 20px 30px;
}

.alignleft {
  float: left;
  margin: 0 30px 20px 0;
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
}

@media (max-width: 575.98px) {
  .alignleft,
  .alignright {
    float: none;
    display: block;
    margin: 0;
    margin-bottom: 24px;
    margin-left: auto;
    margin-right: auto;
  }
}
.u-bg-primary {
  background-color: var(--c-primary);
}

.u-bg-gray {
  background-color: #fafafa;
}

.u-bg-white {
  background-color: #fff;
}

.u-gray-box {
  --box-size: 24px;
  --background: #fafafa;
  --decoration: block;
  position: relative;
  background-color: var(--background);
  border: 1px solid #ececec;
}
.u-gray-box::before {
  content: "";
  position: absolute;
  display: var(--decoration);
  width: var(--box-size);
  height: var(--box-size);
  border-left: 4px solid #ececec;
  border-top: 4px solid #ececec;
  top: 0;
  left: 0;
}
.u-gray-box.--bg-white {
  --background: #fff;
}
.u-gray-box.--without-decoration {
  --decoration: none;
}

.u-btn-reset {
  user-select: none;
  border: none;
  cursor: pointer;
  touch-action: manipulation;
  padding: 0;
  margin: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  appearance: none;
}
.u-btn-reset:focus, .u-btn-reset:active {
  outline: 0;
  text-decoration: none;
}
.u-btn-reset :focus:not(:focus-visible) {
  outline: none;
}
.u-btn-reset :focus:not(:-moz-focusring) {
  outline: none;
}

.u-color-primary {
  color: var(--c-primary);
}

.u-color-black {
  color: #292934;
}

.u-cursor-pointer {
  cursor: pointer;
}

.u-fz-12 {
  font-size: 12px;
}

.u-fz-14 {
  font-size: 14px;
}

.u-fz-16 {
  font-size: 16px;
}

.u-fz-18 {
  font-size: 18px;
}

.u-fz-20 {
  font-size: 20px;
}

.u-fz-24 {
  font-size: 24px;
}

.u-fw-400 {
  font-weight: 400;
}

.u-fw-500 {
  font-weight: 500;
}

.u-fw-600 {
  font-weight: 600;
}

.u-fw-700 {
  font-weight: 700;
}

.u-fw-800 {
  font-weight: 800;
}

.u-font-secondary {
  font-family: "Noto Serif", serif;
}

.u-img-hover {
  overflow: hidden;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}
.u-img-hover img {
  transition-property: opacity, transform;
}
.u-img-hover:hover img {
  transform: scale(1.05);
}

.u-top-line {
  position: relative;
  --position: 0;
}
.u-top-line::before {
  content: "";
  position: absolute;
  display: block;
  left: 50%;
  top: var(--position);
  transform: translateX(-50%);
  width: 100vw;
  height: 1px;
  background-color: #ececec;
}

.--loading {
  position: relative;
  pointer-events: none;
}
.--loading::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
  z-index: 2000;
  transition: opacity 0.3s ease;
  bottom: 0;
  left: 0;
  opacity: 0.3;
}
.--loading::after {
  content: "";
  position: absolute;
  display: block;
  width: 30px;
  height: 30px;
  left: 50%;
  top: 50%;
  margin-top: -15px;
  margin-left: -15px;
  transform: translateX(-50%) translateY(-50%);
  border: 4px solid #fafafa;
  border-bottom-color: var(--c-primary);
  border-radius: 50%;
  animation: rotation 1s ease infinite;
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  z-index: 2001;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.u-scrollbar .os-theme-dark.os-scrollbar-vertical {
  width: 2px;
  padding: 0;
  border-radius: 0;
  transition: width 0.3s ease;
}
.u-scrollbar .os-theme-dark.os-scrollbar > .os-scrollbar-track {
  background: #ececec;
  border-radius: 0;
}
.u-scrollbar .os-theme-dark.os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
  background: #a9a9ae;
  border-radius: 0;
}
.u-scrollbar:hover .os-theme-dark.os-scrollbar-vertical {
  width: 6px;
}

.u-posr {
  position: relative;
}

.u-posa {
  position: absolute;
}

.u-fixed {
  position: fixed;
}

.u-top-50-p {
  top: 50%;
}

.u-left-50-p {
  left: 50%;
}

.u-top-8 {
  top: 8px;
}

.u-bottom-8 {
  bottom: 8px;
}

.u-left-8 {
  left: 8px;
}

.u-right-8 {
  right: 8px;
}

.u-top-16 {
  top: 16px;
}

.u-bottom-16 {
  bottom: 16px;
}

.u-left-16 {
  left: 16px;
}

.u-right-16 {
  right: 16px;
}

.u-translate-middle-x {
  transform: translateX(-50%);
}

.u-translate-middle-y {
  transform: translateY(-50%);
}

.u-translate-middle {
  transform: translateX(-50%) translateY(-50%);
}

.u-z-index-10 {
  z-index: 10;
}

.u-z-index-2 {
  z-index: 2;
}

.u-z-index-1 {
  z-index: 1;
}

.u-z-index-0 {
  z-index: 0;
}

.u-z-index-negative {
  z-index: -1;
}

.u-product-image {
  outline: 1px solid #ececec;
}

.u-line-through {
  text-decoration: line-through;
}

.u-text-center {
  text-align: center;
}

.u-text-right {
  text-align: right;
}

.u-w-100 {
  width: 100%;
}

.u-mw-100 {
  max-width: 100%;
}

.action-button {
  --size: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--size);
  height: var(--size);
  background-color: transparent;
  z-index: 2;
}
.action-button::before {
  content: "";
  position: absolute;
  display: block;
  width: var(--size);
  height: var(--size);
  background-color: #ececec;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  z-index: -1;
}
.action-button:hover::before {
  opacity: 1;
}
.action-button.--size-sm {
  --size: 40px;
}
.action-button.--size-sm .icon {
  --size: 20px;
}

.c-alert {
  --background: #e7f4e4;
  --border: #48a463;
  --opacity: 0;
  --translate: -32px;
  --color: #48a463;
  background-color: var(--background);
  opacity: var(--opacity);
  transform: translateY(var(--translate));
  transition: opacity 0.3s ease;
  transition-property: opacity, transform;
  will-change: opacity, transform;
  border-width: 1px;
  border-style: solid;
  border-color: var(--border);
  color: var(--color);
}
.c-alert.--show {
  --opacity: 1;
  --translate: 0;
}
.c-alert.--success {
  --border: #48a463;
  --background: #e7f4e4;
  --color: #48a463;
}
.c-alert.--error {
  --border: #e21c3d;
  --background: #f9dede;
  --color: #e21c3d;
}

.base-header__paragraph {
  margin-bottom: 0;
  max-width: 592px;
  margin-inline: auto;
}

.base-header {
  position: relative;
  text-align: center;
  margin: 0 auto;
  max-width: 720px;
  margin-bottom: 64px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.base-header.--left {
  text-align: left;
  margin-right: 0;
  margin-left: 0;
}
.base-header.--left .base-header__paragraph {
  margin-inline: 0;
}
.base-header.--spacing-bottom-0 {
  margin-bottom: 0;
}
.base-header.--spacing-md {
  --spacing: 40px;
}

.base-heading {
  --font-family: Noto Serif, serif;
  margin-bottom: 0;
  font-family: var(--font-family);
}
.base-heading--h1 {
  font-size: 40px;
}
@media (max-width: 575.98px) {
  .base-heading--h1 {
    font-size: 32px;
  }
}
.base-heading--h2 {
  font-size: 40px;
}
@media (max-width: 575.98px) {
  .base-heading--h2 {
    font-size: 32px;
  }
}
.base-heading--h3 {
  font-size: 32px;
}
@media (max-width: 575.98px) {
  .base-heading--h3 {
    font-size: 24px;
  }
}
.base-heading--h4 {
  font-size: 24px;
}
@media (max-width: 575.98px) {
  .base-heading--h4 {
    font-size: 18px;
  }
}
.base-heading--h5 {
  font-size: 18px;
}
@media (max-width: 575.98px) {
  .base-heading--h5 {
    font-size: 16px;
  }
}

.base-section {
  --padding-y: 56px;
  padding-top: var(--padding-y);
  padding-bottom: var(--padding-y);
}
@media (max-width: 575.98px) {
  .base-section {
    --padding-y: 48px;
  }
}
.base-section.--p-top-0 {
  padding-top: 0;
}
.base-section.--p-bottom-0 {
  padding-bottom: 0;
}
.base-section.--p-y-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.base-subheading {
  text-transform: uppercase;
  font-size: 16px;
}
.base-subheading.--orange {
  color: #ebab80;
}
.base-subheading.--gray {
  color: #a9a9ae;
}

.b-cart__header {
  padding: 24px 32px;
}

.b-cart__body-header {
  display: grid;
  gap: 24px;
  grid-template-columns: 364px 1fr 1fr 40px 1fr 46px;
  padding: 24px 32px;
  border-top: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
}
@media (max-width: 991.98px) {
  .b-cart__body-header {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .b-cart__body-header {
    gap: 16px;
  }
}

@media (max-width: 991.98px) {
  .b-cart__body {
    gap: 32px;
  }
}

.b-login {
  max-width: 575px;
  margin-inline: auto;
}

.b-password-new {
  max-width: 575px;
  margin-inline: auto;
}

.b-password-reset {
  max-width: 575px;
  margin-inline: auto;
}

.b-register {
  max-width: 575px;
  margin-inline: auto;
}

.b-search {
  opacity: 0;
  pointer-events: auto;
  transition: opacity 0.3s ease;
  box-shadow: 0 0 40px rgba(41, 41, 52, 0.0509803922);
}
.b-search.--show {
  opacity: 1;
  pointer-events: auto;
}

.btn {
  --direction-offset: 8px;
}
.btn.--size-xs {
  --direction-offset: 4px;
}
@media (hover: hover) {
  .btn.--animation-right:hover svg {
    transform: translateX(var(--direction-offset));
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  }
}
@media (hover: hover) {
  .btn.--animation-left:hover svg {
    transform: translateX(calc(-1 * var(--direction-offset)));
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  }
}
@media (hover: hover) {
  .btn.--animation-zoom:hover svg {
    transform: scale(1.1);
  }
}
@media (hover: hover) {
  .btn.--animation-down:hover svg {
    transform: translateY(var(--direction-offset));
  }
}

.btn.--primary {
  --bg-color: var(--c-primary);
  --font-color: #fff;
  color: var(--font-color);
}
.btn.--primary .btn__body {
  background-color: var(--bg-color);
  clip-path: polygon(0 48px, 0 24px, 24px 0, 100% 0, 100% 100%, 0 100%, 0 48px, 24px 16px);
  transition: clip-path 0.3s ease;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
.btn.--primary::after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 25px 25px 0 0;
  border-color: var(--bg-color) transparent transparent;
  top: 0;
  left: 0;
  transition: transform 0.3s ease;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
.btn.--primary path {
  fill: var(--font-color);
}
@media (hover: hover) {
  .btn.--primary:not(.--disabled):hover {
    color: var(--font-color);
  }
  .btn.--primary:not(.--disabled):hover .btn__body {
    clip-path: polygon(0 42px, 0 24px, 24px 0, 100% 0, 100% 100%, 0 100%, 0 48px, 24px 16px);
  }
  .btn.--primary:not(.--disabled):hover::after {
    transform: translateX(-6px) rotate(-4deg);
  }
}
.btn.--black {
  --bg-color: #292934;
  --font-color: #fff;
  color: var(--font-color);
}
.btn.--black .btn__body { /* stylelint-disable-line no-descending-specificity */
  background-color: var(--bg-color);
  clip-path: polygon(0 48px, 0 24px, 24px 0, 100% 0, 100% 100%, 0 100%, 0 48px, 24px 16px);
  transition: clip-path 0.3s ease;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
.btn.--black::after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 25px 25px 0 0;
  border-color: var(--bg-color) transparent transparent;
  top: 0;
  left: 0;
  transition: transform 0.3s ease;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
.btn.--black path {
  fill: var(--font-color);
}
@media (hover: hover) {
  .btn.--black:not(.--disabled):hover {
    color: var(--font-color);
  }
  .btn.--black:not(.--disabled):hover .btn__body {
    clip-path: polygon(0 42px, 0 24px, 24px 0, 100% 0, 100% 100%, 0 100%, 0 48px, 24px 16px);
  }
  .btn.--black:not(.--disabled):hover::after {
    transform: translateX(-6px) rotate(-4deg);
  }
}
.btn.--outline {
  box-shadow: 0 0 0 1px #ececec;
  color: #292934;
  border: none;
  font-weight: 700;
  background-color: #fff;
}
.btn.--outline:hover {
  background-color: #fafafa;
  color: #292934;
}
.btn.--outline:active, .btn.--outline:focus {
  color: #292934;
}
.btn.--outline path {
  fill: #292934;
}

.btn__icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn__icon .icon svg {
  height: 16px;
  width: 16px;
  transition: transform 0.3s ease;
}

.btn__body {
  display: flex;
  column-gap: 8px;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  min-height: 64px;
  padding: 20px 32px;
}

.btn {
  --display: inline-flex;
  user-select: none;
  position: relative;
  transition: background-color 0.3s ease-out;
  transition-property: background-color;
  display: var(--display);
  font-family: "Noto Sans", sans-serif;
  color: #fff;
  border-radius: 0;
  border: none;
  z-index: 1;
  text-decoration: none;
  touch-action: manipulation;
  cursor: pointer;
  padding: 0;
  margin: 0;
}
.btn:hover {
  text-decoration: none;
  color: #fff;
}
.btn:focus, .btn:active {
  outline: 0;
  color: #fff;
  text-decoration: none;
}
.btn::before {
  --size: 8px;
  content: "";
  position: absolute;
  display: block;
  width: var(--size);
  height: var(--size);
  top: calc(var(--size) / 2);
  right: calc(var(--size) / 2);
  border-radius: calc(var(--size) / 2);
  background-color: #0066b3;
  transform: scale(0);
  pointer-events: none;
  transition: transform 0.3s ease;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
.btn.--size-sm .btn__body {
  padding: 12px 32px;
  min-height: 48px;
  max-width: 100%;
}
.btn.--size-xs .btn__body {
  padding: 8px 16px;
  min-width: auto;
  min-height: 32px;
  max-width: 100%;
}
.btn.--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.btn.--icon-before .btn__icon {
  order: -1;
}
.btn.--active::before {
  transform: scale(1);
}
@media (max-width: 575.98px) {
  .btn.--full-width-mobile {
    width: 100%;
  }
}
.btn.--full-width {
  width: 100%;
}
.btn.--full-width .btn__body { /* stylelint-disable-line no-descending-specificity */
  min-width: auto;
  padding: 0;
}
.btn.--icon-only {
  --display: flex;
  width: 64px;
  height: 64px;
  justify-content: center;
  align-items: center;
  padding: 0;
  flex-shrink: 0;
}
.btn.--icon-only .btn__body { /* stylelint-disable-line no-descending-specificity */
  padding: 0;
  min-width: auto;
}
.btn.--icon-only .btn__title {
  display: none;
}
.btn.--icon-only .btn__icon svg {
  width: 24px;
  height: 24px;
}
.btn.--icon-only.--size-sm {
  width: 48px;
  height: 48px;
}
.btn.--icon-only.--size-sm .btn__body {
  width: 48px;
  height: 48px;
  flex-basis: 48px;
}
.btn.--icon-only.--size-sm .btn__icon svg {
  width: 14px;
  height: 14px;
}
.btn.--icon-only.--size-xs {
  width: 32px;
  height: 32px;
}
.btn.--icon-only.--size-xs .btn__icon svg {
  width: 14px;
  height: 14px;
}
.btn.--hidden {
  --display: none;
}

@media (min-width: 992px) {
  .content-w-aside__content {
    grid-column: 1/span 9;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .content-w-aside__content {
    grid-column: 1/span 8;
  }
}

.content-w-aside__row {
  gap: 32px;
}
@media (min-width: 992px) {
  .content-w-aside__row {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(12, 1fr);
  }
}
@media (max-width: 991.98px) {
  .content-w-aside__row {
    display: flex;
    flex-direction: column;
  }
}
.content-w-aside__row > .content-w-aside__content:last-child {
  grid-column: 3/span 8;
}

.content-w-aside__aside {
  --padding-top: 56px;
  padding-top: var(--padding-top);
}
@media (max-width: 991.98px) {
  .content-w-aside__aside {
    --padding-top: 0;
  }
}
@media (min-width: 992px) {
  .content-w-aside__aside {
    grid-column: span 3/-1;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .content-w-aside__aside {
    grid-column: span 4/-1;
  }
}

.c-counter {
  --width: 152px;
  border: 1px solid #ececec;
  min-height: 64px;
  width: var(--width);
  user-select: none;
  transition: background-color 0.3s ease;
}
.c-counter:hover {
  background-color: #fafafa;
}

.c-counter__value-visual {
  gap: 4px;
}

.c-counter__button {
  width: 24px;
  height: 24px;
  z-index: 2;
  cursor: pointer;
}
.c-counter__button > svg {
  z-index: 2;
}
.c-counter__button::before {
  content: "";
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: #ececec;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.c-counter__button:hover::before {
  opacity: 1;
}

.main-decoration {
  position: absolute;
  right: 0;
  top: 0;
  pointer-events: none;
  user-select: none;
  z-index: -1;
}
.main-decoration.--left {
  left: 0;
  bottom: -56px;
  top: auto;
  right: auto;
  transform: scale(-1, -1);
}

.d-side-stripes {
  --offset: 8px;
  --offset-negative: calc(-1 * var(--offset));
  position: absolute;
  top: 50%;
  pointer-events: none;
}
.d-side-stripes.--left {
  right: 100%;
  transform: translateY(-50%) translateX(var(--offset-negative));
}
.d-side-stripes.--right {
  left: 100%;
  transform: translateY(-50%) translateX(var(--offset)) scale(-1);
}

.f-checkbox__visual {
  width: 16px;
  height: 16px;
  border: 1px solid #292934;
  background-color: #fff;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}
.f-checkbox__visual svg {
  opacity: 0;
  transform: scale(0.2);
  transition: opacity 0.3s ease;
  transition-property: opacity, transform;
}

.f-checkbox__input {
  display: none;
}
.f-checkbox__input:checked ~ .f-checkbox__visual {
  background-color: #292934;
}
.f-checkbox__input:checked ~ .f-checkbox__visual svg {
  opacity: 1;
  transform: scale(1);
}

.f-checkbox {
  display: inline-flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
}

.f-checkbox__title a {
  color: #292934;
  font-weight: 700;
}

.f-error {
  --display: none;
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  color: #f93a3a;
  width: 100%;
  display: var(--display);
  z-index: 2;
  font-size: 12px;
  gap: 4px;
}
.f-error.--show {
  --display: flex;
}

.f-base {
  --font-size: 14px;
  display: block;
  width: 100%;
  color: #292934;
  background-color: #fff;
  background-clip: padding-box;
  padding: 27px 16px;
  padding-bottom: 8px;
  border-radius: 0;
  font-size: var(--font-size);
  appearance: none;
  border: none;
}
.f-base[type=search]::-webkit-search-cancel-button {
  appearance: none;
  height: 10px;
  width: 10px;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE2LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgd2lkdGg9IjEyMy4wNXB4IiBoZWlnaHQ9IjEyMy4wNXB4IiB2aWV3Qm94PSIwIDAgMTIzLjA1IDEyMy4wNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTIzLjA1IDEyMy4wNTsiDQoJIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPHBhdGggZD0iTTEyMS4zMjUsMTAuOTI1bC04LjUtOC4zOTljLTIuMy0yLjMtNi4xLTIuMy04LjUsMGwtNDIuNCw0Mi4zOTlMMTguNzI2LDEuNzI2Yy0yLjMwMS0yLjMwMS02LjEwMS0yLjMwMS04LjUsMGwtOC41LDguNQ0KCQljLTIuMzAxLDIuMy0yLjMwMSw2LjEsMCw4LjVsNDMuMSw0My4xbC00Mi4zLDQyLjVjLTIuMywyLjMtMi4zLDYuMSwwLDguNWw4LjUsOC41YzIuMywyLjMsNi4xLDIuMyw4LjUsMGw0Mi4zOTktNDIuNGw0Mi40LDQyLjQNCgkJYzIuMywyLjMsNi4xLDIuMyw4LjUsMGw4LjUtOC41YzIuMy0yLjMsMi4zLTYuMSwwLTguNWwtNDIuNS00Mi40bDQyLjQtNDIuMzk5QzEyMy42MjUsMTcuMTI1LDEyMy42MjUsMTMuMzI1LDEyMS4zMjUsMTAuOTI1eiIvPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=");
  background-size: 10px 10px;
  cursor: pointer;
  right: -16px;
  position: relative;
}
@media (max-width: 575.98px) {
  .f-base {
    --font-size: 16px;
  }
}
.f-base:focus {
  color: #292934;
  border-color: none;
  outline: none;
}
.f-base::placeholder {
  opacity: 1;
  color: #292934;
  font-weight: 600;
}

textarea.f-base {
  min-height: 216px;
}

.f-input.f-header-search-bar__input {
  border-color: transparent;
  position: relative;
}
.f-input.f-header-search-bar__input::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease;
  transition-property: opacity, box-shadow;
  box-shadow: 0 0 10px 24px #fff;
  pointer-events: none;
}
.f-input.f-header-search-bar__input:active::after {
  box-shadow: 0 0 0 0 #fff;
  position: absolute;
  opacity: 1;
  transition: 0s;
}
.f-input.f-header-search-bar__input .f-input__input.f-base {
  min-height: 48px;
  background-color: #ececec;
  padding-bottom: 16px;
  padding-top: 16px;
  padding-right: 72px;
}
@media (max-width: 575.98px) {
  .f-input.f-header-search-bar__input .f-input__input.f-base::placeholder {
    font-size: 12px;
  }
}

button.f-input__icon {
  transition: background-color 0.3s ease;
}
button.f-input__icon:hover {
  background-color: #a9a9ae;
}

.f-header-search-bar__button {
  position: absolute;
  background-color: transparent;
  top: 0;
  right: 0;
  height: 100%;
  width: 56px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.f-header-search-bar__button::before {
  content: "";
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 46px;
  height: 46px;
  background-color: #a9a9ae;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 50%;
  z-index: -1;
}
.f-header-search-bar__button:hover::before {
  opacity: 1;
}

span.f-input__icon {
  pointer-events: none;
}

.f-input__icon {
  padding: 16px;
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
}
.f-input__icon.--before {
  padding-left: 16px;
  left: 0;
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
.f-input__icon.--after {
  padding-right: 16px;
  right: 0;
  top: 50%;
  transform: translateX(0) translateY(-50%);
}

.f-input__input {
  font-weight: 700;
  transition: border-color 0.3s ease;
  transition-property: background-color, border-color;
}

.f-input {
  border: 1px solid #ececec;
  border-radius: 0;
  display: flex;
  align-items: center;
  position: relative;
  transition: border-color 0.3s ease;
}
.f-input:hover .f-input__input {
  background-color: #fafafa;
}
.f-input.--invalid {
  border-color: #f93a3a;
}
.f-input.--active .f-input__input {
  background-color: #fafafa;
}
.f-input.--icon-after .f-input__input {
  padding-right: 48px;
}

.f-label {
  font-size: 14px;
  font-weight: 700;
  color: #292934;
  margin-bottom: 0;
  padding: 0 0;
  display: flex;
  align-items: center;
  position: absolute;
  top: 20px;
  left: 18px;
  z-index: 1;
  pointer-events: none;
  transition: transform 0.3s ease;
  will-change: transform;
  transform-origin: left;
}
.f-label.--required::after {
  content: "*";
  color: #f93a3a;
  padding-left: 4px;
}
.f-label.--active {
  transform: translateY(-16px) scale(0.9);
}

.f-radio-box__image {
  width: 72px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 575.98px) {
  .f-radio-box__image {
    width: 40px;
    height: 24px;
  }
}

.f-radio-box__visual {
  border: 1px solid #292934;
  width: 16px;
  height: 16px;
  position: relative;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.f-radio-box__visual::before {
  content: "";
  display: block;
  left: 50%;
  top: 50%;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #292934;
  opacity: 0;
  transform: scale(0.5);
  transition: opacity 0.3s ease;
  transition-property: opacity, transform;
}

.f-radio-box {
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.f-radio-box__body {
  background-color: #fff;
}
.f-radio-box__body::before {
  content: "";
  position: absolute;
  display: block;
  height: 100%;
  width: 3px;
  left: -8px;
  top: 0;
  background-color: var(--c-primary);
  transform: scaleY(0);
  transform-origin: center center;
  transition: opacity 0.3s ease;
  transition-property: opacity, transform;
}
.f-radio-box__body:hover {
  background-color: #fafafa;
}
@media (min-width: 992px) {
  .f-radio-box__body {
    padding-right: 16px;
  }
}
@media (max-width: 575.98px) {
  .f-radio-box__body {
    flex-wrap: wrap;
    row-gap: 8px;
  }
}

.f-radio-box__input {
  display: none;
}
.f-radio-box__input:checked ~ .f-radio-box__body::before {
  transform: scaleY(1);
}
.f-radio-box__input:checked ~ .f-radio-box__body .f-radio-box__visual::before {
  opacity: 1;
  transform: scale(1);
}

.f-radio-box__description a {
  color: #292934;
  font-weight: 700;
}
@media (max-width: 575.98px) {
  .f-radio-box__description {
    font-size: 12px;
  }
}

@media (max-width: 575.98px) {
  .f-radio-box__title {
    font-size: 14px;
  }
}

@media (max-width: 575.98px) {
  .f-radio-box__content-col {
    flex-grow: 1;
    order: 3;
    width: 100%;
  }
}

.f-textarea__input::-webkit-resizer {
  display: none;
}

.f-textarea {
  border: 1px solid #ececec;
  border-radius: 0;
  display: flex;
  align-items: center;
  position: relative;
}
.f-textarea.--no-resize .f-textarea__input {
  resize: none;
}
.f-textarea.--invalid {
  border-color: #f93a3a;
}

.f-textarea__resize {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 26px;
  height: 24px;
  pointer-events: none;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #fff;
}
.f-textarea__resize svg {
  width: 18px;
  height: 16px;
}

.f-contact {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  box-shadow: inset 0 0 0 1px #ececec;
  border-radius: 4px;
}

.f-contact__wrapper {
  display: grid;
  gap: 32px;
}
@media (min-width: 768px) {
  .f-contact__wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

.f-contact__bottom {
  display: flex;
  justify-content: space-between;
  gap: 32px;
}
@media (max-width: 575.98px) {
  .f-contact__bottom {
    flex-direction: column;
    align-items: flex-end;
  }
}

@media (min-width: 576px) {
  .f-discount__button {
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
  }
}
@media (max-width: 575.98px) {
  .f-discount__button {
    position: relative;
  }
  .f-discount__button.--size-sm .btn__body {
    min-height: 64px;
  }
}

.f-discount__input-wrap {
  flex-grow: 1;
  max-width: 568px;
  width: 100%;
}
.f-discount__input-wrap label {
  top: 24px;
}
.f-discount__input-wrap input {
  padding: 35px 16px;
  padding-bottom: 8px;
}
@media (max-width: 575.98px) {
  .f-discount__input-wrap {
    flex-direction: column;
    display: flex;
    gap: 8px;
  }
}

.f-discount__stripe {
  margin-right: -8px;
}

.f-discount {
  flex-grow: 1;
}
@media (max-width: 991.98px) {
  .f-discount {
    flex-direction: column;
    row-gap: 16px;
  }
}

.f-question__grid {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  column-gap: 32px;
}
@media (min-width: 992px) {
  .f-question__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.f-question__cell {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

@media (min-width: 992px) {
  .f-question__button {
    max-width: 50%;
    margin-left: auto;
    padding-left: 16px;
  }
}

.f-recommend__grid {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  column-gap: 32px;
}
@media (min-width: 992px) {
  .f-recommend__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.f-recommend__cell {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

@media (min-width: 992px) {
  .f-recommend__button {
    max-width: 50%;
    margin-left: auto;
    padding-left: 16px;
  }
}

.f-register__description p {
  margin-bottom: 0;
}

.g-base {
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);
  align-items: center;
}
.g-base.--no-wrap {
  flex-wrap: nowrap;
}
.g-base.--direction-column {
  flex-direction: column;
  align-items: initial;
}
.g-base.--gap-xs {
  --gap: 4px;
}
.g-base.--gap-sm {
  --gap: 8px;
}
.g-base.--gap-md {
  --gap: 16px;
}
.g-base.--gap-lg {
  --gap: 24px;
}
.g-base.--gap-xl {
  --gap: 32px;
}
.g-base.--align-left {
  justify-content: flex-start;
}
.g-base.--align-right {
  justify-content: flex-end;
}
.g-base.--align-center {
  justify-content: center;
}
.g-base.--align-between {
  justify-content: space-between;
}

.g-branch-modal__header {
  display: grid;
  grid-template-columns: 360px 248px 1fr 1fr;
  gap: 16px;
}
@media (max-width: 991.98px) {
  .g-branch-modal__header {
    display: none;
  }
}
.g-branch-modal__header::after {
  content: "";
  position: absolute;
  display: block;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: calc(100% + 48px);
  height: 1px;
  background-color: #ececec;
}

.g-breadcrumbs__list {
  display: flex;
  gap: 24px;
  align-items: center;
}
@media (max-width: 767.98px) {
  .g-breadcrumbs__list {
    display: none;
  }
}

.g-buttons {
  margin-top: 48px;
}

.g-cart-steps {
  justify-content: space-between;
  align-items: center;
  gap: 24px;
}
@media (max-width: 991.98px) {
  .g-cart-steps {
    gap: 8px;
  }
}
@media (max-width: 575.98px) {
  .g-cart-steps {
    justify-content: center;
  }
}

.g-cart-steps__divider {
  background-color: #ececec;
  width: 100%;
  flex-grow: 1;
  height: 1px;
}
@media (max-width: 1199.98px) {
  .g-cart-steps__divider {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .g-company-fieldset__row {
    --bs-gutter-y: 24px;
  }
}

.g-contact-group {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 767.98px) {
  .g-contact-group {
    grid-template-columns: 1fr;
  }
}

.g-contact-group__left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-right: 2rem;
  gap: 32px;
}
@media (max-width: 767.98px) {
  .g-contact-group__left {
    gap: 16px;
  }
}

.g-contact-group__socials {
  display: flex;
  gap: 32px;
}

.g-contact-group__right {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
}

@media (max-width: 575.98px) {
  .g-different-address-fieldset__row {
    --bs-gutter-y: 24px;
  }
}

.g-filters {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
}
@media (max-width: 991.98px) {
  .g-filters {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 575.98px) {
  .g-filters {
    display: flex;
    flex-direction: column;
  }
}

.g-gifts {
  display: grid;
  gap: 8px;
}
@media (min-width: 768px) {
  .g-gifts {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1200px) {
  .g-gifts {
    grid-template-columns: repeat(3, 1fr);
  }
}

.g-grader {
  --swiper-scrollbar-drag-bg-color: #292934;
  --swiper-scrollbar-size: 8px;
  --swiper-scrollbar-border-radius: 0;
  --swiper-scrollbar-bottom: -40px;
  display: none;
  width: 100%;
  grid-template-columns: 100%;
  grid-template-columns: minmax(0, 3fr) minmax(0, 9fr);
}
@media (max-width: 575.98px) {
  .g-grader {
    display: none;
  }
}
.g-grader.--active {
  display: grid;
}
@media (max-width: 575.98px) {
  .g-grader.--active {
    display: block;
  }
}

.g-grader__label {
  border-top: 1px solid #ececec;
}
.g-grader__label:first-of-type {
  border-top: none;
}

.g-grader__header {
  border-right: none;
}
@media (max-width: 575.98px) {
  .g-grader__header {
    display: none;
  }
}

.g-grader-slider {
  --swiper-scrollbar-drag-bg-color: #292934;
  --swiper-scrollbar-size: 8px;
  --swiper-scrollbar-border-radius: 0;
  --swiper-scrollbar-bottom: -40px;
  position: relative;
}
.g-grader-slider .swiper-wrapper {
  display: flex;
}
.g-grader-slider .swiper-slide {
  flex-shrink: 0;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  height: auto;
}

.g-grader-slider__progress.swiper-progress {
  position: relative;
  height: 8px;
  background-color: #a9a9ae;
}

.g-grader-slider__scrollbar {
  cursor: grab;
  transform-origin: bottom;
  transform: scaleY(0.5);
}
.g-grader-slider__scrollbar.swiper-scrollbar.swiper-scrollbar-horizontal {
  height: 8px;
  background-color: #a9a9ae;
  width: 100%;
  left: 0;
}
.g-grader-slider__scrollbar:hover, .g-grader-slider__scrollbar:focus, .g-grader-slider__scrollbar:active {
  transform: scaleY(1);
}
.g-grader-slider__scrollbar:active, .g-grader-slider__scrollbar:focus {
  cursor: grabbing;
}

.g-grader-slider__buttons {
  --buttons-offset: -32px;
}
@media (max-width: 575.98px) {
  .g-grader-slider__buttons {
    --buttons-offset: -16px;
  }
}
@media (max-width: 359px) {
  .g-grader-slider__buttons {
    --buttons-offset: -8px;
  }
}
.g-grader-slider__buttons .swiper-button {
  position: absolute;
  top: 196px;
  transition: opacity 0.3s ease;
  z-index: 1;
  pointer-events: auto;
}
.g-grader-slider__buttons .swiper-button::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease;
  transition-property: opacity, box-shadow;
  box-shadow: 0 0 10px 40px #fff;
}
.g-grader-slider__buttons .swiper-button:active::after {
  box-shadow: 0 0 0 0 #fff;
  position: absolute;
  opacity: 1;
  transition: 0s;
}
.g-grader-slider__buttons .swiper-button-next {
  right: var(--buttons-offset);
}
.g-grader-slider__buttons .swiper-button-prev {
  left: var(--buttons-offset);
}
.g-grader-slider__buttons .swiper-button-disabled {
  opacity: 0;
  pointer-events: none;
}

.g-grader-tab {
  overflow-x: auto;
}
.g-grader-tab::after {
  content: "";
  position: absolute;
  display: block;
  height: 1px;
  background-color: #ececec;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: -1;
}

.g-hero-slider {
  --swiper-theme-color: #292934;
  overflow: hidden;
}
.g-hero-slider .swiper-fraction__current {
  color: #292934;
}
.g-hero-slider .swiper-wrapper {
  display: flex;
}
.g-hero-slider .swiper-slide {
  flex-shrink: 0;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  width: 100% !important;
}
.g-hero-slider .swiper-pagination-progressbar {
  position: relative;
  height: 8px;
  max-width: 128px;
  background-color: #a9a9ae;
}
.g-hero-slider .swiper-fraction {
  display: flex;
  gap: 4px;
  font-weight: bold;
  font-size: 16px;
  color: #a9a9ae;
}
.g-hero-slider .swiper-fraction__divider::before {
  content: "/";
}
.g-hero-slider .swiper-button {
  transition-property: opacity, box-shadow;
}
.g-hero-slider .swiper-button.swiper-button-disabled {
  box-shadow: none;
  opacity: 0.5;
  cursor: not-allowed;
}

.g-hero-slider__navigation {
  display: flex;
  gap: 16px;
  margin-top: 24px;
  align-items: center;
  justify-content: flex-end;
}
@media (max-width: 991.98px) {
  .g-hero-slider__navigation {
    justify-content: center;
  }
}

.g-hero-slider__buttons {
  display: flex;
  gap: 1px;
  padding: 1px;
}

@media (min-width: 992px) {
  .g-information {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.g-load-more {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 64px;
}
@media (min-width: 1200px) and (max-width: 1499.98px) {
  .g-load-more {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
  }
}
@media (max-width: 991.98px) {
  .g-load-more {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
  }
}

.g-load-more__cell:first-child {
  grid-column-start: 2;
}
@media (max-width: 575.98px) {
  .g-load-more__cell:first-child {
    width: 100%;
  }
}

.g-opening-hours__icon {
  padding-top: 4px;
}

.g-opening-hours__content {
  flex-wrap: wrap;
}

.g-orders {
  gap: 1px;
}

.g-overview {
  display: flex;
  gap: 2px;
}

@media (max-width: 575.98px) {
  .g-personal-data__row {
    --bs-gutter-y: 24px;
  }
}

.g-posts-grid {
  display: block;
}
.g-posts-grid .g-posts-grid__cell:nth-child(2) {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1px;
}
@media (max-width: 991.98px) {
  .g-posts-grid .g-posts-grid__cell:nth-child(2) {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 767.98px) {
  .g-posts-grid .g-posts-grid__cell:nth-child(2) {
    display: flex;
    flex-direction: column;
  }
}

.g-posts-list {
  gap: 1px;
}

.g-products {
  display: grid;
  gap: 1px;
  grid-template-columns: repeat(3, 1fr);
}
@media (min-width: 1200px) and (max-width: 1499.98px) {
  .g-products {
    grid-template-columns: repeat(2, 1fr);
    max-width: 688px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .g-products {
    grid-template-columns: repeat(3, 1fr);
    max-width: none;
  }
}
@media (max-width: 991.98px) {
  .g-products {
    grid-template-columns: repeat(2, 1fr);
    max-width: 688px;
  }
}
@media (max-width: 767.98px) {
  .g-products {
    grid-template-columns: 1fr;
    max-width: 344px;
    margin-inline: auto;
  }
}

.g-products-slider {
  overflow: hidden;
}
.g-products-slider.u-top-line {
  --position: -1px;
}
.g-products-slider .swiper-wrapper {
  display: flex;
}
.g-products-slider.swiper {
  overflow: initial;
  position: relative;
}
.g-products-slider .swiper-slide {
  flex-shrink: 0;
  height: auto;
}
.g-products-slider.swiper-initialized .swiper-slide {
  opacity: 0.8;
  transition: opacity 0.3s ease;
  transition-property: opacity, filter;
  user-select: none;
  pointer-events: none;
}
@media (min-width: 768px) {
  .g-products-slider.swiper-initialized .swiper-slide {
    filter: blur(4px);
  }
}
.g-products-slider.swiper-initialized .swiper-slide-visible {
  opacity: 1;
  filter: blur(0);
  pointer-events: auto;
  user-select: text;
}
@media (max-width: 767.98px) {
  .g-products-slider {
    max-width: 344px;
  }
}
.g-products-slider .swiper-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 0.3s ease;
  z-index: 1;
  pointer-events: auto;
}
.g-products-slider .swiper-button::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease;
  transition-property: opacity, box-shadow;
  box-shadow: 0 0 10px 40px #fff;
}
.g-products-slider .swiper-button:active::after {
  box-shadow: 0 0 0 0 #fff;
  position: absolute;
  opacity: 1;
  transition: 0s;
}
.g-products-slider .swiper-button-next {
  right: 0;
  margin-right: 16px;
}
.g-products-slider .swiper-button-prev {
  left: 0;
  margin-left: 16px;
}
.g-products-slider .swiper-button-disabled {
  opacity: 0;
  pointer-events: none;
}
.g-products-slider .swiper-buttons {
  pointer-events: none;
  position: absolute;
  width: 100vw;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  max-width: 1600px;
  margin-inline: auto;
  z-index: 1;
}

.g-radio-boxes {
  background-color: #fff;
  outline: 1px solid #ececec;
}

.g-rotate-slider {
  height: 100%;
  max-width: 670px;
  max-height: 670px;
  aspect-ratio: 1/1;
}

.g-rotate-slider__canvas {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  display: block;
}

.g-search-suggest {
  --opacity: 1;
  top: 100%;
  opacity: var(--opacity);
}

.g-selected-filters {
  flex-grow: 1;
}
@media (min-width: 576px) {
  .g-selected-filters {
    min-height: 24px;
  }
}

.g-set-products {
  display: flex;
  flex-direction: column;
  gap: 1px;
}
@media (min-width: 576px) {
  .g-set-products {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.g-sets {
  display: grid;
  gap: 1px;
  grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 767.98px) {
  .g-sets {
    display: flex;
    flex-direction: column;
    max-width: 517px;
    margin-inline: auto;
  }
}

.g-sets-slider {
  overflow: hidden;
}
.g-sets-slider.u-top-line {
  --position: -1px;
}
.g-sets-slider .swiper-wrapper {
  display: flex;
}
.g-sets-slider .swiper-slide {
  flex-shrink: 0;
  height: auto;
}
.g-sets-slider.swiper {
  overflow: initial;
  position: relative;
}
.g-sets-slider.swiper-initialized .swiper-slide {
  opacity: 0.8;
  transition: opacity 0.3s ease;
  transition-property: opacity, filter;
  user-select: none;
  pointer-events: none;
}
@media (min-width: 768px) {
  .g-sets-slider.swiper-initialized .swiper-slide {
    filter: blur(4px);
  }
}
.g-sets-slider.swiper-initialized .swiper-slide-visible {
  opacity: 1;
  filter: blur(0);
  pointer-events: auto;
  user-select: text;
}
@media (max-width: 767.98px) {
  .g-sets-slider {
    max-width: 517px;
  }
}
.g-sets-slider .swiper-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 0.3s ease;
  z-index: 1;
  pointer-events: auto;
}
.g-sets-slider .swiper-button::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease;
  transition-property: opacity, box-shadow;
  box-shadow: 0 0 10px 40px #fff;
}
.g-sets-slider .swiper-button:active::after {
  box-shadow: 0 0 0 0 #fff;
  position: absolute;
  opacity: 1;
  transition: 0s;
}
.g-sets-slider .swiper-button-next {
  right: 0;
  margin-right: 16px;
}
.g-sets-slider .swiper-button-prev {
  left: 0;
  margin-left: 16px;
}
.g-sets-slider .swiper-button-disabled {
  opacity: 0;
  pointer-events: none;
}
.g-sets-slider .swiper-buttons {
  pointer-events: none;
  position: absolute;
  width: 100vw;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  max-width: 1600px;
  margin-inline: auto;
  z-index: 1;
}

.g-showroom-gallery {
  display: grid;
  gap: 1px;
  grid-template-columns: repeat(2, 1fr);
}
@media (min-width: 768px) {
  .g-showroom-gallery {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 992px) {
  .g-showroom-gallery {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 1200px) {
  .g-showroom-gallery {
    grid-template-columns: repeat(6, 1fr);
  }
}

.g-usps {
  display: grid;
  gap: 1px;
  position: relative;
}
@media (min-width: 576px) {
  .g-usps {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 992px) {
  .g-usps {
    grid-template-columns: repeat(4, 1fr);
  }
}
.g-usps::before {
  content: "";
  position: absolute;
  display: block;
  width: 24px;
  height: 24px;
  border-left: 4px solid #ececec;
  border-top: 4px solid #ececec;
  top: 0;
  left: 0;
}

.g-usps__cell {
  box-shadow: 0 0 0 1px #ececec;
}

.icon {
  --size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 var(--size);
  max-width: var(--size);
  height: var(--size);
}
.icon svg {
  width: 100%;
  height: 100%;
}
.icon.--colored {
  color: var(--color);
}
.icon.--colored path {
  fill: var(--color);
}
.icon.--primary {
  --color: var(--c-primary);
}
.icon.--white {
  --color: #fff;
}
.icon.--black {
  --color: #292934;
}
.icon.--success {
  --color: #48a463;
}
.icon.--error {
  --color: #f93a3a;
}
.icon.--xl {
  --size: 40px;
}
.icon.--lg {
  --size: 32px;
}
.icon.--sm {
  --size: 16px;
}
.icon.--xs {
  --size: 9px;
}

.img {
  overflow: hidden;
  border-radius: 0;
}
.img.--ratio-4-3 {
  aspect-ratio: 4/3;
}
.img.--ratio-16-9 {
  aspect-ratio: 16/9;
}
.img.--ratio-3-2 {
  aspect-ratio: 3/2;
}
.img.--ratio-1-1 {
  aspect-ratio: 1/1;
}
.img.--ratio-23-17 {
  aspect-ratio: 23/17;
}
.img.--ratio-5-4 {
  aspect-ratio: 5/4;
}
.img img {
  border-radius: 0;
}
.img.--contain img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.img.--cover img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.img.--placeholder {
  position: relative;
}
.img.--placeholder::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: -1;
}

.i-aviability {
  --icon-size: 8px;
}
.i-aviability:not(.--default)::before {
  content: "";
  display: block;
  width: var(--icon-size);
  height: var(--icon-size);
  border-radius: 50%;
}
.i-aviability.--for-order::before {
  background-color: #a9a9ae;
}
.i-aviability.--aviable::before {
  background-color: #51c87c;
}
.i-aviability.--unaviable::before {
  background-color: #f93a3a;
}

.i-badge {
  --font-color: colors.$black;
  --padding-y: 2px;
  padding-block: var(--padding-y);
  color: var(--font-color);
  background-color: #ececec;
  min-height: 24px;
}
.i-badge.--gray {
  background-color: #fafafa;
  border: 1px solid #ececec;
}
.i-badge.--orange {
  background-color: rgba(255, 204, 185, 0.5019607843);
  border: 1px solid #ffccb9;
}
.i-badge.--black {
  background-color: #292934;
  color: #a9a9ae;
}
.i-badge.--black path {
  fill: #a9a9ae;
}

.i-branch-modal {
  display: grid;
  grid-template-columns: 360px 248px 1fr 1fr;
  gap: 16px;
}
@media (max-width: 991.98px) {
  .i-branch-modal {
    grid-template-columns: minmax(256px, 360px) minmax(168px, 248px) 1fr 1fr;
  }
}
@media (max-width: 767.98px) {
  .i-branch-modal {
    display: flex;
    flex-wrap: wrap;
  }
}

.i-branch-modal__title {
  max-width: 232px;
  width: 100%;
}

@media (max-width: 767.98px) {
  .i-branch-modal__content {
    flex-grow: 1;
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  .i-branch-modal__button-col {
    margin-left: auto;
  }
}

.i-branch-modal__tooltip-col {
  flex-shrink: 0;
}

.i-breadcrumb__link::after {
  content: "";
  position: absolute;
  display: block;
  right: 0;
  top: 50%;
  transform: translateY(-50%) translateX(12px);
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: #292934;
  pointer-events: none;
}

.i-breadcrumb__current {
  max-width: 60ch;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.i-cart {
  --padding-x: 32px;
  display: grid;
  gap: 24px;
  grid-template-columns: 88px 252px 1fr 1fr 40px 1fr 46px;
  grid-template-areas: "image content aviability counter discount price action";
  align-items: center;
  background-color: #fff;
  padding-left: var(--padding-x);
  padding-right: var(--padding-x);
}
.i-cart .c-counter {
  --width: 128px;
}
@media (max-width: 575.98px) {
  .i-cart {
    --padding-x: 16px;
  }
}
@media (max-width: 991.98px) {
  .i-cart {
    grid-template-columns: 64px 1fr 1fr 46px;
    gap: 16px;
    grid-template-areas: "image content content content" "aviability aviability discount discount" "counter counter price action";
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .i-cart {
    gap: 16px;
  }
}

@media (max-width: 575.98px) {
  .i-cart__title {
    font-size: 12px;
  }
}

.i-cart__title-link {
  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 575.98px) {
  .i-cart__title-link {
    -webkit-line-clamp: 4;
  }
}

.i-cart__image {
  grid-area: image;
  height: 88px;
}
@media (max-width: 991.98px) {
  .i-cart__image {
    height: 64px;
  }
}

.i-cart__content {
  grid-area: content;
  max-width: 252px;
}
@media (max-width: 991.98px) {
  .i-cart__content {
    align-self: start;
    margin-top: 8px;
    max-width: 100%;
  }
}

.i-cart__aviability {
  grid-area: aviability;
}
@media (min-width: 992px) {
  .i-cart__aviability {
    margin-inline: auto;
  }
}

.i-cart__counter {
  grid-area: counter;
}

.i-cart__price {
  grid-area: price;
}

.i-cart__discount {
  grid-area: discount;
  justify-self: end;
}

.i-cart__action {
  grid-area: action;
  justify-self: end;
}

a.i-cart-step {
  text-decoration-color: transparent;
}
a.i-cart-step:hover {
  color: #292934;
  text-decoration-color: #292934;
}

.i-cart-step {
  align-items: center;
  color: #292934;
  font-family: "Noto Serif", serif;
  font-size: 20px;
}
.i-cart-step::before {
  content: attr(data-step);
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  min-height: 40px;
  background-color: #292934;
  color: #fff;
  border-radius: 20px;
}
.i-cart-step.--disabled::before {
  background-color: #fafafa;
  color: #292934;
  outline: 1px solid #ececec;
}
@media (max-width: 575.98px) {
  .i-cart-step:not(.--active) {
    display: none;
  }
}
@media (min-width: 1200px) {
  .i-cart-step {
    flex-shrink: 0;
  }
}
@media (min-width: 576px) and (max-width: 991.98px) {
  .i-cart-step {
    font-size: 16px;
  }
  .i-cart-step::before {
    min-width: 32px;
    min-height: 32px;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .i-cart-step {
    font-size: 12px;
    gap: 4px;
  }
  .i-cart-step::before {
    min-width: 24px;
    min-height: 24px;
  }
}

.i-contact__body svg {
  margin-top: 5px;
}

.i-content-toggle__header {
  --font-family: Noto Serif, serif;
  font-family: var(--font-family);
  cursor: pointer;
  user-select: none;
}

.i-content-toggle__content {
  display: none;
}

.i-content-toggle__check-visual {
  width: 16px;
  height: 16px;
  border: 1px solid #292934;
  background-color: #fff;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}
.i-content-toggle__check-visual svg {
  opacity: 0;
  transform: scale(0.2);
  transition: opacity 0.3s ease;
  transition-property: opacity, transform;
}

.i-content-toggle.--active .i-content-toggle__content {
  display: block;
}
.i-content-toggle.--active .i-content-toggle__check-visual {
  background-color: #292934;
}
.i-content-toggle.--active .i-content-toggle__check-visual svg {
  opacity: 1;
  transform: scale(1);
}

.i-deliverity.u-gray-box {
  --box-size: 16px;
}
@media (min-width: 1200px) and (max-width: 1499.98px) {
  .i-deliverity {
    padding: 8px;
    gap: 8px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .i-deliverity {
    padding: 8px;
    gap: 8px;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .i-deliverity {
    padding: 8px;
    gap: 8px;
  }
}
@media (max-width: 575.98px) {
  .i-deliverity {
    display: flex;
    width: 100%;
    justify-content: center;
  }
}
.i-deliverity.--hidden {
  visibility: hidden;
}
@media (max-width: 575.98px) {
  .i-deliverity.--hidden {
    display: none;
  }
}

@media (min-width: 1200px) and (max-width: 1499.98px) {
  .i-deliverity__icon {
    margin-left: 0;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .i-deliverity__icon {
    margin-left: 0;
  }
}

.i-deliverity__content {
  gap: 4px;
}

.i-deliverity__icon-decoration {
  right: 100%;
  top: 50%;
  transform: translateY(-50%) translateX(-8px);
  pointer-events: none;
}
@media (min-width: 1200px) and (max-width: 1499.98px) {
  .i-deliverity__icon-decoration {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .i-deliverity__icon-decoration {
    display: none;
  }
}

.i-deliverity__tooltip {
  z-index: 1;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.i-deliverity__tooltip::before {
  content: "";
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  height: 100%;
  background-color: #ececec;
  pointer-events: none;
  border-radius: 50%;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.i-deliverity__tooltip:hover::before {
  opacity: 1;
}

.i-delivery-provider__logo {
  flex-basis: 40px;
  max-width: 40px;
}

.i-discount__text {
  font-size: 10px;
}

.i-discount {
  --size: 64px;
  aspect-ratio: 1/1;
  background-color: #ffccb9;
  min-width: var(--size);
  min-height: var(--size);
  border-radius: 50%;
  font-weight: 800;
}
.i-discount.--compact {
  --size: 40px;
  display: inline-flex;
}
.i-discount.--compact .i-discount__value {
  font-size: 14px;
}
.i-discount.--compact .i-discount__text {
  display: none;
}

.i-dropdown-product__title {
  max-width: 208px;
}

.i-dropdown-product__action {
  min-height: 48px;
  min-width: 48px;
  cursor: pointer;
}
.i-dropdown-product__action .icon {
  z-index: 1;
}
.i-dropdown-product__action::after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #ececec;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.i-dropdown-product__action:hover::after {
  opacity: 1;
}

.i-dropdown-product__image {
  --size: 32px;
  display: block;
  flex: 0 0 var(--size);
  max-width: var(--size);
  height: var(--size);
}

.i-gift__body {
  --visual-opacity: 0;
  --visual-opacity-checked: 1;
  --visual-scale: 0.5;
  --visual-scale-checked: 1;
  --visual-before-scale: 0 1;
  --visual-before-scale-checked: 1 1;
}
.i-gift__body::before {
  content: "";
  position: absolute;
  display: block;
  height: calc(100% - 8px);
  width: 3px;
  background-color: #0066b3;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  scale: var(--visual-before-scale);
  transition: scale 0.3s ease;
  transform-origin: center;
}

.i-gift__input {
  pointer-events: none;
  left: 0;
  bottom: 0;
}
.i-gift__input:checked ~ .i-gift__body {
  --visual-opacity: var(--visual-opacity-checked);
  --visual-scale: var(--visual-scale-checked);
  --visual-before-scale: var(--visual-before-scale-checked);
}

.i-gift__visual {
  border: 1px solid #292934;
  width: 16px;
  height: 16px;
  border-radius: 8px;
}
.i-gift__visual::before {
  content: "";
  display: block;
  left: 50%;
  top: 50%;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #292934;
  opacity: var(--visual-opacity);
  scale: var(--visual-scale);
  transition: opacity 0.3s ease;
  transition-property: opacity, scale;
}

.i-gift__description span {
  color: #f93a3a;
  font-weight: 700;
}

.i-gift__image-col {
  --size: 56px;
  max-width: var(--size);
  flex: 0 0 var(--size);
  height: var(--size);
}

.i-gift {
  border: 1px solid #ececec;
  transition: background-color 0.3s ease;
  transition-property: background-color, opacity;
}
.i-gift:hover:not(.--disabled) {
  background-color: #fafafa;
}
.i-gift.--disabled {
  opacity: 0.5;
}

.i-grader-slide {
  border: 1px solid #ececec;
  height: 100%;
}

.i-grader-slide__parameters {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.i-grader-slide__param {
  border-top: 1px solid #ececec;
}
.i-grader-slide__param:first-of-type {
  border-top: none;
}

.i-grader-slide__title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.i-grader-tab {
  background-color: #fafafa;
  min-height: 64px;
  cursor: pointer;
  border: 1px solid #ececec;
  max-width: 25%;
  flex: 0 0 25%;
}
@media (max-width: 1199.98px) {
  .i-grader-tab {
    max-width: 33%;
    flex: 0 0 33%;
  }
}
@media (max-width: 991.98px) {
  .i-grader-tab {
    flex: 0 0 auto;
    max-width: none;
    flex-shrink: 0;
  }
}
.i-grader-tab.--active {
  background-color: #fff;
  border-bottom-color: #fff;
}
.i-grader-tab::after {
  content: "";
  position: absolute;
  display: block;
  left: 100%;
  width: 17px;
  height: 1px;
  background-color: #ececec;
  bottom: -1px;
}
.i-grader-tab:last-of-type::after {
  display: none;
}

.i-grader-tab__count {
  background-color: transparent;
  border: 1px solid #ececec;
  min-height: 24px;
  padding-inline: 8px;
}
.i-grader-tab__count svg {
  width: 8px;
  height: 8px;
}

.i-header-dropdown__list {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 5;
  min-width: 480px;
  max-width: 100%;
  pointer-events: none;
  opacity: 0;
  transform: translateY(16px);
  transition: opacity 0.3s ease;
  transition-property: opacity, transform;
  cursor: auto;
}
@media (max-width: 767.98px) {
  .i-header-dropdown__list {
    display: none;
  }
}

.i-header-dropdown {
  --size: 56px;
  min-width: var(--size);
  min-height: var(--size);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  color: #292934;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .i-header-dropdown {
    --size: 48px;
  }
}
@media (max-width: 575.98px) {
  .i-header-dropdown {
    --size: 48px;
  }
}
.i-header-dropdown::before {
  content: "";
  position: absolute;
  display: block;
  width: 46px;
  height: 46px;
  background-color: #ececec;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}
.i-header-dropdown::after {
  content: "";
  position: absolute;
  display: block;
  width: 8px;
  height: 8px;
  background-color: var(--c-primary);
  border-radius: 4px;
  top: 8px;
  right: 8px;
  opacity: 0;
  transform: scale(0);
  transition: opacity 0.3s ease;
  transition-property: opacity, transform;
  pointer-events: none;
}
.i-header-dropdown:hover::before, .i-header-dropdown.--open::before {
  opacity: 1;
}
.i-header-dropdown:hover .i-header-dropdown__list, .i-header-dropdown.--open .i-header-dropdown__list {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
}
.i-header-dropdown.--fill::after {
  opacity: 1;
  transform: scale(1);
}

.i-header-dropdown__link {
  display: flex;
  z-index: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
@media (max-width: 575.98px) {
  .i-header-dropdown__link .icon {
    padding: 2px;
  }
}

/* stylelint-disable max-line-length */
/* stylelint-enable max-line-length */
.i-hero {
  --content-max-width: 380px;
  --font-color: #fff;
  color: var(--font-color);
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  align-items: center;
}
@media (max-width: 1499.98px) {
  .i-hero {
    --content-max-width: 320px;
  }
}
@media (max-width: 991.98px) {
  .i-hero {
    --content-max-width: 100%;
    display: block;
    max-width: 536px;
    margin-inline: auto;
  }
}

.i-hero__body {
  padding: 56px 48px;
  display: flex;
  justify-content: center;
  grid-column: 1/6;
  grid-row: 1/2;
  margin-right: 8px;
  width: 100%;
}
@media (min-width: 992px) {
  .i-hero__body {
    min-height: 312px;
  }
}
@media (max-width: 575.98px) {
  .i-hero__body {
    padding: 32px;
  }
}
@media (max-width: 359px) {
  .i-hero__body {
    padding-right: 16px;
    padding-left: 16px;
  }
}

.i-hero__description {
  padding-left: 16px;
  max-width: var(--content-max-width);
}
@media (max-width: 575.98px) {
  .i-hero__description {
    font-size: 14px;
  }
}
.i-hero__description::before {
  content: "";
  position: absolute;
  display: block;
  width: 2px;
  height: 100%;
  background-color: var(--font-color);
  top: 0;
  left: 0;
}

.i-hero__image {
  order: 2;
  grid-column: 5/-1;
  grid-row: 1/2;
  max-width: 536px;
}
@media (min-width: 1200px) and (max-width: 1499.98px) {
  .i-hero__image {
    padding-left: 32px;
  }
}
@media (min-width: 992px) {
  .i-hero__image {
    margin-inline-start: auto;
  }
}

.i-hero__heading {
  max-width: var(--content-max-width);
}

.i-hero__body-pattern {
  --bg-color: var(--c-primary);
  --pattern-color: var(--c-primary-500);
  overflow: hidden;
  background-color: var(--bg-color);
  z-index: -1;
}

/* stylelint-disable max-line-length */
/* stylelint-enable max-line-length */
.i-hero-slide {
  --content-max-width: 376px;
  --font-color: #fff;
  display: block;
  align-items: center;
}
@media (max-width: 1499.98px) {
  .i-hero-slide {
    --content-max-width: 320px;
  }
}
@media (max-width: 991.98px) {
  .i-hero-slide {
    --content-max-width: 100%;
  }
}
@media (min-width: 992px) {
  .i-hero-slide {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
  }
}

.i-hero-slide__body {
  color: var(--font-color);
  display: flex;
  flex-direction: column;
  padding: 72px 56px;
}
@media (min-width: 992px) {
  .i-hero-slide__body {
    min-height: 420px;
  }
}
@media (min-width: 992px) {
  .i-hero-slide__body {
    grid-column: 1/6;
    grid-row: 1/2;
  }
}
@media (max-width: 991.98px) {
  .i-hero-slide__body {
    max-width: 552px;
    margin-inline: auto;
    padding: 32px 16px;
  }
}

.i-hero-slide__button {
  margin-top: 56px;
}
@media (max-width: 575.98px) {
  .i-hero-slide__button {
    margin-top: 32px;
  }
}

.i-hero-slide__description {
  padding-left: 16px;
  position: relative;
  max-width: var(--content-max-width);
}
@media (max-width: 575.98px) {
  .i-hero-slide__description {
    font-size: 14px;
  }
}
.i-hero-slide__description::before {
  content: "";
  position: absolute;
  display: block;
  width: 2px;
  height: 100%;
  background-color: var(--font-color);
  top: 0;
  left: 0;
}

.i-hero-slide__heading {
  color: var(--font-color);
  margin-bottom: 24px;
  max-width: var(--content-max-width);
}
@media (max-width: 575.98px) {
  .i-hero-slide__heading {
    font-size: 28px;
    margin-bottom: 16px;
  }
}

.i-hero-slide__image {
  z-index: 2;
  max-width: 552px;
  margin-inline-start: auto;
}
.i-hero-slide__image img {
  transform: scale(1);
  transition-property: opacity, transform;
}
.i-hero-slide__image img[data-src]:not(.loaded) {
  opacity: 0;
  transform: scale(0.95);
}
@media (min-width: 992px) {
  .i-hero-slide__image {
    grid-column: 5/-1;
    grid-row: 1/2;
    padding-left: 16px;
  }
}
@media (max-width: 991.98px) {
  .i-hero-slide__image {
    margin-inline: auto;
  }
}

.i-hero-slider__category {
  text-transform: uppercase;
  max-width: var(--content-max-width);
}

.i-hero-slide__body-pattern {
  --bg-color: var(--c-primary);
  --pattern-color: var(--c-primary-500);
  overflow: hidden;
  background-color: var(--bg-color);
  z-index: -1;
}

.i-image-box.u-gray-box {
  --background: #fff;
}

@media (min-width: 576px) {
  .i-information__label {
    flex-shrink: 0;
    width: min(100%, 112px);
  }
}

.i-information__item {
  row-gap: 8px;
}
@media (max-width: 575.98px) {
  .i-information__item {
    flex-direction: column;
  }
}

.i-information__content {
  max-width: 80ch;
}

.i-order {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 48px;
  grid-template-areas: "id date price status action";
  gap: 16px;
  outline: 1px solid #ececec;
}
@media (max-width: 767.98px) {
  .i-order {
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas: "id id date date" "price price status status" "action action action action";
  }
}

.i-order__action {
  grid-area: action;
}

.i-order__date {
  grid-area: date;
}
@media (max-width: 767.98px) {
  .i-order__date {
    justify-content: flex-end;
    display: flex;
  }
}

.i-order__id {
  grid-area: id;
}

.i-order__price {
  grid-area: price;
}

.i-order__status {
  grid-area: status;
}
@media (max-width: 767.98px) {
  .i-order__status {
    justify-content: flex-end;
  }
}

.i-overview__image {
  max-width: 40px;
  flex: 0 0 40px;
  height: 24px;
}

.i-overview::after, .i-overview::before {
  content: "";
  position: absolute;
  display: block;
  height: 1px;
  width: calc(100% + 32px);
  background-color: #ececec;
  left: 50%;
  transform: translateX(-50%);
}
.i-overview::before {
  top: 0;
}
.i-overview::after {
  bottom: 0;
  background-color: transparent;
}
.i-overview:last-of-type::after {
  background-color: #ececec;
}
.i-overview.--default {
  flex-wrap: wrap;
  align-items: center;
}
.i-overview.--default .i-overview__title {
  font-size: 12px;
}
.i-overview.--default .i-overview__label {
  width: 100%;
  flex-grow: 1;
}
.i-overview.--compact {
  justify-content: space-between;
}
.i-overview.--compact .i-overview__title {
  font-size: 14px;
}
.i-overview.--hidden {
  display: none;
}

.i-person-inline__image {
  height: 64px;
  max-width: 64px;
  flex: 0 0 64px;
  padding: 4px;
  outline: 1px solid #292934;
}

.i-person-inline__body {
  gap: 4px;
  height: 100%;
}

.i-person__image {
  width: 104px;
  outline: 1px solid #fff;
}

.i-person__caption {
  color: #a9a9ae;
}

.i-person .d-side-stripes {
  --offset: 24px;
}
.i-person svg {
  width: 40px;
  height: 35px;
}
.i-person path {
  fill: #fff;
}

.i-post.--column {
  display: flex;
  flex-direction: column-reverse;
  padding: 32px;
  justify-content: space-between;
  outline: 1px solid #ececec;
  position: relative;
  overflow: hidden;
  gap: 16px;
}
@media (hover: hover) {
  .i-post.--column:hover .i-post__button {
    opacity: 1;
    transform: translateX(0);
  }
}
.i-post.--column .i-post__button {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 16px;
  overflow: hidden;
  transition: opacity 0.3s ease;
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
@media (hover: hover) {
  .i-post.--column .i-post__button {
    opacity: 0;
    transform: translateX(32px);
  }
}
.i-post.--column .i-post__button::after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #fff;
  top: 0;
  left: 0;
  opacity: 0.8;
}
.i-post.--column .i-post__perex {
  -webkit-line-clamp: 4;
  line-height: 24px;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .i-post.--column {
    padding: 16px;
  }
}
@media (max-width: 767.98px) {
  .i-post.--column {
    max-width: 353px;
    margin-inline: auto;
  }
}

.i-post.--default {
  outline: 1px solid #ececec;
  padding: 32px;
}
.i-post.--default .i-post__content {
  max-width: 70ch;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .i-post.--default {
    padding: 16px;
  }
  .i-post.--default .i-post__perex {
    -webkit-line-clamp: 3;
  }
}
@media (max-width: 1199.98px) {
  .i-post.--default .i-post__heading-link {
    display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media (max-width: 767.98px) {
  .i-post.--default {
    display: flex;
    flex-direction: column;
    max-width: 353px;
    margin-inline: auto;
    gap: 16px;
    padding: 16px;
  }
}
@media (max-width: 767.98px) {
  .i-post.--default .i-post__button {
    justify-content: flex-end;
  }
}

.i-post.--hero {
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  position: relative;
}
.i-post.--hero::after, .i-post.--hero::before {
  content: "";
  position: absolute;
  display: block;
  width: 100vw;
  height: 1px;
  background-color: #ececec;
  left: 50%;
  transform: translateX(-50%);
}
.i-post.--hero::before {
  top: -1px;
}
.i-post.--hero::after {
  bottom: 0;
}
@media (max-width: 1199.98px) {
  .i-post.--hero {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 991.98px) {
  .i-post.--hero {
    display: flex;
    flex-direction: column;
    max-width: 656px;
    margin-inline: auto;
    padding-bottom: 16px;
  }
}
.i-post.--hero .i-post__content {
  justify-content: center;
  padding-block: 8px;
}
.i-post.--hero .i-post__button {
  justify-content: flex-start;
  margin-top: auto;
  margin-top: 40px;
}
@media (max-width: 1499.98px) {
  .i-post.--hero .i-post__button {
    margin-top: 8px;
  }
}
@media (max-width: 991.98px) {
  .i-post.--hero .i-post__button {
    justify-content: flex-end;
  }
}
.i-post.--hero .i-post__heading-link {
  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.i-post.--hero .i-post__heading {
  font-size: 32px;
}
@media (max-width: 1199.98px) {
  .i-post.--hero .i-post__heading {
    font-size: 28px;
  }
}
@media (max-width: 991.98px) {
  .i-post.--hero .i-post__heading {
    font-size: 24px;
  }
}
.i-post.--hero .i-post__image {
  max-width: 656px;
  margin-right: 32px;
}
@media (max-width: 1199.98px) {
  .i-post.--hero .i-post__image {
    margin-right: 16px;
  }
}
@media (max-width: 991.98px) {
  .i-post.--hero .i-post__image {
    margin-right: 0;
  }
}
.i-post.--hero .i-post__perex {
  font-size: 16px;
  -webkit-line-clamp: 7;
  line-height: 24px;
}
@media (max-width: 1499.98px) {
  .i-post.--hero .i-post__perex {
    -webkit-line-clamp: 5;
  }
}
@media (max-width: 1199.98px) {
  .i-post.--hero .i-post__perex {
    -webkit-line-clamp: 3;
    font-size: 14px;
  }
}

.i-post__image-col {
  grid-area: image;
}

.i-post__content-col {
  grid-area: content;
}

.i-post__heading {
  font-family: "Noto Serif", serif;
  font-size: 18px;
  font-weight: bold;
}

.i-post__perex {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.i-post__header {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.i-post__heading-link {
  text-decoration-color: transparent;
  color: #292934;
  display: block;
  overflow: hidden;
}
.i-post__heading-link:hover {
  color: #292934;
  text-decoration-color: #292934;
}

.i-post__footer {
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  margin-top: 8px;
}

.i-post__content {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  height: 100%;
}

.i-post-image-link {
  border-radius: 0;
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}
.i-post-image-link img {
  transform: scale(1);
  transition-property: opacity, transform;
}
.i-post-image-link img[data-src]:not(.loaded) {
  opacity: 0;
  transform: scale(0.95);
}

.i-post__date {
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-right: auto;
  font-size: 14px;
  font-weight: 800;
}

.i-post__button {
  display: flex;
}

.i-post {
  display: grid;
  column-gap: 16px;
  grid-template-columns: 288px auto;
  grid-template-areas: "image content";
  background-color: #fff;
}
@media (max-width: 767.98px) {
  .i-post {
    grid-template-columns: 192px auto;
  }
}
@media (max-width: 575.98px) {
  .i-post {
    display: flex;
    flex-direction: column;
    max-width: 336px;
    margin-inline: auto;
  }
}

.i-price__value {
  white-space: nowrap;
}

.i-price__base {
  font-size: 24px;
  color: #292934;
  display: flex;
  white-space: nowrap;
  gap: 8px;
}

.i-price__before {
  text-decoration: line-through;
  color: #9999a5;
  display: flex;
  gap: 4px;
  white-space: nowrap;
}

.i-price {
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: right;
  align-items: flex-end;
  justify-content: center;
}
.i-price.--size-xs .i-price__base {
  font-size: 14px;
  gap: 4px;
}
.i-price.--size-xs .i-price__before {
  font-size: 12px;
}
.i-price.--size-sm .i-price__base {
  font-size: 16px;
  gap: 4px;
}
@media (max-width: 575.98px) {
  .i-price.--size-sm .i-price__base {
    font-size: 14px;
  }
}
.i-price.--size-sm .i-price__before {
  font-size: 14px;
}
@media (max-width: 575.98px) {
  .i-price.--size-sm .i-price__before {
    font-size: 12px;
  }
}
.i-price.--size-md .i-price__base {
  font-size: 20px;
  gap: 4px;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .i-price.--size-md .i-price__base {
    font-size: 16px;
  }
}
@media (max-width: 575.98px) {
  .i-price.--size-md .i-price__base {
    font-size: 16px;
  }
}
.i-price.--align-center {
  align-items: center;
}

.i-product {
  box-shadow: 0 0 0 1px #ececec;
}

.i-product__title {
  font-family: "Noto Serif", serif;
}

.i-product__title-link {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.i-product__warranty {
  pointer-events: none;
}

.i-product-modal::after, .i-product-modal::before {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 48px);
  height: 1px;
  background-color: #ececec;
}
.i-product-modal::before {
  top: 0;
}
.i-product-modal::after {
  bottom: 0;
}

.i-product-modal__image {
  flex-basis: 56px;
}

.i-product-overview {
  display: grid;
  align-items: center;
  gap: 16px;
  grid-template-columns: 40px 208px auto 1fr;
  grid-template-areas: "image content count price";
}
@media (min-width: 992px) and (max-width: 1499.98px) {
  .i-product-overview {
    grid-template-columns: 40px 184px auto 1fr;
    grid-template-areas: "image content count price";
    gap: 8px;
  }
}
@media (max-width: 991.98px) {
  .i-product-overview {
    grid-template-columns: 40px 184px 1fr 1fr;
    grid-template-areas: "image content count price";
  }
}
@media (max-width: 575.98px) {
  .i-product-overview {
    row-gap: 8px;
    grid-template-columns: 40px 1fr 1fr;
    grid-template-areas: "image count price" "content content content";
  }
}

.i-product-overview__count {
  grid-area: count;
  white-space: nowrap;
  margin-left: auto;
}
@media (min-width: 992px) and (max-width: 1499.98px) {
  .i-product-overview__count {
    font-size: 12px;
  }
}
@media (max-width: 575.98px) {
  .i-product-overview__count {
    margin-left: 0;
  }
}

.i-product-overview__title {
  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.i-product-overview__content {
  grid-area: content;
}
@media (max-width: 575.98px) {
  .i-product-overview__content {
    order: 3;
  }
}

.i-product-overview__image {
  grid-area: image;
  outline: 1px solid #ececec;
}

.i-product-overview__price {
  grid-area: price;
}

.i-progress__icon {
  width: 32px;
}

.i-progress__input {
  appearance: none;
  border: none;
  border-radius: 4px;
  height: 8px;
  overflow: hidden;
  background-color: #ececec;
}
.i-progress__input::-webkit-progress-bar {
  background-color: #ececec;
}
.i-progress__input::-webkit-progress-value {
  background-color: #292934;
}
.i-progress__input::-moz-progress-bar {
  background-color: #292934;
}

.i-progress__icon-decoration {
  margin-right: -8px;
}

.i-progress__gratis-text {
  display: none;
  font-weight: 800;
}

.i-progress {
  max-width: 720px;
}
.i-progress strong {
  font-weight: 800;
}
.i-progress.--gratis .i-progress__gratis-text {
  display: block;
}
.i-progress.--gratis .i-progress__text {
  display: none;
}

.i-search-suggest__image {
  width: 32px;
  height: 32px;
}

.i-search-suggest {
  text-decoration: none;
}
.i-search-suggest:hover {
  background-color: #ececec;
  color: #292934;
}

.i-search-suggest__code {
  font-size: 10px;
}

.i-selected-filter {
  padding: 2px 8px;
  min-height: 24px;
  background-color: #fafafa;
  border: 1px solid #ececec;
  align-items: center;
  cursor: pointer;
}

.i-selected-filter__remove {
  --size: 8px;
}
.i-selected-filter__remove svg {
  width: var(--size);
  height: var(--size);
}

.i-set__heading {
  font-family: "Noto Serif", serif;
}
.i-set__heading .link__title {
  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.i-set {
  box-shadow: 0 0 0 1px #ececec;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .i-set__footer {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (max-width: 575.98px) {
  .i-set__footer {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .i-set__button-link {
    width: 100%;
  }
}

.i-set-product {
  outline: 1px solid #ececec;
  background-color: #fff;
}

.i-set-product__header {
  display: grid;
  grid-template-columns: 64px 1fr;
}

.i-set-product__title {
  font-family: "Noto Serif", serif;
}
.i-set-product__title .link__title {
  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.i-set-product__image {
  height: 64px;
}

.i-set-product__table {
  display: none;
}
.i-set-product__table.--active {
  display: block;
}
.i-set-product__table tbody {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #ececec;
}
.i-set-product__table tr {
  display: grid;
  grid-template-columns: 17fr 20fr;
  gap: 16px;
}

.i-set-product__parameters-heading {
  padding: 0 8px;
  cursor: pointer;
}

.i-set-product__parameters-header {
  justify-content: space-between;
  align-items: center;
  user-select: none;
}
.i-set-product__parameters-header svg {
  transition: scale 0.3s ease;
  will-change: scale;
}
.i-set-product__parameters-header.--active svg {
  scale: -1;
}
.i-set-product__parameters-header::after, .i-set-product__parameters-header::before {
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  background-color: #ececec;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .i-set-product__buttons {
    flex-direction: column;
  }
  .i-set-product__buttons .btn-compare {
    width: 100%;
    min-width: auto;
    max-width: 100%;
  }
}

.i-showroom-gallery {
  outline: 1px solid #ececec;
  cursor: pointer;
}
@media (max-width: 575.98px) {
  .i-showroom-gallery {
    padding: 8px;
  }
}

.i-specialist__header {
  max-width: 176px;
}

.i-specialist::after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 25px 25px 0 0;
  border-color: #34343e transparent transparent;
  top: 0;
  left: 0;
  transition: transform 0.3s ease;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  transform: translateX(-6px) rotate(-4deg);
}

.i-specialist__body {
  background-color: #34343e;
  color: #fff;
  clip-path: polygon(0 42px, 0 24px, 24px 0, 100% 0, 100% 100%, 0 100%, 0 48px, 24px 16px);
}

.i-status {
  --font-color: colors.$black;
  padding: 6px;
  background-color: #9eeaf9;
  color: var(--font-color);
  min-height: 32px;
}
.i-status.--success {
  --font-color: #fff;
  background-color: #51c87c;
}
.i-status.--error {
  --font-color: #fff;
  background-color: #f93a3a;
}
.i-status.--info {
  --font-color: #292934;
  background-color: #9eeaf9;
}
.i-status.--warning {
  --font-color: #292934;
  background-color: #ffc107;
}

.i-tag {
  --font-color: colors.$black;
  --padding-y: 2px;
  padding-block: var(--padding-y);
  color: var(--font-color);
  background-color: #ececec;
  min-height: 24px;
}
.i-tag path {
  fill: var(--font-color);
}
.i-tag.--blue {
  --font-color: #292934;
  background-color: #d0e1f9;
}
.i-tag.--orange {
  --font-color: #292934;
  background-color: #f9e6d9;
}
.i-tag.--primary {
  --font-color: #fff;
  background-color: var(--c-primary);
}
.i-tag.--black {
  --font-color: #fff;
  background-color: #292934;
}
.i-tag.--custom {
  background-color: var(--background);
}

a.i-tag {
  text-decoration-color: transparent;
}
a.i-tag:hover {
  color: var(--font-color);
  text-decoration-color: var(--font-color);
}

.i-total-price {
  gap: 2px;
}

@media (max-width: 575.98px) {
  .i-total-price__row.--main .i-total-price__label {
    font-size: 16px;
  }
  .i-total-price__row.--main .i-total-price__amount {
    font-size: 18px;
  }
}

.i-usp__button {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.i-usp__icon {
  margin-bottom: 4px;
}

.i-usp__body {
  gap: 4px;
  transition: filter 0.3s ease;
}

.i-usp:has(.i-usp__button):hover .i-usp__body {
  filter: blur(4px);
}
.i-usp:has(.i-usp__button):hover .i-usp__button {
  opacity: 1;
  pointer-events: auto;
}

.i-variant {
  outline: 1px solid #ececec;
  transition: outline 0.3s ease;
}
.i-variant:hover, .i-variant.--active {
  outline: 1px solid #292934;
}

.i-warranty {
  min-width: 48px;
  min-height: 48px;
  aspect-ratio: 1/1;
  font-size: 9px;
}
.i-warranty > svg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.i-warranty__value {
  line-height: 16px;
  z-index: 1;
}
.i-warranty__value::first-letter {
  font-size: 16px;
}

.i-warranty__text {
  z-index: 1;
}

.l-aside__grid {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
@media (min-width: 1200px) {
  .l-aside__grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
}

@media (min-width: 1200px) {
  .l-aside__aside {
    grid-column: auto/span 3;
    order: -1;
  }
}
@media (min-width: 768px) and (max-width: 1199.98px) {
  .l-aside__aside {
    max-width: 688px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-inline: auto;
    column-gap: 32px;
  }
}
@media (max-width: 767.98px) {
  .l-aside__aside {
    max-width: 328px;
    margin-inline: auto;
  }
}

@media (min-width: 1200px) {
  .l-aside__content {
    grid-column: auto/span 9;
  }
}

.l-cart {
  --display: flex;
  --aside-cols: auto/span 4;
  --content-cols: auto/span 8;
  display: var(--display);
  grid-template-columns: repeat(12, 1fr);
  gap: 32px;
  row-gap: 56px;
  flex-direction: column;
}
@media (min-width: 992px) {
  .l-cart {
    --display: grid;
  }
}

.l-cart__content {
  grid-column: var(--content-cols);
}
@media (max-width: 1499.98px) {
  .l-cart__content {
    --content-cols: auto/span 7;
  }
}

.l-cart__aside {
  grid-column: var(--aside-cols);
}
@media (max-width: 1499.98px) {
  .l-cart__aside {
    --aside-cols: auto/span 5;
  }
}

.l-user__grid {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
@media (min-width: 1200px) {
  .l-user__grid {
    display: grid;
    grid-template-columns: 100%;
    grid-template-columns: 3fr 9fr;
  }
}

.l-user__aside {
  order: -1;
}

.link__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  transform: translateY(3px);
}
.link__icon svg {
  transition: transform 0.3s ease;
  will-change: transform;
}

.link {
  display: inline-flex;
  transition: text-decoration-color 0.3s ease;
  transition-property: text-decoration-color, color;
  text-decoration: underline;
  align-items: baseline;
  column-gap: 8px;
}
.link.--black {
  color: #292934;
  text-decoration-color: transparent;
}
.link.--black:hover, .link.--black:focus {
  color: #292934;
  text-decoration-color: #292934;
}
.link.--primary {
  color: var(--c-primary);
  text-decoration-color: transparent;
}
.link.--primary:hover, .link.--primary:focus {
  color: var(--c-primary);
  text-decoration-color: var(--c-primary);
}
.link.--white {
  color: #fff;
  text-decoration-color: transparent;
}
.link.--white:hover, .link.--white:focus {
  color: #fff;
  text-decoration-color: #fff;
}
.link.--icon-before .link__icon {
  order: -1;
}
.link.--animation-right svg {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
.link.--animation-right:hover svg {
  transform: translateX(8px);
}
.link.--animation-zoom:hover svg {
  transform: scale(1.1);
}
.link.--underline-initial {
  text-decoration-color: inherit;
}
.link.--underline-initial:hover, .link.--underline-initial:focus {
  text-decoration-color: transparent;
}

.main-footer-column__heading {
  color: #fff;
  font-family: "Noto Serif", serif;
}

@media (max-width: 575.98px) {
  .main-footer-column {
    text-align: center;
  }
}

.main-footer-column__list-item {
  display: block;
}

.main-footer-column__list-item-link {
  display: block;
  color: #a9a9ae;
  text-decoration-color: transparent;
}
.main-footer-column__list-item-link:hover {
  color: #a9a9ae;
  text-decoration-color: #a9a9ae;
}

@media (min-width: 1200px) {
  .main-footer-column__socials {
    justify-content: center;
  }
}
@media (max-width: 575.98px) {
  .main-footer-column__socials {
    justify-content: center;
  }
}

@media (max-width: 575.98px) {
  .main-footer-column__map {
    margin-inline: auto;
  }
}

.main-footer-column__logo {
  margin-bottom: 24px;
}
@media (min-width: 1200px) {
  .main-footer-column__logo {
    margin-bottom: 40px;
    margin-top: 40px;
    margin-inline: auto;
  }
}
@media (max-width: 575.98px) {
  .main-footer-column__logo {
    margin-inline: auto;
  }
}

.footer-map {
  max-width: 224px;
}
.footer-map svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 48px;
  height: 48px;
  pointer-events: none;
}

.footer-map__link {
  max-width: 100%;
  overflow: hidden;
}
.footer-map__link img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition-property: transform, opacity;
}
.footer-map__link:hover img {
  transform: scale(1.1);
}

.main-footer {
  --border-color: var(--c-primary);
  color: #a9a9ae;
  margin-top: 56px;
  border-bottom: 4px solid var(--border-color);
}

.main-footer__top {
  background-color: #34343e;
  padding: 56px 0;
}

.main-footer__bottom {
  background-color: #292934;
  padding: 32px 0;
  text-align: center;
}

.main-footer__grid {
  display: grid;
  gap: 32px;
}
@media (min-width: 576px) {
  .main-footer__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1200px) {
  .main-footer__grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

.main-footer__bottom-group {
  align-items: center;
}

.m-header-bottom {
  background-color: #fafafa;
  border-bottom: 1px solid #ececec;
}

.m-header-bottom__grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
}
@media (min-width: 768px) {
  .m-header-bottom__grid {
    display: grid;
    gap: 32px;
    grid-template-columns: 2fr 6fr 3fr;
  }
}
@media (min-width: 992px) {
  .m-header-bottom__grid {
    grid-template-columns: 3fr 6fr 3fr;
  }
}
@media (min-width: 1200px) {
  .m-header-bottom__grid {
    grid-template-columns: 3fr 7fr 2fr;
  }
}

@media (max-width: 767.98px) {
  .m-header-bottom__search {
    order: 2;
    flex: 1 1 100%;
  }
}

.m-header-bottom__ham {
  --size: 56px;
  min-width: var(--size);
  min-height: var(--size);
  cursor: pointer;
  background-color: transparent;
}
@media (min-width: 1200px) {
  .m-header-bottom__ham {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .m-header-bottom__ham {
    --size: 48px;
  }
}
@media (max-width: 575.98px) {
  .m-header-bottom__ham {
    --size: 48px;
  }
  .m-header-bottom__ham .icon {
    padding: 2px;
  }
}

@media (max-width: 575.98px) {
  .m-header-logo {
    max-width: 120px;
  }
}

.m-header-top {
  --border-color: var(--c-primary);
  background-color: #34343e;
  color: #a9a9ae;
  border-top: 4px solid var(--border-color);
}
.m-header-top .link {
  font-weight: bold;
}
@media (max-width: 1199.98px) {
  .m-header-top {
    padding: 8px 0;
  }
}

.m-header-top__grid {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1199.98px) {
  .m-header-top__grid {
    flex-direction: column;
    gap: 32px;
  }
}

@media (max-width: 1199.98px) {
  .g-base.m-header-top__contact-group {
    --gap: 16px;
    flex-direction: column;
  }
}

@media (max-width: 1199.98px) {
  .g-base.m-header-top__side-menu {
    --gap: 16px;
    flex-direction: column;
  }
}

@media (max-width: 1199.98px) {
  .m-header-top__container {
    display: none;
  }
}

.main-nav {
  max-width: 420px;
  transform: translateX(100%);
  transition: transform 0.3s ease;
  z-index: 100;
}
.main-nav.--open {
  transform: translateX(0);
}
@media (min-width: 1200px) {
  .main-nav {
    display: none;
  }
}

.main-nav__wrapper {
  overflow-y: scroll;
  overflow-x: hidden;
}

.main-nav__header {
  padding-right: 0;
}

.main-nav__closer {
  --size: 56px;
  margin-left: auto;
  min-width: var(--size);
  min-height: var(--size);
  background-color: transparent;
}

.main-nav__bottom {
  background-color: rgba(52, 52, 62, 0.9490196078);
  color: #a9a9ae;
}
.main-nav__bottom .link {
  font-weight: bold;
}

.m-category-item__link {
  padding-block: 5px;
  text-decoration: none;
  color: #292934;
  transition: background-color 0.3s ease;
}
.m-category-item__link:hover {
  color: #292934;
  background-color: #ececec;
}
@media (max-width: 1199.98px) {
  .m-category-item__link {
    padding-block: 13px;
  }
}

.m-category-item__dropdown {
  width: 32px;
  pointer-events: none;
  transition: transform 0.3s ease;
}
@media (max-width: 1199.98px) {
  .m-category-item__dropdown {
    width: 48px;
    height: 48px;
    pointer-events: auto;
    cursor: pointer;
  }
  .m-category-item__dropdown svg {
    transform: rotate(90deg);
  }
}

.m-category-item.--active .m-category-item__link {
  position: relative;
}
.m-category-item.--active .m-category-item__link::before {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  height: 100%;
  background-color: var(--c-primary);
  width: 3px;
}
@media (min-width: 1200px) {
  .m-category-item:hover .m-category-sub-list {
    opacity: 1;
    pointer-events: auto;
    transform: translateX(100%);
  }
}
.m-category-item:hover .m-category-item__dropdown {
  transform: translateY(4px);
}
@media (min-width: 1200px) {
  .m-category-item:hover .m-category-item__dropdown {
    transform: translateX(4px);
  }
}
@media (max-width: 1199.98px) {
  .m-category-item.--open .m-category-sub-list {
    display: flex;
  }
}

.m-category-sub-item__link {
  display: block;
  padding-block: 5px;
  text-decoration: none;
  color: #292934;
}
.m-category-sub-item__link:hover {
  color: #292934;
  background-color: #fafafa;
}
@media (max-width: 1199.98px) {
  .m-category-sub-item__link {
    padding-block: 9px;
  }
}

.m-category-sub-list {
  transition: opacity 0.3s ease;
  transition-property: opacity, transform;
}
.m-category-sub-list::before {
  content: "";
  position: absolute;
  display: block;
  height: 100%;
  width: 3px;
  background-color: #ececec;
  left: 12px;
  top: 0;
}
@media (min-width: 1200px) {
  .m-category-sub-list {
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(calc(100% - 16px));
    z-index: 5;
    width: 100%;
    opacity: 0;
    pointer-events: none;
    display: flex;
  }
}

@media (max-width: 1199.98px) {
  .m-category {
    display: none;
  }
}
.m-category::after {
  content: "";
  position: absolute;
  display: block;
  top: -1px;
  right: 0;
  background-color: #fafafa;
  border: 1px solid #ececec;
  width: 100%;
  height: calc(100% + 2px);
  transform: scaleX(0.85) translateX(100%);
  z-index: 2;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease;
  transition-property: opacity, transform;
}
.m-category.--active::after {
  opacity: 0.97;
  transform: scaleX(1) translateX(100%);
}

.c-modal__dialog {
  position: relative;
  width: auto;
  pointer-events: none;
  margin: 2rem;
  margin-left: auto;
  margin-right: auto;
  cursor: cell;
}

.c-modal__content {
  position: relative;
  width: min(100% - 32px, 920px);
  margin-inline: auto;
  pointer-events: auto;
  background-clip: padding-box;
  outline: 0;
  background-color: #fff;
  border: 1px solid #ececec;
  cursor: auto;
  box-shadow: 4px 4px 24px rgba(15, 38, 57, 0.1019607843);
  transform: translateY(32px);
  transition: transform var(--transtion-length) ease;
}
.c-modal__content.u-gray-box {
  --background: #fff;
}

.c-modal__body {
  position: relative;
  flex: 1 1 auto;
  padding: 32px 24px;
}

.c-modal__header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  gap: 16px;
  padding: 24px 24px 0;
  padding-right: 72px;
}

.c-modal__close-cross {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  top: 20px;
  right: 20px;
}
.c-modal__close-cross svg {
  width: 16px;
  height: 16px;
  z-index: 2;
}
.c-modal__close-cross::before {
  content: "";
  position: absolute;
  display: block;
  background-color: #ececec;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.c-modal__close-cross:hover::before {
  opacity: 1;
}

.c-modal__footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 24px 32px;
  gap: 16px;
}
.c-modal__footer.--right {
  justify-content: flex-end;
}
.c-modal__footer.--center {
  justify-content: center;
}
.c-modal__footer.--between {
  justify-content: space-between;
}

.c-modal__heading {
  font-size: 22px;
}

.c-modal__inner {
  display: flex;
  flex-direction: column;
}

.c-modal {
  --transtion-length: $transitionLength;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9000;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  opacity: 0;
  pointer-events: none;
  background-color: rgba(52, 52, 62, 0.9490196078);
  cursor: pointer;
  transition: opacity var(--transtion-length) ease;
}
.c-modal.--open {
  opacity: 1;
  pointer-events: all;
  visibility: visible;
}
.c-modal.--open .c-modal__content {
  transform: translateY(0);
}
.c-modal.--hidden {
  visibility: hidden;
}
.c-modal.--size-sm .c-modal__content {
  width: min(100% - 32px, 480px);
}
.c-modal.--size-xl .c-modal__content {
  width: min(100% - 32px, 1140px);
}
.c-modal.--height-stretch .c-modal__dialog {
  height: calc(100% - 64px);
}
.c-modal.--height-stretch .c-modal__content {
  height: 100%;
}
.c-modal.--height-stretch .c-modal__inner {
  height: 100%;
}

.c-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  background-color: #292934;
  opacity: 0;
  transition: opacity 0.3s ease;
  cursor: pointer;
}
.c-overlay.--show {
  opacity: 0.75;
  pointer-events: all;
}

.c-pagination__bar {
  --bar-width: 0;
  height: 4px;
  width: 128px;
  background-color: #a9a9ae;
}
.c-pagination__bar::after {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform-origin: left;
  transform: scaleX(var(--bar-width));
  background-color: #292934;
  transition: transform 0.3s ease;
  will-change: trasnform;
}

.c-pagination__divider::before {
  content: "/";
}

.c-pagination__fraction {
  color: #a9a9ae;
  gap: 4px;
}

.c-pagination__current {
  color: #292934;
}

.pd-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.pd-content__price-group {
  row-gap: 16px;
}

.pd-content__buttons {
  border-top: 1px solid #ececec;
  padding-top: 16px;
}

@media (max-width: 575.98px) {
  .pd-content__buttons-group.g-base {
    display: flex;
    flex-direction: column;
  }
  .pd-content__buttons-group.g-base .c-counter {
    max-width: 100%;
    width: auto;
  }
  .pd-content__buttons-group.g-base .btn.--outline {
    min-width: auto;
    width: 100%;
    max-width: 100%;
  }
  .pd-content__buttons-group.g-base .btn.--primary {
    grid-column: span 2/auto;
  }
  .pd-content__buttons-group.g-base.--full {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 576px) {
  .pd-content__links-group.g-base {
    padding: 0 24px;
  }
}
@media (min-width: 992px) and (max-width: 1499.98px) {
  .pd-content__links-group.g-base {
    padding: 0;
    column-gap: 16px;
  }
}
@media (max-width: 575.98px) {
  .pd-content__links-group.g-base {
    flex-direction: column;
  }
}

@media (min-width: 992px) {
  .pd-description {
    padding-left: 32px;
  }
}

.pd-description__header {
  padding: 24px 0;
}

.pd-description__body {
  max-width: 70ch;
}

.pd-gallery {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.pd-gallery__body {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.pd-gallery__hero-slider {
  max-width: 672px;
}
@media (min-width: 768px) {
  .pd-gallery__hero-slider {
    margin-left: 112px;
  }
}

@media (min-width: 768px) {
  .pd-gallery__thumbnails {
    position: absolute;
    top: 0;
    left: 0;
    width: 112px;
    height: 100%;
    max-height: 100%;
  }
}
@media (max-width: 767.98px) {
  .pd-gallery__thumbnails {
    order: 2;
  }
}

.g-heros {
  width: 100%;
}

.g-thumbnails {
  width: 100%;
  max-height: 100%;
}
@media (max-width: 767.98px) {
  .g-thumbnails .swiper-slide {
    width: 112px;
  }
}
.g-thumbnails .swiper-slide .u-product-image {
  will-change: outline-color;
  transition: outline-color 0.3s ease;
}
.g-thumbnails .swiper-buttons {
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
  top: 50%;
  transform: translateY(-50%);
}
@media (min-width: 768px) {
  .g-thumbnails .swiper-buttons {
    height: 100%;
    transform: translateX(-50%);
    top: 0;
    left: 50%;
    flex-direction: column;
  }
}
@media (max-width: 767.98px) {
  .g-thumbnails .swiper-buttons {
    width: 100%;
  }
}
.g-thumbnails .swiper-button {
  pointer-events: auto;
  transition: opacity 0.3s ease;
}
.g-thumbnails .swiper-button.swiper-button-disabled {
  opacity: 0;
}
.g-thumbnails .swiper-button-prev {
  transform: translateY(-1px);
  rotate: 90deg;
}
.g-thumbnails .swiper-button-prev.--hidden {
  opacity: 0;
  pointer-events: none;
}
@media (min-width: 768px) {
  .g-thumbnails .swiper-button-prev {
    rotate: 90deg;
  }
}
.g-thumbnails .swiper-button-next {
  transform: translateY(-1px);
}
@media (max-width: 767.98px) {
  .g-thumbnails .swiper-button-next {
    rotate: -90deg;
  }
}
.g-thumbnails .swiper-slide-thumb-active .u-product-image {
  outline-color: #292934;
}

.pd-info-box {
  border-top: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
}

.pd-info-box__body {
  gap: 14px;
}
@media (max-width: 575.98px) {
  .pd-info-box__body {
    padding: 24px 8px;
  }
}

.pd-i-hero {
  border: 1px solid #ececec;
  outline: none;
}

.pd-i-info__label {
  flex: 0 0 160px;
}
@media (max-width: 575.98px) {
  .pd-i-info__label {
    flex: 0 0 96px;
    gap: 8px;
  }
}
@media (max-width: 359px) {
  .pd-i-info__label {
    flex: 0 0 88px;
  }
}
.pd-i-info__label::after {
  content: ":";
}

.pd-i-thumbnail {
  border: 1px solid #ececec;
  border-top: 0;
  padding: 16px;
  cursor: pointer;
}
.pd-i-thumbnail:first-of-type {
  border-top: 1px solid #ececec;
}

.pd-parameters.u-gray-box {
  --background: #fff;
}

.pd-parameters__header {
  padding: 24px 32px;
}

.pd-parameters__body {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
@media (max-width: 575.98px) {
  .pd-parameters__body {
    overflow: visible;
  }
}

.pd-parameters__table {
  width: 100%;
  border-collapse: collapse;
}
@media (min-width: 576px) {
  .pd-parameters__table {
    table-layout: auto;
  }
}
.pd-parameters__table td {
  padding: 12px 0;
}
.pd-parameters__table td:nth-child(2) {
  padding-right: 32px;
}
.pd-parameters__table td:first-child {
  padding-left: 32px;
  width: 208px;
}
.pd-parameters__table td:first-child::after {
  content: ":";
}
.pd-parameters__table tr:nth-of-type(odd) {
  background-color: #fafafa;
  border-top: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
}

.pd-layout__top {
  display: grid;
  grid-template-columns: 7fr 5fr;
  gap: 32px;
}
@media (min-width: 1200px) {
  .pd-layout__top {
    gap: 48px;
  }
}
@media (max-width: 1199.98px) {
  .pd-layout__top {
    grid-template-columns: 6fr 6fr;
  }
}
@media (max-width: 991.98px) {
  .pd-layout__top {
    display: flex;
    flex-direction: column;
  }
}

.pd-layout__bottom {
  display: grid;
  gap: 32px;
}
@media (min-width: 992px) {
  .pd-layout__bottom {
    grid-template-columns: repeat(2, 1fr);
  }
}

.pd-layout {
  padding-bottom: 56px;
}

.pd-layout__container {
  display: flex;
  flex-direction: column;
  gap: 112px;
}

.pd-set-products__header {
  padding: 24px 32px;
}
.pd-set-products__header.u-gray-box {
  background-color: #fff;
}

.scroll-up {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 32px;
  right: 32px;
  border-radius: 0;
  background-color: var(--c-primary);
  z-index: 50;
  transition: background-color 0.3s ease, opacity 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55), transform 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  will-change: opacity, transform, background-color;
  pointer-events: none;
  opacity: 0;
  transform: translateY(32px) scale(0.8);
  cursor: pointer;
}
@media (min-width: 576px) {
  .scroll-up {
    display: none;
  }
}
.scroll-up::after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease;
  transition-property: opacity, box-shadow;
  box-shadow: 0 0 10px 40px #fff;
}
.scroll-up:active::after {
  box-shadow: 0 0 0 0 #fff;
  position: absolute;
  opacity: 1;
  transition: 0s;
}
.scroll-up svg {
  width: 16px;
  height: 16px;
}
.scroll-up path {
  fill: #fff;
}
.scroll-up.--active {
  opacity: 1;
  pointer-events: all;
  transform: translateY(0) scale(1);
}

.s-breadcrumbs {
  margin-top: 32px;
  margin-bottom: 32px;
}
.s-breadcrumbs.--top-0 {
  margin-top: 0;
}
.s-breadcrumbs.--bottom-0 {
  margin-top: 0;
}
.s-breadcrumbs.--y-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.s-cart__aside-layout {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 32px;
}
@media (max-width: 1199.98px) {
  .s-cart__aside-layout {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 991.98px) {
  .s-cart__aside-layout {
    display: flex;
    flex-direction: column;
  }
}
.s-cart__aside-layout .n-cart {
  grid-column-start: 2;
}

.s-cart {
  --empty-display: none;
  --content-display: flex;
}
.s-cart.--empty {
  --empty-display: block;
  --content-display: none;
}

.s-cart__content {
  display: var(--content-display);
}

.s-cart__empty {
  display: var(--empty-display);
}

.s-contact-form__row {
  justify-content: center;
}

.s-contact__grid {
  display: grid;
  grid-template-columns: 7fr 5fr;
  gap: 32px;
}
@media (max-width: 991.98px) {
  .s-contact__grid {
    grid-template-columns: 1fr;
  }
}

.s-contact__image {
  outline: 1px solid #ececec;
}
.s-contact__image .img {
  outline: 1px solid #ececec;
}
.s-contact__image svg {
  width: 48px;
  height: 48px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}
@media (max-width: 991.98px) {
  .s-contact__image {
    max-width: 540px;
    margin-inline: auto;
  }
}

.s-dropdown-product__header {
  padding-bottom: 24px;
  position: relative;
}
.s-dropdown-product__header::after {
  content: "";
  position: absolute;
  display: block;
  width: calc(100% + 2 * var(--offset));
  height: 1px;
  background-color: #ececec;
  bottom: 12px;
  left: 0;
  transform: translateX(calc(-1 * var(--offset)));
}

.s-dropdown-product__heading {
  font-weight: bold;
  font-family: "Noto Serif", serif;
  font-size: 16px;
}

.s-dropdown-product__buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 14px;
  font-weight: bold;
  margin-top: 24px;
  align-items: center;
}

.s-dropdown-product__empty {
  display: none;
  font-size: 14px;
}

.s-dropdown-product {
  --offset: 16px;
  padding: var(--offset);
  box-shadow: 0 0 40px rgba(41, 41, 52, 0.0509803922);
  position: relative;
  color: #292934;
  transform: translateY(8px);
}
.s-dropdown-product.u-gray-box {
  --background: #fff;
}
.s-dropdown-product::after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8px 8px;
  border-color: transparent transparent #ececec;
  bottom: 100%;
  right: 19px;
}
.s-dropdown-product.--empty .s-dropdown-product__empty {
  display: block;
}
.s-dropdown-product.--empty .s-dropdown-product__buttons {
  display: none;
}

.s-dropdown-product__content {
  max-height: 180px;
}

.s-error-404 {
  text-align: center;
}

.s-error-404__perex {
  max-width: 592px;
  margin-inline: auto;
  margin-bottom: 0;
}

.s-error-404__sub-heading {
  max-width: 720px;
  margin-inline: auto;
  margin-bottom: 32px;
}

.s-error-404__heading {
  color: var(--c-primary);
  font-size: 72px;
}
@media (min-width: 768px) {
  .s-error-404__heading {
    font-size: 104px;
  }
}

.s-filters__footer {
  align-items: center;
}
@media (max-width: 575.98px) {
  .s-filters__footer {
    flex-direction: column;
  }
}

.s-filters__footer-line {
  flex-grow: 1;
  background-color: #ececec;
  height: 1px;
  width: 100%;
}

.s-filters {
  margin-top: 32px;
  margin-bottom: 32px;
}

.s-filters__body {
  padding-top: 32px;
  border-top: 1px solid #ececec;
}
.s-filters__body.--hidden {
  display: none;
}

@media (max-width: 575.98px) {
  .s-filters__header {
    flex-direction: column;
  }
}

.s-gifts {
  --remaining-display: none;
  --reached-display: block;
}
.s-gifts.--disabled {
  --remaining-display: block;
  --reached-display: none;
}

.s-gifts__heading.--remaining {
  display: var(--remaining-display);
}
.s-gifts__heading.--reached {
  display: var(--reached-display);
}

.s-grader__empty-note {
  display: none;
}

.s-grader.--empty .s-grader__empty-note {
  display: block;
}

.i-intro__grid {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
@media (min-width: 1200px) {
  .i-intro__grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
}

.i-intro__content {
  display: flex;
  flex-direction: column;
  gap: 48px;
}
@media (min-width: 1200px) {
  .i-intro__content {
    grid-column: auto/span 9;
  }
}

.i-intro__aside {
  order: -1;
}
@media (min-width: 1200px) {
  .i-intro__aside {
    grid-column: auto/span 3;
  }
}

.s-post-detail__meta {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px 32px;
}

.s-post-detail__entry-content {
  margin-top: 32px;
}

.s-seo__grid {
  gap: 56px;
  row-gap: 32px;
  border-top: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
  padding-top: 56px;
  padding-bottom: 56px;
}
@media (max-width: 991.98px) {
  .s-seo__grid {
    flex-direction: column;
    align-items: initial;
  }
}

.s-seo__content-full {
  display: none;
}

.s-seo__button .btn__icon {
  position: absolute;
  left: 50%;
  top: calc(50% + 14px);
  transform: translateX(-50%) translateY(-50%);
}
.s-seo__button .btn__icon svg {
  width: 9px;
}

.s-seo {
  padding-top: 0;
}
.s-seo.--active .s-seo__content-short {
  display: none;
}
.s-seo.--active .s-seo__content-full {
  display: block;
}
.s-seo.--active .s-seo__button svg {
  scale: -1;
}

.s-showroom__main {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
  align-items: center;
}
@media (max-width: 991.98px) {
  .s-showroom__main {
    grid-template-columns: 1fr;
  }
}

.s-showroom__left .img {
  max-width: 656px;
}

@media (min-width: 992px) and (max-width: 1499.98px) {
  .s-showroom__heading {
    font-size: 32px;
  }
}

@media (min-width: 992px) {
  .s-showroom__header .base-header__paragraph {
    display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media (min-width: 992px) and (max-width: 1499.98px) {
  .s-showroom__header .base-header__paragraph {
    -webkit-line-clamp: 5;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .s-showroom__header .base-header__paragraph {
    -webkit-line-clamp: 2;
  }
}
@media (max-width: 1499.98px) {
  .s-showroom__header {
    margin-bottom: 0;
  }
}

.language-select__active {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: #fff;
  position: relative;
  min-height: 40px;
}
@media (max-width: 1199.98px) {
  .language-select__active {
    background-color: #292934;
  }
}

.language-select__drowpdown {
  width: 100%;
  transition: opacity 0.3s ease;
  flex-direction: column;
  align-items: center;
  background-color: #292934;
  padding-bottom: 8px;
  display: flex;
}
@media (min-width: 1200px) {
  .language-select__drowpdown {
    position: absolute;
    top: 100%;
    opacity: 0;
    left: 0;
    pointer-events: none;
  }
}

.language-select__dropdown-link {
  text-decoration: none;
  color: #a9a9ae;
  transition: color 0.3s ease;
  padding: 4px 0;
}
.language-select__dropdown-link:hover {
  color: #fff;
}

.language-select {
  position: relative;
  min-width: 64px;
  z-index: 10;
}
@media (min-width: 1200px) {
  .language-select.--has-items:hover .language-select__drowpdown {
    opacity: 1;
    pointer-events: auto;
  }
}
.language-select.--has-items:hover .language-select__active {
  background-color: #292934;
}

.se-multi-checkbox__header {
  padding: 7px 8px;
  min-height: 48px;
  align-items: center;
  border: 1px solid #ececec;
  user-select: none;
  background-color: var(--header-background);
}

.se-multi-checkbox__clear {
  cursor: pointer;
  display: none;
}
.se-multi-checkbox__clear.--active {
  display: flex;
}

.se-multi-checkbox__toggler {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}
.se-multi-checkbox__toggler svg {
  width: 14px;
  height: 14px;
}
.se-multi-checkbox__toggler:hover {
  background-color: #ececec;
}
.se-multi-checkbox__toggler.--active svg {
  scale: -1;
}

.se-multi-checkbox__label {
  --end: 0;
  margin-left: 8px;
  overflow: hidden;
  flex: 1;
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  height: 32px;
  cursor: pointer;
}
.se-multi-checkbox__label > span {
  position: absolute;
  white-space: nowrap;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: left 2s linear;
}
.se-multi-checkbox__label::after {
  content: "";
  pointer-events: none;
  position: absolute;
  display: block;
  right: 0;
  top: 0;
  width: 16px;
  height: 100%;
  background: transparent linear-gradient(270deg, var(--header-background) 0%, rgba(255, 255, 255, 0) 100%) 0% 0% no-repeat padding-box; /* stylelint-disable-line max-line-length */
  z-index: 1;
}
.se-multi-checkbox__label:hover > span {
  left: var(--end);
}

.se-multi-checkbox__body {
  border: 1px solid #ececec;
  top: calc(100% - 1px);
  left: 50%;
  transform: translateX(-50%) scaleY(0.25);
  transform-origin: top;
  width: calc(100% - 1px);
  background-color: #fff;
  z-index: 5;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  transition-property: opacity, transform;
  will-change: opacity, transform;
}

.se-multi-checkbox__body-inner {
  max-height: 160px;
  padding-bottom: 16px;
}

.se-multi-checkbox {
  --header-background: #fff;
  min-width: 0;
}
.se-multi-checkbox.--active {
  --header-background: #fafafa;
}
.se-multi-checkbox.--active .se-multi-checkbox__body {
  opacity: 1;
  pointer-events: auto;
  transform: translateX(-50%) scaleY(1);
}

.se-single__select {
  font-weight: 700;
}
.se-single__select.slim-select {
  --ss-primary-color: var(--c-primary);
  --ss-bg-color: #fff;
  --ss-font-color: #292934;
  --ss-border-color: #ececec;
  --ss-success-color: #51c87c;
  --ss-error-color: #f93a3a;
  --ss-main-height: 64px;
  --ss-content-height: 300px;
  --ss-animation-timing: 0.3s;
  --ss-border-radius: 0;
}
.se-single__select.slim-select .ss-list .ss-option {
  padding: 8px 24px;
}
.se-single__select.slim-select.ss-open-above, .se-single__select.slim-select.ss-open-below {
  --ss-bg-color: #fafafa;
}
.se-single__select.slim-select.ss-content {
  border-top: 0;
}
.se-single__select.slim-select.ss-main {
  font-weight: 700;
  padding: 0;
  padding-left: 24px;
  padding-right: 24px;
}
.se-single__select.slim-select.ss-main .ss-arrow {
  width: 14px;
  margin: auto 0;
}
.se-single__select.slim-select.ss-main:focus {
  box-shadow: none;
}

.se-single.--native {
  display: grid;
  align-items: center;
  grid-template-areas: "select";
}
.se-single.--native::after {
  display: block;
  content: "";
  width: 14px;
  height: 8px;
  background-image: url("../images/svg/arrow-down.svg");
  background-repeat: no-repeat;
  background-size: contain;
  justify-self: end;
  grid-area: select;
  margin-right: 24px;
}
.se-single.--native .se-single__select {
  width: 100%;
  min-height: 64px;
  border: 1px solid #ececec;
  background-color: #fff;
  padding-left: 24px;
  cursor: pointer;
  outline: none;
  margin: 0;
  appearance: none;
  grid-area: select;
}

.se-slim-single__select.slim-select {
  --ss-primary-color: var(--c-primary);
  --ss-bg-color: #fff;
  --ss-font-color: #292934;
  --ss-border-color: #ececec;
  --ss-success-color: #51c87c;
  --ss-error-color: #f93a3a;
  --ss-main-height: 32px;
  --ss-content-height: 300px;
  --ss-spacing-l: 7px;
  --ss-spacing-m: 5px;
  --ss-spacing-s: 3px;
  --ss-animation-timing: 0.3s;
  --ss-border-radius: 0;
  font-size: 14px;
}
.se-slim-single__select.slim-select.ss-open-above, .se-slim-single__select.slim-select.ss-open-below {
  --ss-bg-color: #fafafa;
}
.se-slim-single__select.slim-select.ss-content {
  border-top: 0;
  width: fit-content;
  translate: -100%;
}
.se-slim-single__select.slim-select.ss-main {
  font-weight: 700;
  border-color: transparent;
}
.se-slim-single__select.slim-select.ss-main:focus {
  box-shadow: none;
}
.se-slim-single__select.slim-select.ss-main.ss-open-above, .se-slim-single__select.slim-select.ss-main.ss-open-below {
  border-color: var(--ss-border-color);
  border-bottom-color: transparent;
}

.se-slim-single.--native {
  font-size: 14px;
  display: grid;
  align-items: center;
  grid-template-areas: "select";
}
.se-slim-single.--native::after {
  display: block;
  content: "";
  width: 10px;
  height: 10px;
  background-image: url("../images/svg/arrow-down.svg");
  background-repeat: no-repeat;
  background-size: contain;
  justify-self: end;
  grid-area: select;
}
.se-slim-single.--native .se-slim-single__select {
  font-weight: 700;
  width: 100%;
  min-height: 32px;
  background-color: #fff;
  border: none;
  padding-right: 24px;
  cursor: pointer;
  outline: none;
  margin: 0;
  appearance: none;
  grid-area: select;
}
.se-slim-single.--native .se-slim-single__select.slim-select {
  padding-right: 0;
}

.tippy-box[data-animation=fade][data-state=hidden] {
  opacity: 0;
}

[data-tippy-root] {
  max-width: calc(100vw - 10px);
}

.tippy-arrow {
  width: 16px;
  height: 16px;
  color: #ececec;
}

.tippy-arrow::before {
  content: "";
  position: absolute;
  border-color: transparent;
  border-style: solid;
}

.tippy-box {
  position: relative;
  background-color: #fff;
  border: 1px solid #ececec;
  box-shadow: 0 0 40px rgba(41, 41, 52, 0.0509803922);
  font-size: 14px;
  white-space: normal;
  outline: 0;
  transition-property: transform, visibility, opacity;
}

.tippy-box[data-placement^=top] > .tippy-arrow {
  bottom: 0;
}

.tippy-box[data-placement^=top] > .tippy-arrow::before {
  bottom: -8px;
  left: 0;
  border-width: 8px 8px 0;
  border-top-color: initial;
  transform-origin: center top;
}

.tippy-box[data-placement^=bottom] > .tippy-arrow {
  top: 0;
}

.tippy-box[data-placement^=bottom] > .tippy-arrow::before {
  top: -8px;
  left: 0;
  border-width: 0 8px 8px;
  border-bottom-color: initial;
  transform-origin: center bottom;
}

.tippy-box[data-placement^=left] > .tippy-arrow {
  right: 0;
}

.tippy-box[data-placement^=left] > .tippy-arrow::before {
  border-width: 8px 0 8px 8px;
  border-left-color: initial;
  right: -8px;
  transform-origin: center left;
}

.tippy-box[data-placement^=right] > .tippy-arrow {
  left: 0;
}

.tippy-box[data-placement^=right] > .tippy-arrow::before {
  left: -8px;
  border-width: 8px 8px 8px 0;
  border-right-color: initial;
  transform-origin: center right;
}

.tippy-box[data-inertia][data-state=visible] {
  transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
}

.tippy-content {
  padding: 16px;
}

.w-navigation__link {
  border-bottom: 1px solid #ececec;
}
.w-navigation__link:last-of-type {
  border-bottom: none;
}

.w-widgets__navigation-item {
  transition-property: background-color, text-decoration-color;
}
.w-widgets__navigation-item:hover {
  background-color: #ececec;
}
.w-widgets__navigation-item.--active::before {
  content: "";
  position: absolute;
  display: block;
  height: 100%;
  width: 2px;
  background-color: var(--c-primary);
  left: 0;
  top: 0;
}

.entry-content {
  padding: 0;
  text-align: left;
  max-width: 85ch;
}
.entry-content strong:empty,
.entry-content b:empty,
.entry-content p:empty {
  display: none;
}
.entry-content hr {
  border: none;
  background: #fafafa;
  height: 1px;
  margin: 48px 0;
  transform: translateY(6px);
}
.entry-content .wp-caption {
  border: 0;
  padding: 0;
  text-align: center;
  max-width: 100%;
}
.entry-content .wp-caption-text {
  font-size: 16px;
  color: #292934;
  margin-left: 0;
  transform: none;
  text-align: center;
  line-height: 24px;
  padding: 4px 10px;
  background: rgba(235, 235, 235, 0.39);
  margin-bottom: 0;
}

.entry-content blockquote {
  font-size: 16px;
  font-weight: 400;
  padding: 24px;
  margin: 24px 0;
  position: relative;
  font-style: italic;
  border: 1px solid #ececec;
}
.entry-content blockquote p {
  font-style: italic;
  margin-bottom: 0;
}

@media (max-width: 575.98px) {
  .entry-content {
    margin-top: 16px;
  }
}
.entry-content h1,
.entry-content h2,
.entry-content h3,
.entry-content h4,
.entry-content h5,
.entry-content h6 {
  --font-family: Noto Serif, serif;
  margin-top: 40px;
  color: #292934;
  font-family: var(--font-family);
  font-weight: bold;
  margin-bottom: 8px;
}
@media (max-width: 575.98px) {
  .entry-content h1,
  .entry-content h2,
  .entry-content h3,
  .entry-content h4,
  .entry-content h5,
  .entry-content h6 {
    margin-bottom: 24px !important;
  }
}
.entry-content h1 {
  margin-top: 0;
  position: relative;
  font-size: 40px;
  line-height: 48px;
}
.entry-content h2 {
  position: relative;
  font-size: 40px;
  line-height: 48px;
}
.entry-content h3 {
  font-size: 32px;
  line-height: 40px;
}
.entry-content h4 {
  font-size: 24px;
  line-height: 32px;
}
.entry-content h5 {
  font-size: 18px;
  line-height: 24px;
}
.entry-content > h2:first-child, .entry-content > h3:first-child {
  margin-top: 0;
}

.entry-content img {
  max-width: 100%;
  margin-block: 16px;
}

.entry-content ol > li {
  position: relative;
  counter-increment: item;
  padding-left: 35px;
}
.entry-content ol > li::before {
  content: counter(item) ".";
  font-weight: 700;
  font-size: 18px;
  top: 1px;
  width: 28px;
  height: 28px;
  text-align: center;
  display: inline-block;
  position: relative;
  margin-right: 8px;
  color: #292934;
  margin-left: -35px;
  border-radius: 100%;
  padding: 1px 4px;
  padding-left: 6px;
}
.entry-content ol > li ol > li { /* stylelint-disable-line no-descending-specificity */
  counter-increment: sub-item;
}
.entry-content ol > li ol > li::before {
  content: counter(sub-item) ".";
}
.entry-content ol > li ol > li ol > li { /* stylelint-disable-line no-descending-specificity */
  counter-increment: sub-sub-item;
}
.entry-content ol > li ol > li ol > li::before {
  content: counter(sub-sub-item) ".";
}
.entry-content ol[style*="list-style-type: lower-alpha"] li::before {
  content: counter(item, lower-alpha) ")";
}
.entry-content ol[style*="list-style-type: upper-alpha"] li::before {
  content: counter(item, upper-alpha) ")";
}
.entry-content ol[style*="list-style-type: lower-latin"] li::before {
  content: counter(item, lower-latin) ")";
}
.entry-content ol[style*="list-style-type: upper-latin"] li::before {
  content: counter(item, upper-latin) ")";
}
.entry-content ol[style*="list-style-type: lower-greek"] li::before {
  content: counter(item, lower-greek) ")";
}
.entry-content ol[style*="list-style-type: upper-greek"] li::before {
  content: counter(item, upper-greek) ")";
}
.entry-content ol[style*="list-style-type: lower-roman"] li::before {
  content: counter(item, lower-roman) ")";
}
.entry-content ol[style*="list-style-type: upper-roman"] li::before {
  content: counter(item, upper-roman) ")";
}
.entry-content ul > li { /* stylelint-disable-line no-descending-specificity */
  padding-left: 20px;
}
.entry-content ul > li::before { /* stylelint-disable-line no-descending-specificity */
  content: "";
  display: inline-block;
  position: relative;
  top: 50%;
  left: 0;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  margin-right: 10px;
  background-color: #292934;
  transform: translateY(-2px);
  margin-left: -16px;
}
.entry-content ul,
.entry-content ol {
  list-style: none !important;
  padding: 0;
  margin: 0;
  margin-bottom: 24px !important;
  color: #292934;
}
.entry-content ul ul,
.entry-content ul ol,
.entry-content ol ul,
.entry-content ol ol {
  margin-bottom: 0 !important;
  margin-top: 8px;
}
.entry-content ul li,
.entry-content ol li { /* stylelint-disable-line no-descending-specificity */
  margin-bottom: 8px;
}

.entry-content p {
  line-height: 24px;
  color: #292934;
  margin-bottom: 24px;
}

.entry-content .table-responsive {
  margin: 16px 0;
  box-shadow: 0 0 0 1px #fafafa;
  overflow: auto;
}
.entry-content th,
.entry-content td,
.entry-content tr {
  border: 1px solid #ececec;
}
.entry-content td,
.entry-content th {
  padding: 16px;
  text-align: center;
  position: relative;
}
.entry-content thead {
  color: #292934;
  font-size: 18px;
  font-weight: bold;
  font-family: "Noto Serif", serif;
}
.entry-content tbody {
  background-color: #fff;
  color: #292934;
  font-size: 14px;
}
.entry-content tbody tr:nth-child(odd) {
  background-color: #fafafa;
}
.entry-content tbody td,
.entry-content tbody th {
  padding: 8px;
}
.entry-content tbody td::before,
.entry-content tbody th::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 1px;
  background: #fafafa;
  top: 0;
  right: 0;
}
.entry-content tbody td:last-child::before,
.entry-content tbody th:last-child::before {
  display: none;
}
.entry-content table {
  margin-bottom: 0 !important;
  width: 100%;
  max-width: 100%;
}
.entry-content table p {
  margin-bottom: 0 !important;
  font-size: 16px;
  transform: none;
}

.g-search-results {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-top: 80px;
}

.i-search-result {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 80ch;
}

.i-search-result__heading {
  margin-bottom: 16px;
}

.i-search-result__heading-link {
  text-decoration-color: transparent;
}
.i-search-result__heading-link:hover {
  text-decoration-color: var(--c-primary);
}

.i-search-result__description {
  color: #292934;
  font-size: 20px;
}

.i-search-result__link {
  margin-bottom: 8px;
  text-decoration-color: transparent;
  transition-property: color, text-decoration-color;
}
.i-search-result__link:hover {
  color: var(--c-primary);
  text-decoration-color: var(--c-primary);
}

.search-form__wrap {
  display: flex;
  align-items: center;
  position: relative;
  gap: 16px 32px;
  max-width: 80ch;
}
@media (max-width: 991.98px) {
  .search-form__wrap {
    flex-wrap: wrap;
  }
}

.search-form__heading {
  flex-shrink: 0;
}

.search-form {
  background-color: #fff;
  box-shadow: inset 0 0 0 1px #fafafa;
  display: flex;
  flex-grow: 1;
  position: relative;
}

.search-form__input {
  border: 0;
  background: none;
  padding: 0 16px;
  flex-grow: 1;
  outline: 0;
  color: #292934;
}
@media (max-width: 575.98px) {
  .search-form__input {
    min-width: 0;
  }
}

.search-form__submit {
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
}
.search-form__submit svg {
  width: 18px;
  height: 18px;
  transition: fill 0.2s;
  fill: var(--c-primary);
}
.search-form__submit:hover {
  background-color: var(--c-primary);
}
.search-form__submit:hover svg {
  fill: #fff;
}
.search-form__submit:active, .search-form__submit:focus {
  outline: 0;
}

/*! purgecss end ignore */