*, :before, :after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

hr {
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: .25;
  margin: .5rem 0;
}

h5, h4, h3, h2, h1 {
  color: var(--bs-heading-color);
  margin-top: 0;
  margin-bottom: .25rem;
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 75em) {
  h1 {
    font-size: 2.5rem;
  }
}

h2 {
  font-size: calc(1.325rem + .9vw);
}

@media (min-width: 75em) {
  h2 {
    font-size: 2rem;
  }
}

h3 {
  font-size: calc(1.3rem + .6vw);
}

@media (min-width: 75em) {
  h3 {
    font-size: 1.75rem;
  }
}

h4 {
  font-size: calc(1.275rem + .3vw);
}

@media (min-width: 75em) {
  h4 {
    font-size: 1.5rem;
  }
}

h5 {
  font-size: 1.25rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: .875em;
}

mark {
  background-color: var(--bs-highlight-bg);
  padding: .1875em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}

a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

code {
  font-family: var(--bs-font-monospace);
  color: var(--bs-code-color);
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: var(--bs-secondary-color);
  text-align: left;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead, tbody, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

::-webkit-datetime-edit-fields-wrapper, ::-webkit-datetime-edit-text, ::-webkit-datetime-edit-minute, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -.125rem;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

iframe {
  border: 0;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.container, .container-fluid {
  --bs-gutter-x: 2rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 36em) {
  .container {
    max-width: 33.75rem;
  }
}

@media (min-width: 48em) {
  .container {
    max-width: 45rem;
  }
}

@media (min-width: 62em) {
  .container {
    max-width: 60rem;
  }
}

@media (min-width: 75em) {
  .container {
    max-width: 71.25rem;
  }
}

@media (min-width: 93.75em) {
  .container {
    max-width: 91.25rem;
  }
}

:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 36rem;
  --bs-breakpoint-md: 48rem;
  --bs-breakpoint-lg: 62rem;
  --bs-breakpoint-xl: 75rem;
  --bs-breakpoint-xxl: 93.75rem;
  --bs-breakpoint-fullhd: 120rem;
}

.row {
  --bs-gutter-x: 2rem;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.row > * {
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  flex-shrink: 0;
}

.col-12 {
  width: 100%;
  flex: none;
}

.gy-2 {
  --bs-gutter-y: 1rem;
}

.gy-4 {
  --bs-gutter-y: 2rem;
}

@media (min-width: 48em) {
  .col-md-3 {
    width: 25%;
    flex: none;
  }

  .col-md-6 {
    width: 50%;
    flex: none;
  }
}

@media (min-width: 62em) {
  .col-lg-3 {
    width: 25%;
    flex: none;
  }

  .col-lg-6 {
    width: 50%;
    flex: none;
  }
}

@media (min-width: 75em) {
  .col-xl-10 {
    width: 83.3333%;
    flex: none;
  }
}

@media (min-width: 93.75em) {
  .col-xxl-8 {
    width: 66.6667%;
    flex: none;
  }
}

.ratio {
  width: 100%;
  position: relative;
}

.ratio:before {
  padding-top: var(--bs-aspect-ratio);
  content: "";
  display: block;
}

.ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.opacity-0 {
  opacity: 0;
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.d-none {
  display: none;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.start-0 {
  left: 0;
}

.end-0 {
  right: 0;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.flex-column {
  flex-direction: column;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-self-center {
  align-self: center;
}

.mx-1 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mt-2 {
  margin-top: 1rem;
}

.mt-3 {
  margin-top: 1.5rem;
}

.mt-4 {
  margin-top: 2rem;
}

.mt-5 {
  margin-top: 2.5rem;
}

.mt-auto {
  margin-top: auto;
}

.me-1 {
  margin-right: .5rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: .5rem;
}

.mb-2 {
  margin-bottom: 1rem;
}

.mb-3 {
  margin-bottom: 1.5rem;
}

.mb-5 {
  margin-bottom: 2.5rem;
}

.mb-6 {
  margin-bottom: 3rem;
}

.mb-8 {
  margin-bottom: 4rem;
}

.ms-1 {
  margin-left: .5rem;
}

.ms-3 {
  margin-left: 1.5rem;
}

.ms-auto {
  margin-left: auto;
}

.p-1 {
  padding: .5rem;
}

.p-2 {
  padding: 1rem;
}

.p-3 {
  padding: 1.5rem;
}

.p-4 {
  padding: 2rem;
}

.px-1 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-3 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-4 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-1 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-3 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-4 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pt-1 {
  padding-top: .5rem;
}

.pt-4 {
  padding-top: 2rem;
}

.pb-2 {
  padding-bottom: 1rem;
}

.pb-3 {
  padding-bottom: 1.5rem;
}

.gap-1 {
  gap: .5rem;
}

.gap-2 {
  gap: 1rem;
}

.gap-3 {
  gap: 1.5rem;
}

.gap-4 {
  gap: 2rem;
}

.gap-5 {
  gap: 2.5rem;
}

.gap-6 {
  gap: 3rem;
}

.gap-7 {
  gap: 3.5rem;
}

.gap-8 {
  gap: 4rem;
}

.container {
  padding-left: 1.625rem;
  padding-right: 1.625rem;
}

@media (max-width: 35.999em) {
  .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (max-width: 22.438em) {
  .container {
    padding-left: .5rem;
    padding-right: .5rem;
  }
}

.fancybox__container {
  --fancybox-accent-color: var(--c-primary);
  --fancybox-thumbs-border-radius: 0;
}

body {
  color: #292934;
  -webkit-font-smoothing: antialiased;
  min-height: 100%;
  flex-direction: column;
  font-family: Noto Sans, sans-serif;
  display: flex;
  overflow-x: hidden;
  -moz-osx-font-smoothing: grayscale !important;
}

figure {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0;
  font-weight: 700;
}

html {
  scroll-behavior: smooth;
  height: 100%;
}

iframe {
  max-width: 100%;
  display: block;
}

img {
  opacity: 1;
  height: auto;
  max-width: 100%;
  transition: opacity .5s;
  display: block;
}

img[data-src], img[data-srcset] {
  min-height: .0625rem;
  min-width: .0625rem;
  display: block;
}

img[data-src]:not(.loaded) {
  opacity: 0;
}

a {
  color: var(--c-primary);
  text-decoration: underline;
  transition: text-decoration-color .3s;
  display: inline-block;
}

a:hover, a:focus {
  color: var(--c-primary);
  text-decoration-color: #0000;
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

main {
  flex-grow: 1;
  display: block;
  overflow-x: hidden;
}

:root {
  --c-primary: #0066b3;
  --c-primary-500: #0060a8;
}

strong, b {
  font-weight: 700;
}

.entry-content .column {
  width: 100%;
  min-height: .0625rem;
  margin-bottom: 1.5rem;
  padding-left: .9375rem;
  padding-right: .9375rem;
  position: relative;
}

.entry-content .column img {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  display: block;
  float: none !important;
}

@media (max-width: 61.999em) {
  .entry-content .column h2:first-of-type, .entry-content .column h3:first-of-type, .entry-content .column h4:first-of-type, .entry-content .column h5:first-of-type, .entry-content .column h6:first-of-type {
    margin-top: 0;
  }
}

.entry-content .columns-1, .entry-content .columns-1 .column {
  display: block;
}

.entry-content .columns-2 {
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  display: grid;
}

@media (max-width: 61.999em) {
  .entry-content .columns-2 {
    flex-direction: column;
    display: flex;
  }
}

.entry-content .columns-3 {
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  display: grid;
}

@media (max-width: 61.999em) {
  .entry-content .columns-3 {
    flex-direction: column;
    display: flex;
  }
}

.entry-content .columns-4 {
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  display: grid;
}

@media (max-width: 74.999em) {
  .entry-content .columns-4 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 61.999em) {
  .entry-content .columns-4 {
    flex-direction: column;
    display: flex;
  }
}

div.validator {
  height: 0;
  position: absolute;
  top: calc(100% + .125rem);
  left: -.0625rem;
}

div.validator > span.erorr-s {
  white-space: nowrap;
  color: #fff;
  z-index: 99;
  background: #f93a3a;
  border-radius: 0;
  padding: .125rem .5rem;
  font-size: .75rem;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.alignnone {
  margin: .3125rem 1.25rem 1.25rem 0;
}

.aligncenter, div.aligncenter {
  margin: 3rem auto;
  display: block;
}

.aligncenter img, div.aligncenter img {
  margin-inline: auto;
}

.alignright {
  float: right;
  margin: 0 0 1.25rem 1.875rem;
}

.alignleft {
  float: left;
  margin: 0 1.875rem 1.25rem 0;
}

.screen-reader-text {
  clip: rect(.0625rem, .0625rem, .0625rem, .0625rem);
  height: .0625rem;
  width: .0625rem;
  overflow: hidden;
  position: absolute !important;
}

.screen-reader-text:focus {
  color: #21759b;
  height: auto;
  width: auto;
  z-index: 100000;
  background-color: #f1f1f1;
  border-radius: .1875rem;
  padding: .9375rem 1.4375rem .875rem;
  font-size: .875rem;
  font-weight: bold;
  line-height: normal;
  text-decoration: none;
  display: block;
  top: .3125rem;
  left: .3125rem;
  box-shadow: 0 0 .125rem .125rem #0009;
  clip: auto !important;
}

@media (max-width: 35.999em) {
  .alignleft, .alignright {
    float: none;
    margin: 0;
    margin-bottom: 1.5rem;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

.u-bg-primary {
  background-color: var(--c-primary);
}

.u-bg-gray {
  background-color: #fafafa;
}

.u-bg-white {
  background-color: #fff;
}

.u-gray-box {
  --box-size: 1.5rem;
  --background: #fafafa;
  --decoration: block;
  background-color: var(--background);
  border: .0625rem solid #ececec;
  position: relative;
}

.u-gray-box:before {
  content: "";
  display: var(--decoration);
  width: var(--box-size);
  height: var(--box-size);
  border-top: .25rem solid #ececec;
  border-left: .25rem solid #ececec;
  position: absolute;
  top: 0;
  left: 0;
}

.u-gray-box.--bg-white {
  --background: #fff;
}

.u-gray-box.--without-decoration {
  --decoration: none;
}

.u-btn-reset {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  touch-action: manipulation;
  color: inherit;
  font: inherit;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  margin: 0;
  padding: 0;
  line-height: normal;
}

.u-btn-reset:focus, .u-btn-reset:active {
  outline: 0;
  text-decoration: none;
}

.u-btn-reset :focus:not(:focus-visible), .u-btn-reset :focus:not(:-moz-focusring) {
  outline: none;
}

.u-color-primary {
  color: var(--c-primary);
}

.u-color-black {
  color: #292934;
}

.u-cursor-pointer {
  cursor: pointer;
}

.u-fz-12 {
  font-size: .75rem;
}

.u-fz-14 {
  font-size: .875rem;
}

.u-fz-16 {
  font-size: 1rem;
}

.u-fz-18 {
  font-size: 1.125rem;
}

.u-fz-20 {
  font-size: 1.25rem;
}

.u-fz-24 {
  font-size: 1.5rem;
}

.u-fw-400 {
  font-weight: 400;
}

.u-fw-500 {
  font-weight: 500;
}

.u-fw-600 {
  font-weight: 600;
}

.u-fw-700 {
  font-weight: 700;
}

.u-fw-800 {
  font-weight: 800;
}

.u-font-secondary {
  font-family: Noto Serif, serif;
}

.u-img-hover {
  backface-visibility: hidden;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.u-img-hover img {
  transition-property: opacity, transform;
}

.u-img-hover:hover img {
  transform: scale(1.05);
}

.u-top-line {
  --position: 0;
  position: relative;
}

.u-top-line:before {
  content: "";
  left: 50%;
  top: var(--position);
  width: 100vw;
  height: .0625rem;
  background-color: #ececec;
  display: block;
  position: absolute;
  transform: translateX(-50%);
}

.--loading {
  pointer-events: none;
  position: relative;
}

.--loading:before {
  content: "";
  width: 100%;
  height: 100%;
  z-index: 2000;
  opacity: .3;
  background-color: #fafafa;
  transition: opacity .3s;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.--loading:after {
  content: "";
  width: 1.875rem;
  height: 1.875rem;
  border: .25rem solid #fafafa;
  border-bottom-color: var(--c-primary);
  z-index: 2001;
  border-radius: 50%;
  margin-top: -.9375rem;
  margin-left: -.9375rem;
  animation: 1s cubic-bezier(.68, -.55, .27, 1.55) infinite rotation;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

@keyframes rotation {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.u-scrollbar .os-theme-dark.os-scrollbar-vertical {
  width: .125rem;
  border-radius: 0;
  padding: 0;
  transition: width .3s;
}

.u-scrollbar .os-theme-dark.os-scrollbar > .os-scrollbar-track {
  background: #ececec;
  border-radius: 0;
}

.u-scrollbar .os-theme-dark.os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
  background: #a9a9ae;
  border-radius: 0;
}

.u-scrollbar:hover .os-theme-dark.os-scrollbar-vertical {
  width: .375rem;
}

.u-posr {
  position: relative;
}

.u-posa {
  position: absolute;
}

.u-fixed {
  position: fixed;
}

.u-top-50-p {
  top: 50%;
}

.u-left-50-p {
  left: 50%;
}

.u-top-8 {
  top: .5rem;
}

.u-bottom-8 {
  bottom: .5rem;
}

.u-left-8 {
  left: .5rem;
}

.u-right-8 {
  right: .5rem;
}

.u-top-16 {
  top: 1rem;
}

.u-bottom-16 {
  bottom: 1rem;
}

.u-left-16 {
  left: 1rem;
}

.u-right-16 {
  right: 1rem;
}

.u-translate-middle-x {
  transform: translateX(-50%);
}

.u-translate-middle-y {
  transform: translateY(-50%);
}

.u-translate-middle {
  transform: translateX(-50%)translateY(-50%);
}

.u-z-index-10 {
  z-index: 10;
}

.u-z-index-2 {
  z-index: 2;
}

.u-z-index-1 {
  z-index: 1;
}

.u-z-index-0 {
  z-index: 0;
}

.u-z-index-negative {
  z-index: -1;
}

.u-product-image {
  outline: .0625rem solid #ececec;
}

.u-line-through {
  text-decoration: line-through;
}

.u-text-center {
  text-align: center;
}

.u-text-right {
  text-align: right;
}

.u-w-100 {
  width: 100%;
}

.u-mw-100 {
  max-width: 100%;
}

.action-button {
  --size: 2.875rem;
  width: var(--size);
  height: var(--size);
  z-index: 2;
  background-color: #0000;
  justify-content: center;
  align-items: center;
  display: flex;
}

.action-button:before {
  content: "";
  width: var(--size);
  height: var(--size);
  opacity: 0;
  pointer-events: none;
  z-index: -1;
  background-color: #ececec;
  border-radius: 50%;
  transition: opacity .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.action-button:hover:before {
  opacity: 1;
}

.action-button.--size-sm {
  --size: 2.5rem;
}

.action-button.--size-sm .icon {
  --size: 1.25rem;
}

.c-alert {
  --background: #e7f4e4;
  --border: #48a463;
  --opacity: 0;
  --translate: -2rem;
  --color: #48a463;
  background-color: var(--background);
  opacity: var(--opacity);
  transform: translateY(var(--translate));
  will-change: opacity, transform;
  border-style: solid;
  border-width: .0625rem;
  border-color: var(--border);
  color: var(--color);
  transition: opacity .3s, transform .3s;
}

.c-alert.--show {
  --opacity: 1;
  --translate: 0;
}

.c-alert.--success {
  --border: #48a463;
  --background: #e7f4e4;
  --color: #48a463;
}

.c-alert.--error {
  --border: #e21c3d;
  --background: #f9dede;
  --color: #e21c3d;
}

.base-header__paragraph {
  max-width: 37rem;
  margin-bottom: 0;
  margin-inline: auto;
}

.base-header {
  text-align: center;
  max-width: 45rem;
  margin: 0 auto;
  flex-direction: column;
  row-gap: 1rem;
  margin-bottom: 4rem;
  display: flex;
  position: relative;
}

.base-header.--left {
  text-align: left;
  margin-left: 0;
  margin-right: 0;
}

.base-header.--left .base-header__paragraph {
  margin-inline: 0;
}

.base-header.--spacing-bottom-0 {
  margin-bottom: 0;
}

.base-header.--spacing-md {
  --spacing: 2.5rem;
}

.base-heading {
  --font-family: Noto Serif, serif;
  font-family: var(--font-family);
  margin-bottom: 0;
}

.base-heading--h1 {
  font-size: 2.5rem;
}

@media (max-width: 35.999em) {
  .base-heading--h1 {
    font-size: 2rem;
  }
}

.base-heading--h2 {
  font-size: 2.5rem;
}

@media (max-width: 35.999em) {
  .base-heading--h2 {
    font-size: 2rem;
  }
}

.base-heading--h3 {
  font-size: 2rem;
}

@media (max-width: 35.999em) {
  .base-heading--h3 {
    font-size: 1.5rem;
  }
}

.base-heading--h4 {
  font-size: 1.5rem;
}

@media (max-width: 35.999em) {
  .base-heading--h4 {
    font-size: 1.125rem;
  }
}

.base-heading--h5 {
  font-size: 1.125rem;
}

@media (max-width: 35.999em) {
  .base-heading--h5 {
    font-size: 1rem;
  }
}

.base-section {
  --padding-y: 3.5rem;
  padding-top: var(--padding-y);
  padding-bottom: var(--padding-y);
}

@media (max-width: 35.999em) {
  .base-section {
    --padding-y: 3rem;
  }
}

.base-section.--p-top-0 {
  padding-top: 0;
}

.base-section.--p-bottom-0 {
  padding-bottom: 0;
}

.base-section.--p-y-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.base-subheading {
  text-transform: uppercase;
  font-size: 1rem;
}

.base-subheading.--orange {
  color: #ebab80;
}

.base-subheading.--gray {
  color: #a9a9ae;
}

.b-cart__header {
  padding: 1.5rem 2rem;
}

.b-cart__body-header {
  border-top: .0625rem solid #ececec;
  border-bottom: .0625rem solid #ececec;
  grid-template-columns: 22.75rem 1fr 1fr 2.5rem 1fr 2.875rem;
  gap: 1.5rem;
  padding: 1.5rem 2rem;
  display: grid;
}

@media (max-width: 61.999em) {
  .b-cart__body-header {
    display: none;
  }
}

@media (min-width: 62em) and (max-width: 74.999em) {
  .b-cart__body-header {
    gap: 1rem;
  }
}

@media (max-width: 61.999em) {
  .b-cart__body {
    gap: 2rem;
  }
}

.b-login, .b-password-new, .b-password-reset, .b-register {
  max-width: 35.9375rem;
  margin-inline: auto;
}

.b-search {
  opacity: 0;
  pointer-events: auto;
  transition: opacity .3s;
  box-shadow: 0 0 2.5rem #2929340d;
}

.b-search.--show {
  opacity: 1;
  pointer-events: auto;
}

.btn {
  --direction-offset: .5rem;
}

.btn.--size-xs {
  --direction-offset: .25rem;
}

@media (hover: hover) {
  .btn.--animation-right:hover svg {
    transform: translateX(var(--direction-offset));
    transition-timing-function: cubic-bezier(.68, -.55, .27, 1.55);
  }

  .btn.--animation-left:hover svg {
    transform: translateX(calc(-1 * var(--direction-offset)));
    transition-timing-function: cubic-bezier(.68, -.55, .27, 1.55);
  }

  .btn.--animation-zoom:hover svg {
    transform: scale(1.1);
  }

  .btn.--animation-down:hover svg {
    transform: translateY(var(--direction-offset));
  }
}

.btn.--primary {
  --bg-color: var(--c-primary);
  --font-color: #fff;
  color: var(--font-color);
}

.btn.--primary .btn__body {
  background-color: var(--bg-color);
  -webkit-clip-path: polygon(0 3rem, 0 1.5rem, 1.5rem 0, 100% 0, 100% 100%, 0 100%, 0 3rem, 1.5rem 1rem);
  clip-path: polygon(0 3rem, 0 1.5rem, 1.5rem 0, 100% 0, 100% 100%, 0 100%, 0 3rem, 1.5rem 1rem);
  transition: -webkit-clip-path .3s cubic-bezier(.68, -.55, .27, 1.55), clip-path .3s cubic-bezier(.68, -.55, .27, 1.55);
}

.btn.--primary:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 1.5625rem 1.5625rem 0 0;
  border-color: var(--bg-color) transparent transparent;
  transition: transform .3s cubic-bezier(.68, -.55, .27, 1.55);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.btn.--primary path {
  fill: var(--font-color);
}

@media (hover: hover) {
  .btn.--primary:not(.--disabled):hover {
    color: var(--font-color);
  }

  .btn.--primary:not(.--disabled):hover .btn__body {
    -webkit-clip-path: polygon(0 2.625rem, 0 1.5rem, 1.5rem 0, 100% 0, 100% 100%, 0 100%, 0 3rem, 1.5rem 1rem);
    clip-path: polygon(0 2.625rem, 0 1.5rem, 1.5rem 0, 100% 0, 100% 100%, 0 100%, 0 3rem, 1.5rem 1rem);
  }

  .btn.--primary:not(.--disabled):hover:after {
    transform: translateX(-.375rem)rotate(-4deg);
  }
}

.btn.--black {
  --bg-color: #292934;
  --font-color: #fff;
  color: var(--font-color);
}

.btn.--black .btn__body {
  background-color: var(--bg-color);
  -webkit-clip-path: polygon(0 3rem, 0 1.5rem, 1.5rem 0, 100% 0, 100% 100%, 0 100%, 0 3rem, 1.5rem 1rem);
  clip-path: polygon(0 3rem, 0 1.5rem, 1.5rem 0, 100% 0, 100% 100%, 0 100%, 0 3rem, 1.5rem 1rem);
  transition: -webkit-clip-path .3s cubic-bezier(.68, -.55, .27, 1.55), clip-path .3s cubic-bezier(.68, -.55, .27, 1.55);
}

.btn.--black:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 1.5625rem 1.5625rem 0 0;
  border-color: var(--bg-color) transparent transparent;
  transition: transform .3s cubic-bezier(.68, -.55, .27, 1.55);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.btn.--black path {
  fill: var(--font-color);
}

@media (hover: hover) {
  .btn.--black:not(.--disabled):hover {
    color: var(--font-color);
  }

  .btn.--black:not(.--disabled):hover .btn__body {
    -webkit-clip-path: polygon(0 2.625rem, 0 1.5rem, 1.5rem 0, 100% 0, 100% 100%, 0 100%, 0 3rem, 1.5rem 1rem);
    clip-path: polygon(0 2.625rem, 0 1.5rem, 1.5rem 0, 100% 0, 100% 100%, 0 100%, 0 3rem, 1.5rem 1rem);
  }

  .btn.--black:not(.--disabled):hover:after {
    transform: translateX(-.375rem)rotate(-4deg);
  }
}

.btn.--outline {
  color: #292934;
  background-color: #fff;
  border: none;
  font-weight: 700;
  box-shadow: 0 0 0 .0625rem #ececec;
}

.btn.--outline:hover {
  color: #292934;
  background-color: #fafafa;
}

.btn.--outline:active, .btn.--outline:focus {
  color: #292934;
}

.btn.--outline path {
  fill: #292934;
}

.btn__icon {
  justify-content: center;
  align-items: center;
  display: flex;
}

.btn__icon .icon svg {
  height: 1rem;
  width: 1rem;
  transition: transform .3s;
}

.btn__body {
  min-height: 4rem;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  column-gap: .5rem;
  padding: 1.25rem 2rem;
  display: flex;
}

.btn {
  --display: inline-flex;
  -webkit-user-select: none;
  user-select: none;
  display: var(--display);
  color: #fff;
  z-index: 1;
  touch-action: manipulation;
  cursor: pointer;
  border: none;
  border-radius: 0;
  margin: 0;
  padding: 0;
  font-family: Noto Sans, sans-serif;
  text-decoration: none;
  transition: background-color .3s ease-out;
  position: relative;
}

.btn:hover {
  color: #fff;
  text-decoration: none;
}

.btn:focus, .btn:active {
  color: #fff;
  outline: 0;
  text-decoration: none;
}

.btn:before {
  --size: .5rem;
  content: "";
  width: var(--size);
  height: var(--size);
  top: calc(var(--size) / 2);
  right: calc(var(--size) / 2);
  border-radius: calc(var(--size) / 2);
  pointer-events: none;
  background-color: #0066b3;
  transition: transform .3s cubic-bezier(.68, -.55, .27, 1.55);
  display: block;
  position: absolute;
  transform: scale(0);
}

.btn.--size-sm .btn__body {
  min-height: 3rem;
  max-width: 100%;
  padding: .75rem 2rem;
}

.btn.--size-xs .btn__body {
  min-width: auto;
  min-height: 2rem;
  max-width: 100%;
  padding: .5rem 1rem;
}

.btn.--disabled {
  opacity: .5;
  cursor: not-allowed;
}

.btn.--icon-before .btn__icon {
  order: -1;
}

.btn.--active:before {
  transform: scale(1);
}

@media (max-width: 35.999em) {
  .btn.--full-width-mobile {
    width: 100%;
  }
}

.btn.--full-width {
  width: 100%;
}

.btn.--full-width .btn__body {
  min-width: auto;
  padding: 0;
}

.btn.--icon-only {
  --display: flex;
  width: 4rem;
  height: 4rem;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.btn.--icon-only .btn__body {
  min-width: auto;
  padding: 0;
}

.btn.--icon-only .btn__title {
  display: none;
}

.btn.--icon-only .btn__icon svg {
  width: 1.5rem;
  height: 1.5rem;
}

.btn.--icon-only.--size-sm {
  width: 3rem;
  height: 3rem;
}

.btn.--icon-only.--size-sm .btn__body {
  width: 3rem;
  height: 3rem;
  flex-basis: 3rem;
}

.btn.--icon-only.--size-sm .btn__icon svg {
  width: .875rem;
  height: .875rem;
}

.btn.--icon-only.--size-xs {
  width: 2rem;
  height: 2rem;
}

.btn.--icon-only.--size-xs .btn__icon svg {
  width: .875rem;
  height: .875rem;
}

.btn.--hidden {
  --display: none;
}

@media (min-width: 62em) {
  .content-w-aside__content {
    grid-column: 1 / span 9;
  }
}

@media (min-width: 62em) and (max-width: 74.999em) {
  .content-w-aside__content {
    grid-column: 1 / span 8;
  }
}

.content-w-aside__row {
  gap: 2rem;
}

@media (min-width: 62em) {
  .content-w-aside__row {
    grid-template-columns: repeat(12, 1fr);
    justify-content: center;
    display: grid;
  }
}

@media (max-width: 61.999em) {
  .content-w-aside__row {
    flex-direction: column;
    display: flex;
  }
}

.content-w-aside__row > .content-w-aside__content:last-child {
  grid-column: 3 / span 8;
}

.content-w-aside__aside {
  --padding-top: 3.5rem;
  padding-top: var(--padding-top);
}

@media (max-width: 61.999em) {
  .content-w-aside__aside {
    --padding-top: 0;
  }
}

@media (min-width: 62em) {
  .content-w-aside__aside {
    grid-column: span 3 / -1;
  }
}

@media (min-width: 62em) and (max-width: 74.999em) {
  .content-w-aside__aside {
    grid-column: span 4 / -1;
  }
}

.c-counter {
  --width: 9.5rem;
  min-height: 4rem;
  width: var(--width);
  -webkit-user-select: none;
  user-select: none;
  border: .0625rem solid #ececec;
  transition: background-color .3s;
}

.c-counter:hover {
  background-color: #fafafa;
}

.c-counter__value-visual {
  gap: .25rem;
}

.c-counter__button {
  width: 1.5rem;
  height: 1.5rem;
  z-index: 2;
  cursor: pointer;
}

.c-counter__button > svg {
  z-index: 2;
}

.c-counter__button:before {
  content: "";
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: #ececec;
  border-radius: 50%;
  transition: opacity .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.c-counter__button:hover:before {
  opacity: 1;
}

.main-decoration {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
}

.main-decoration.--left {
  inset: auto auto -3.5rem 0;
  transform: scale(-1);
}

.d-side-stripes {
  --offset: .5rem;
  --offset-negative: calc(-1 * var(--offset));
  pointer-events: none;
  position: absolute;
  top: 50%;
}

.d-side-stripes.--left {
  transform: translateY(-50%) translateX(var(--offset-negative));
  right: 100%;
}

.d-side-stripes.--right {
  transform: translateY(-50%) translateX(var(--offset)) scale(-1);
  left: 100%;
}

.f-checkbox__visual {
  width: 1rem;
  height: 1rem;
  background-color: #fff;
  border: .0625rem solid #292934;
  justify-content: center;
  align-items: center;
  transition: background-color .3s;
  display: flex;
}

.f-checkbox__visual svg {
  opacity: 0;
  transition: opacity .3s, transform .3s;
  transform: scale(.2);
}

.f-checkbox__input {
  display: none;
}

.f-checkbox__input:checked ~ .f-checkbox__visual {
  background-color: #292934;
}

.f-checkbox__input:checked ~ .f-checkbox__visual svg {
  opacity: 1;
  transform: scale(1);
}

.f-checkbox {
  cursor: pointer;
  align-items: center;
  gap: .5rem;
  display: inline-flex;
}

.f-checkbox__title a {
  color: #292934;
  font-weight: 700;
}

.f-error {
  --display: none;
  color: #f93a3a;
  width: 100%;
  display: var(--display);
  z-index: 2;
  gap: .25rem;
  font-size: .75rem;
  position: absolute;
  top: calc(100% + .25rem);
  left: 0;
}

.f-error.--show {
  --display: flex;
}

.f-base {
  --font-size: .875rem;
  width: 100%;
  color: #292934;
  font-size: var(--font-size);
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-radius: 0;
  padding: 1.6875rem 1rem .5rem;
  display: block;
}

.f-base[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
  height: .625rem;
  width: .625rem;
  cursor: pointer;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE2LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgd2lkdGg9IjEyMy4wNXB4IiBoZWlnaHQ9IjEyMy4wNXB4IiB2aWV3Qm94PSIwIDAgMTIzLjA1IDEyMy4wNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTIzLjA1IDEyMy4wNTsiDQoJIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPHBhdGggZD0iTTEyMS4zMjUsMTAuOTI1bC04LjUtOC4zOTljLTIuMy0yLjMtNi4xLTIuMy04LjUsMGwtNDIuNCw0Mi4zOTlMMTguNzI2LDEuNzI2Yy0yLjMwMS0yLjMwMS02LjEwMS0yLjMwMS04LjUsMGwtOC41LDguNQ0KCQljLTIuMzAxLDIuMy0yLjMwMSw2LjEsMCw4LjVsNDMuMSw0My4xbC00Mi4zLDQyLjVjLTIuMywyLjMtMi4zLDYuMSwwLDguNWw4LjUsOC41YzIuMywyLjMsNi4xLDIuMyw4LjUsMGw0Mi4zOTktNDIuNGw0Mi40LDQyLjQNCgkJYzIuMywyLjMsNi4xLDIuMyw4LjUsMGw4LjUtOC41YzIuMy0yLjMsMi4zLTYuMSwwLTguNWwtNDIuNS00Mi40bDQyLjQtNDIuMzk5QzEyMy42MjUsMTcuMTI1LDEyMy42MjUsMTMuMzI1LDEyMS4zMjUsMTAuOTI1eiIvPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=");
  background-size: .625rem .625rem;
  position: relative;
  right: -1rem;
}

@media (max-width: 35.999em) {
  .f-base {
    --font-size: 1rem;
  }
}

.f-base:focus {
  color: #292934;
  border-color: none;
  outline: none;
}

.f-base::placeholder {
  opacity: 1;
  color: #292934;
  font-weight: 600;
}

textarea.f-base {
  min-height: 13.5rem;
}

.f-input.f-header-search-bar__input {
  border-color: #0000;
  position: relative;
}

.f-input.f-header-search-bar__input:after {
  content: "";
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  transition: opacity .5s, box-shadow .5s;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 .625rem 1.5rem #fff;
}

.f-input.f-header-search-bar__input:active:after {
  opacity: 1;
  transition: all;
  position: absolute;
  box-shadow: 0 0 #fff;
}

.f-input.f-header-search-bar__input .f-input__input.f-base {
  min-height: 3rem;
  background-color: #ececec;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 4.5rem;
}

@media (max-width: 35.999em) {
  .f-input.f-header-search-bar__input .f-input__input.f-base::placeholder {
    font-size: .75rem;
  }
}

button.f-input__icon {
  transition: background-color .3s;
}

button.f-input__icon:hover {
  background-color: #a9a9ae;
}

.f-header-search-bar__button {
  height: 100%;
  width: 3.5rem;
  z-index: 2;
  background-color: #0000;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.f-header-search-bar__button:before {
  content: "";
  width: 2.875rem;
  height: 2.875rem;
  pointer-events: none;
  opacity: 0;
  z-index: -1;
  background-color: #a9a9ae;
  border-radius: 50%;
  transition: opacity .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.f-header-search-bar__button:hover:before {
  opacity: 1;
}

span.f-input__icon {
  pointer-events: none;
}

.f-input__icon {
  height: 100%;
  background-color: #0000;
  border: none;
  align-items: center;
  padding: 1rem;
  display: flex;
  position: absolute;
}

.f-input__icon.--before {
  padding-left: 1rem;
  top: 50%;
  left: 0;
  transform: translateX(0)translateY(-50%);
}

.f-input__icon.--after {
  padding-right: 1rem;
  top: 50%;
  right: 0;
  transform: translateX(0)translateY(-50%);
}

.f-input__input {
  font-weight: 700;
  transition: background-color .3s, border-color .3s;
}

.f-input {
  border: .0625rem solid #ececec;
  border-radius: 0;
  align-items: center;
  transition: border-color .3s;
  display: flex;
  position: relative;
}

.f-input:hover .f-input__input {
  background-color: #fafafa;
}

.f-input.--invalid {
  border-color: #f93a3a;
}

.f-input.--active .f-input__input {
  background-color: #fafafa;
}

.f-input.--icon-after .f-input__input {
  padding-right: 3rem;
}

.f-label {
  color: #292934;
  z-index: 1;
  pointer-events: none;
  will-change: transform;
  transform-origin: 0;
  align-items: center;
  margin-bottom: 0;
  padding: 0;
  font-size: .875rem;
  font-weight: 700;
  transition: transform .3s;
  display: flex;
  position: absolute;
  top: 1.25rem;
  left: 1.125rem;
}

.f-label.--required:after {
  content: "*";
  color: #f93a3a;
  padding-left: .25rem;
}

.f-label.--active {
  transform: translateY(-1rem)scale(.9);
}

.f-radio-box__image {
  width: 4.5rem;
  height: 3rem;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (max-width: 35.999em) {
  .f-radio-box__image {
    width: 2.5rem;
    height: 1.5rem;
  }
}

.f-radio-box__visual {
  width: 1rem;
  height: 1rem;
  border: .0625rem solid #292934;
  border-radius: .5rem;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.f-radio-box__visual:before {
  content: "";
  width: .625rem;
  height: .625rem;
  opacity: 0;
  background-color: #292934;
  border-radius: .3125rem;
  transition: opacity .3s, transform .3s;
  display: block;
  top: 50%;
  left: 50%;
  transform: scale(.5);
}

.f-radio-box {
  cursor: pointer;
  transition: background-color .3s;
}

.f-radio-box__body {
  background-color: #fff;
}

.f-radio-box__body:before {
  content: "";
  height: 100%;
  width: .1875rem;
  background-color: var(--c-primary);
  transform-origin: center;
  transition: opacity .3s, transform .3s;
  display: block;
  position: absolute;
  top: 0;
  left: -.5rem;
  transform: scaleY(0);
}

.f-radio-box__body:hover {
  background-color: #fafafa;
}

@media (min-width: 62em) {
  .f-radio-box__body {
    padding-right: 1rem;
  }
}

@media (max-width: 35.999em) {
  .f-radio-box__body {
    flex-wrap: wrap;
    row-gap: .5rem;
  }
}

.f-radio-box__input {
  display: none;
}

.f-radio-box__input:checked ~ .f-radio-box__body:before {
  transform: scaleY(1);
}

.f-radio-box__input:checked ~ .f-radio-box__body .f-radio-box__visual:before {
  opacity: 1;
  transform: scale(1);
}

.f-radio-box__description a {
  color: #292934;
  font-weight: 700;
}

@media (max-width: 35.999em) {
  .f-radio-box__description {
    font-size: .75rem;
  }

  .f-radio-box__title {
    font-size: .875rem;
  }

  .f-radio-box__content-col {
    width: 100%;
    flex-grow: 1;
    order: 3;
  }
}

.f-textarea__input::-webkit-resizer {
  display: none;
}

.f-textarea {
  border: .0625rem solid #ececec;
  border-radius: 0;
  align-items: center;
  display: flex;
  position: relative;
}

.f-textarea.--no-resize .f-textarea__input {
  resize: none;
}

.f-textarea.--invalid {
  border-color: #f93a3a;
}

.f-textarea__resize {
  width: 1.625rem;
  height: 1.5rem;
  pointer-events: none;
  background-color: #fff;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
}

.f-textarea__resize svg {
  width: 1.125rem;
  height: 1rem;
}

.f-contact {
  border-radius: .25rem;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
  display: flex;
  box-shadow: inset 0 0 0 .0625rem #ececec;
}

.f-contact__wrapper {
  gap: 2rem;
  display: grid;
}

@media (min-width: 48em) {
  .f-contact__wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

.f-contact__bottom {
  justify-content: space-between;
  gap: 2rem;
  display: flex;
}

@media (max-width: 35.999em) {
  .f-contact__bottom {
    flex-direction: column;
    align-items: flex-end;
  }
}

@media (min-width: 36em) {
  .f-discount__button {
    position: absolute;
    top: 50%;
    right: .5rem;
    transform: translateY(-50%);
  }
}

@media (max-width: 35.999em) {
  .f-discount__button {
    position: relative;
  }

  .f-discount__button.--size-sm .btn__body {
    min-height: 4rem;
  }
}

.f-discount__input-wrap {
  max-width: 35.5rem;
  width: 100%;
  flex-grow: 1;
}

.f-discount__input-wrap label {
  top: 1.5rem;
}

.f-discount__input-wrap input {
  padding: 2.1875rem 1rem .5rem;
}

@media (max-width: 35.999em) {
  .f-discount__input-wrap {
    flex-direction: column;
    gap: .5rem;
    display: flex;
  }
}

.f-discount__stripe {
  margin-right: -.5rem;
}

.f-discount {
  flex-grow: 1;
}

@media (max-width: 61.999em) {
  .f-discount {
    flex-direction: column;
    row-gap: 1rem;
  }
}

.f-question__grid {
  flex-direction: column;
  gap: 1.5rem 2rem;
  display: flex;
}

@media (min-width: 62em) {
  .f-question__grid {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }
}

.f-question__cell {
  flex-direction: column;
  gap: 1.5rem;
  display: flex;
}

@media (min-width: 62em) {
  .f-question__button {
    max-width: 50%;
    margin-left: auto;
    padding-left: 1rem;
  }
}

.f-recommend__grid {
  flex-direction: column;
  gap: 1.5rem 2rem;
  display: flex;
}

@media (min-width: 62em) {
  .f-recommend__grid {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }
}

.f-recommend__cell {
  flex-direction: column;
  gap: 1.5rem;
  display: flex;
}

@media (min-width: 62em) {
  .f-recommend__button {
    max-width: 50%;
    margin-left: auto;
    padding-left: 1rem;
  }
}

.f-register__description p {
  margin-bottom: 0;
}

.g-base {
  gap: var(--gap);
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.g-base.--no-wrap {
  flex-wrap: nowrap;
}

.g-base.--direction-column {
  align-items: initial;
  flex-direction: column;
}

.g-base.--gap-xs {
  --gap: .25rem;
}

.g-base.--gap-sm {
  --gap: .5rem;
}

.g-base.--gap-md {
  --gap: 1rem;
}

.g-base.--gap-lg {
  --gap: 1.5rem;
}

.g-base.--gap-xl {
  --gap: 2rem;
}

.g-base.--align-left {
  justify-content: flex-start;
}

.g-base.--align-right {
  justify-content: flex-end;
}

.g-base.--align-center {
  justify-content: center;
}

.g-base.--align-between {
  justify-content: space-between;
}

.g-branch-modal__header {
  grid-template-columns: 22.5rem 15.5rem 1fr 1fr;
  gap: 1rem;
  display: grid;
}

@media (max-width: 61.999em) {
  .g-branch-modal__header {
    display: none;
  }
}

.g-branch-modal__header:after {
  content: "";
  width: calc(100% + 3rem);
  height: .0625rem;
  background-color: #ececec;
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.g-breadcrumbs__list {
  align-items: center;
  gap: 1.5rem;
  display: flex;
}

@media (max-width: 47.999em) {
  .g-breadcrumbs__list {
    display: none;
  }
}

.g-buttons {
  margin-top: 3rem;
}

.g-cart-steps {
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
}

@media (max-width: 61.999em) {
  .g-cart-steps {
    gap: .5rem;
  }
}

@media (max-width: 35.999em) {
  .g-cart-steps {
    justify-content: center;
  }
}

.g-cart-steps__divider {
  width: 100%;
  height: .0625rem;
  background-color: #ececec;
  flex-grow: 1;
}

@media (max-width: 74.999em) {
  .g-cart-steps__divider {
    display: none;
  }
}

@media (max-width: 35.999em) {
  .g-company-fieldset__row {
    --bs-gutter-y: 1.5rem;
  }
}

.g-contact-group {
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  display: grid;
}

@media (max-width: 47.999em) {
  .g-contact-group {
    grid-template-columns: 1fr;
  }
}

.g-contact-group__left {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;
  margin-right: 2rem;
  display: flex;
}

@media (max-width: 47.999em) {
  .g-contact-group__left {
    gap: 1rem;
  }
}

.g-contact-group__socials {
  gap: 2rem;
  display: flex;
}

.g-contact-group__right {
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  display: flex;
}

@media (max-width: 35.999em) {
  .g-different-address-fieldset__row {
    --bs-gutter-y: 1.5rem;
  }
}

.g-filters {
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  display: grid;
}

@media (max-width: 61.999em) {
  .g-filters {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 35.999em) {
  .g-filters {
    flex-direction: column;
    display: flex;
  }
}

.g-gifts {
  gap: .5rem;
  display: grid;
}

@media (min-width: 48em) {
  .g-gifts {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 75em) {
  .g-gifts {
    grid-template-columns: repeat(3, 1fr);
  }
}

.g-grader {
  --swiper-scrollbar-drag-bg-color: #292934;
  --swiper-scrollbar-size: .5rem;
  --swiper-scrollbar-border-radius: 0;
  --swiper-scrollbar-bottom: -2.5rem;
  width: 100%;
  grid-template-columns: minmax(0, 3fr) minmax(0, 9fr);
  display: none;
}

@media (max-width: 35.999em) {
  .g-grader {
    display: none;
  }
}

.g-grader.--active {
  display: grid;
}

@media (max-width: 35.999em) {
  .g-grader.--active {
    display: block;
  }
}

.g-grader__label {
  border-top: .0625rem solid #ececec;
}

.g-grader__label:first-of-type {
  border-top: none;
}

.g-grader__header {
  border-right: none;
}

@media (max-width: 35.999em) {
  .g-grader__header {
    display: none;
  }
}

.g-grader-slider {
  --swiper-scrollbar-drag-bg-color: #292934;
  --swiper-scrollbar-size: .5rem;
  --swiper-scrollbar-border-radius: 0;
  --swiper-scrollbar-bottom: -2.5rem;
  position: relative;
}

.g-grader-slider .swiper-wrapper {
  display: flex;
}

.g-grader-slider .swiper-slide {
  backface-visibility: hidden;
  height: auto;
  flex-shrink: 0;
  transform: translate3d(0, 0, 0);
}

.g-grader-slider__progress.swiper-progress {
  height: .5rem;
  background-color: #a9a9ae;
  position: relative;
}

.g-grader-slider__scrollbar {
  cursor: grab;
  transform-origin: bottom;
  transform: scaleY(.5);
}

.g-grader-slider__scrollbar.swiper-scrollbar.swiper-scrollbar-horizontal {
  height: .5rem;
  width: 100%;
  background-color: #a9a9ae;
  left: 0;
}

.g-grader-slider__scrollbar:hover, .g-grader-slider__scrollbar:focus, .g-grader-slider__scrollbar:active {
  transform: scaleY(1);
}

.g-grader-slider__scrollbar:active, .g-grader-slider__scrollbar:focus {
  cursor: grabbing;
}

.g-grader-slider__buttons {
  --buttons-offset: -2rem;
}

@media (max-width: 35.999em) {
  .g-grader-slider__buttons {
    --buttons-offset: -1rem;
  }
}

@media (max-width: 22.438em) {
  .g-grader-slider__buttons {
    --buttons-offset: -.5rem;
  }
}

.g-grader-slider__buttons .swiper-button {
  z-index: 1;
  pointer-events: auto;
  transition: opacity .3s;
  position: absolute;
  top: 12.25rem;
}

.g-grader-slider__buttons .swiper-button:after {
  content: "";
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity .5s, box-shadow .5s;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 .625rem 2.5rem #fff;
}

.g-grader-slider__buttons .swiper-button:active:after {
  opacity: 1;
  transition: all;
  position: absolute;
  box-shadow: 0 0 #fff;
}

.g-grader-slider__buttons .swiper-button-next {
  right: var(--buttons-offset);
}

.g-grader-slider__buttons .swiper-button-prev {
  left: var(--buttons-offset);
}

.g-grader-slider__buttons .swiper-button-disabled {
  opacity: 0;
  pointer-events: none;
}

.g-grader-tab {
  overflow-x: auto;
}

.g-grader-tab:after {
  content: "";
  height: .0625rem;
  width: 100%;
  z-index: -1;
  background-color: #ececec;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.g-hero-slider {
  --swiper-theme-color: #292934;
  overflow: hidden;
}

.g-hero-slider .swiper-fraction__current {
  color: #292934;
}

.g-hero-slider .swiper-wrapper {
  display: flex;
}

.g-hero-slider .swiper-slide {
  backface-visibility: hidden;
  flex-shrink: 0;
  transform: translate3d(0, 0, 0);
  width: 100% !important;
}

.g-hero-slider .swiper-pagination-progressbar {
  height: .5rem;
  max-width: 8rem;
  background-color: #a9a9ae;
  position: relative;
}

.g-hero-slider .swiper-fraction {
  color: #a9a9ae;
  gap: .25rem;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
}

.g-hero-slider .swiper-fraction__divider:before {
  content: "/";
}

.g-hero-slider .swiper-button {
  transition-property: opacity, box-shadow;
}

.g-hero-slider .swiper-button.swiper-button-disabled {
  box-shadow: none;
  opacity: .5;
  cursor: not-allowed;
}

.g-hero-slider__navigation {
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  margin-top: 1.5rem;
  display: flex;
}

@media (max-width: 61.999em) {
  .g-hero-slider__navigation {
    justify-content: center;
  }
}

.g-hero-slider__buttons {
  gap: .0625rem;
  padding: .0625rem;
  display: flex;
}

@media (min-width: 62em) {
  .g-information {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }
}

.g-load-more {
  grid-template-columns: repeat(3, 1fr);
  margin-top: 4rem;
  display: grid;
}

@media (min-width: 75em) and (max-width: 93.749em) {
  .g-load-more {
    flex-direction: column;
    row-gap: 2rem;
    display: flex;
  }
}

@media (max-width: 61.999em) {
  .g-load-more {
    flex-direction: column;
    row-gap: 2rem;
    display: flex;
  }
}

.g-load-more__cell:first-child {
  grid-column-start: 2;
}

@media (max-width: 35.999em) {
  .g-load-more__cell:first-child {
    width: 100%;
  }
}

.g-opening-hours__icon {
  padding-top: .25rem;
}

.g-opening-hours__content {
  flex-wrap: wrap;
}

.g-orders {
  gap: .0625rem;
}

.g-overview {
  gap: .125rem;
  display: flex;
}

@media (max-width: 35.999em) {
  .g-personal-data__row {
    --bs-gutter-y: 1.5rem;
  }
}

.g-posts-grid {
  display: block;
}

.g-posts-grid .g-posts-grid__cell:nth-child(2) {
  grid-template-columns: repeat(4, 1fr);
  gap: .0625rem;
  display: grid;
}

@media (max-width: 61.999em) {
  .g-posts-grid .g-posts-grid__cell:nth-child(2) {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 47.999em) {
  .g-posts-grid .g-posts-grid__cell:nth-child(2) {
    flex-direction: column;
    display: flex;
  }
}

.g-posts-list {
  gap: .0625rem;
}

.g-products {
  grid-template-columns: repeat(3, 1fr);
  gap: .0625rem;
  display: grid;
}

@media (min-width: 75em) and (max-width: 93.749em) {
  .g-products {
    max-width: 43rem;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 62em) and (max-width: 74.999em) {
  .g-products {
    max-width: none;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 61.999em) {
  .g-products {
    max-width: 43rem;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 47.999em) {
  .g-products {
    max-width: 21.5rem;
    grid-template-columns: 1fr;
    margin-inline: auto;
  }
}

.g-products-slider {
  overflow: hidden;
}

.g-products-slider.u-top-line {
  --position: -.0625rem;
}

.g-products-slider .swiper-wrapper {
  display: flex;
}

.g-products-slider.swiper {
  overflow: initial;
  position: relative;
}

.g-products-slider .swiper-slide {
  height: auto;
  flex-shrink: 0;
}

.g-products-slider.swiper-initialized .swiper-slide {
  opacity: .8;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  transition: opacity .3s, filter .3s;
}

@media (min-width: 48em) {
  .g-products-slider.swiper-initialized .swiper-slide {
    filter: blur(.25rem);
  }
}

.g-products-slider.swiper-initialized .swiper-slide-visible {
  opacity: 1;
  filter: blur();
  pointer-events: auto;
  -webkit-user-select: text;
  user-select: text;
}

@media (max-width: 47.999em) {
  .g-products-slider {
    max-width: 21.5rem;
  }
}

.g-products-slider .swiper-button {
  z-index: 1;
  pointer-events: auto;
  transition: opacity .3s;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.g-products-slider .swiper-button:after {
  content: "";
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity .5s, box-shadow .5s;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 .625rem 2.5rem #fff;
}

.g-products-slider .swiper-button:active:after {
  opacity: 1;
  transition: all;
  position: absolute;
  box-shadow: 0 0 #fff;
}

.g-products-slider .swiper-button-next {
  margin-right: 1rem;
  right: 0;
}

.g-products-slider .swiper-button-prev {
  margin-left: 1rem;
  left: 0;
}

.g-products-slider .swiper-button-disabled {
  opacity: 0;
  pointer-events: none;
}

.g-products-slider .swiper-buttons {
  pointer-events: none;
  width: 100vw;
  height: 100%;
  max-width: 100rem;
  z-index: 1;
  margin-inline: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.g-radio-boxes {
  background-color: #fff;
  outline: .0625rem solid #ececec;
}

.g-rotate-slider {
  height: 100%;
  max-width: 41.875rem;
  max-height: 41.875rem;
  aspect-ratio: 1 / 1;
}

.g-rotate-slider__canvas {
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  display: block;
}

.g-search-suggest {
  --opacity: 1;
  opacity: var(--opacity);
  top: 100%;
}

.g-selected-filters {
  flex-grow: 1;
}

@media (min-width: 36em) {
  .g-selected-filters {
    min-height: 1.5rem;
  }
}

.g-set-products {
  flex-direction: column;
  gap: .0625rem;
  display: flex;
}

@media (min-width: 36em) {
  .g-set-products {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }
}

.g-sets {
  grid-template-columns: repeat(2, 1fr);
  gap: .0625rem;
  display: grid;
}

@media (max-width: 47.999em) {
  .g-sets {
    max-width: 32.3125rem;
    flex-direction: column;
    margin-inline: auto;
    display: flex;
  }
}

.g-sets-slider {
  overflow: hidden;
}

.g-sets-slider.u-top-line {
  --position: -.0625rem;
}

.g-sets-slider .swiper-wrapper {
  display: flex;
}

.g-sets-slider .swiper-slide {
  height: auto;
  flex-shrink: 0;
}

.g-sets-slider.swiper {
  overflow: initial;
  position: relative;
}

.g-sets-slider.swiper-initialized .swiper-slide {
  opacity: .8;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  transition: opacity .3s, filter .3s;
}

@media (min-width: 48em) {
  .g-sets-slider.swiper-initialized .swiper-slide {
    filter: blur(.25rem);
  }
}

.g-sets-slider.swiper-initialized .swiper-slide-visible {
  opacity: 1;
  filter: blur();
  pointer-events: auto;
  -webkit-user-select: text;
  user-select: text;
}

@media (max-width: 47.999em) {
  .g-sets-slider {
    max-width: 32.3125rem;
  }
}

.g-sets-slider .swiper-button {
  z-index: 1;
  pointer-events: auto;
  transition: opacity .3s;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.g-sets-slider .swiper-button:after {
  content: "";
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity .5s, box-shadow .5s;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 .625rem 2.5rem #fff;
}

.g-sets-slider .swiper-button:active:after {
  opacity: 1;
  transition: all;
  position: absolute;
  box-shadow: 0 0 #fff;
}

.g-sets-slider .swiper-button-next {
  margin-right: 1rem;
  right: 0;
}

.g-sets-slider .swiper-button-prev {
  margin-left: 1rem;
  left: 0;
}

.g-sets-slider .swiper-button-disabled {
  opacity: 0;
  pointer-events: none;
}

.g-sets-slider .swiper-buttons {
  pointer-events: none;
  width: 100vw;
  height: 100%;
  max-width: 100rem;
  z-index: 1;
  margin-inline: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.g-showroom-gallery {
  grid-template-columns: repeat(2, 1fr);
  gap: .0625rem;
  display: grid;
}

@media (min-width: 48em) {
  .g-showroom-gallery {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 62em) {
  .g-showroom-gallery {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 75em) {
  .g-showroom-gallery {
    grid-template-columns: repeat(6, 1fr);
  }
}

.g-usps {
  gap: .0625rem;
  display: grid;
  position: relative;
}

@media (min-width: 36em) {
  .g-usps {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 62em) {
  .g-usps {
    grid-template-columns: repeat(4, 1fr);
  }
}

.g-usps:before {
  content: "";
  width: 1.5rem;
  height: 1.5rem;
  border-top: .25rem solid #ececec;
  border-left: .25rem solid #ececec;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.g-usps__cell {
  box-shadow: 0 0 0 .0625rem #ececec;
}

.icon {
  --size: 1.5rem;
  flex: 0 0 var(--size);
  max-width: var(--size);
  height: var(--size);
  justify-content: center;
  align-items: center;
  display: flex;
}

.icon svg {
  width: 100%;
  height: 100%;
}

.icon.--colored {
  color: var(--color);
}

.icon.--colored path {
  fill: var(--color);
}

.icon.--primary {
  --color: var(--c-primary);
}

.icon.--white {
  --color: #fff;
}

.icon.--black {
  --color: #292934;
}

.icon.--success {
  --color: #48a463;
}

.icon.--error {
  --color: #f93a3a;
}

.icon.--xl {
  --size: 2.5rem;
}

.icon.--lg {
  --size: 2rem;
}

.icon.--sm {
  --size: 1rem;
}

.icon.--xs {
  --size: .5625rem;
}

.img {
  border-radius: 0;
  overflow: hidden;
}

.img.--ratio-4-3 {
  aspect-ratio: 4 / 3;
}

.img.--ratio-16-9 {
  aspect-ratio: 16 / 9;
}

.img.--ratio-3-2 {
  aspect-ratio: 3 / 2;
}

.img.--ratio-1-1 {
  aspect-ratio: 1 / 1;
}

.img.--ratio-23-17 {
  aspect-ratio: 23 / 17;
}

.img.--ratio-5-4 {
  aspect-ratio: 5 / 4;
}

.img img {
  border-radius: 0;
}

.img.--contain img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.img.--cover img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.img.--placeholder {
  position: relative;
}

.img.--placeholder:before {
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #fafafa;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.i-aviability {
  --icon-size: .5rem;
}

.i-aviability:not(.--default):before {
  content: "";
  width: var(--icon-size);
  height: var(--icon-size);
  border-radius: 50%;
  display: block;
}

.i-aviability.--for-order:before {
  background-color: #a9a9ae;
}

.i-aviability.--aviable:before {
  background-color: #51c87c;
}

.i-aviability.--unaviable:before {
  background-color: #f93a3a;
}

.i-badge {
  --font-color: colors. $ black;
  --padding-y: .125rem;
  padding-block: var(--padding-y);
  color: var(--font-color);
  min-height: 1.5rem;
  background-color: #ececec;
}

.i-badge.--gray {
  background-color: #fafafa;
  border: .0625rem solid #ececec;
}

.i-badge.--orange {
  background-color: #ffccb980;
  border: .0625rem solid #ffccb9;
}

.i-badge.--black {
  color: #a9a9ae;
  background-color: #292934;
}

.i-badge.--black path {
  fill: #a9a9ae;
}

.i-branch-modal {
  grid-template-columns: 22.5rem 15.5rem 1fr 1fr;
  gap: 1rem;
  display: grid;
}

@media (max-width: 61.999em) {
  .i-branch-modal {
    grid-template-columns: minmax(16rem, 22.5rem) minmax(10.5rem, 15.5rem) 1fr 1fr;
  }
}

@media (max-width: 47.999em) {
  .i-branch-modal {
    flex-wrap: wrap;
    display: flex;
  }
}

.i-branch-modal__title {
  max-width: 14.5rem;
  width: 100%;
}

@media (max-width: 47.999em) {
  .i-branch-modal__content {
    width: 100%;
    flex-grow: 1;
  }

  .i-branch-modal__button-col {
    margin-left: auto;
  }
}

.i-branch-modal__tooltip-col {
  flex-shrink: 0;
}

.i-breadcrumb__link:after {
  content: "";
  width: .25rem;
  height: .25rem;
  pointer-events: none;
  background-color: #292934;
  border-radius: .125rem;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%)translateX(.75rem);
}

.i-breadcrumb__current {
  max-width: 60ch;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.i-cart {
  --padding-x: 2rem;
  padding-left: var(--padding-x);
  padding-right: var(--padding-x);
  background-color: #fff;
  grid-template-columns: 5.5rem 15.75rem 1fr 1fr 2.5rem 1fr 2.875rem;
  grid-template-areas: "image content aviability counter discount price action";
  align-items: center;
  gap: 1.5rem;
  display: grid;
}

.i-cart .c-counter {
  --width: 8rem;
}

@media (max-width: 35.999em) {
  .i-cart {
    --padding-x: 1rem;
  }
}

@media (max-width: 61.999em) {
  .i-cart {
    grid-template-columns: 4rem 1fr 1fr 2.875rem;
    grid-template-areas: "image content content content"
                         "aviability aviability discount discount"
                         "counter counter price action";
    gap: 1rem;
  }
}

@media (min-width: 62em) and (max-width: 74.999em) {
  .i-cart {
    gap: 1rem;
  }
}

@media (max-width: 35.999em) {
  .i-cart__title {
    font-size: .75rem;
  }
}

.i-cart__title-link {
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

@media (max-width: 35.999em) {
  .i-cart__title-link {
    -webkit-line-clamp: 4;
  }
}

.i-cart__image {
  height: 5.5rem;
  grid-area: image;
}

@media (max-width: 61.999em) {
  .i-cart__image {
    height: 4rem;
  }
}

.i-cart__content {
  max-width: 15.75rem;
  grid-area: content;
}

@media (max-width: 61.999em) {
  .i-cart__content {
    max-width: 100%;
    align-self: start;
    margin-top: .5rem;
  }
}

.i-cart__aviability {
  grid-area: aviability;
}

@media (min-width: 62em) {
  .i-cart__aviability {
    margin-inline: auto;
  }
}

.i-cart__counter {
  grid-area: counter;
}

.i-cart__price {
  grid-area: price;
}

.i-cart__discount {
  grid-area: discount;
  justify-self: end;
}

.i-cart__action {
  grid-area: action;
  justify-self: end;
}

a.i-cart-step {
  text-decoration-color: #0000;
}

a.i-cart-step:hover {
  color: #292934;
  text-decoration-color: #292934;
}

.i-cart-step {
  color: #292934;
  align-items: center;
  font-family: Noto Serif, serif;
  font-size: 1.25rem;
}

.i-cart-step:before {
  content: attr(data-step);
  min-width: 2.5rem;
  min-height: 2.5rem;
  color: #fff;
  background-color: #292934;
  border-radius: 1.25rem;
  justify-content: center;
  align-items: center;
  display: flex;
}

.i-cart-step.--disabled:before {
  color: #292934;
  background-color: #fafafa;
  outline: .0625rem solid #ececec;
}

@media (max-width: 35.999em) {
  .i-cart-step:not(.--active) {
    display: none;
  }
}

@media (min-width: 75em) {
  .i-cart-step {
    flex-shrink: 0;
  }
}

@media (min-width: 36em) and (max-width: 61.999em) {
  .i-cart-step {
    font-size: 1rem;
  }

  .i-cart-step:before {
    min-width: 2rem;
    min-height: 2rem;
  }
}

@media (min-width: 36em) and (max-width: 47.999em) {
  .i-cart-step {
    gap: .25rem;
    font-size: .75rem;
  }

  .i-cart-step:before {
    min-width: 1.5rem;
    min-height: 1.5rem;
  }
}

.i-contact__body svg {
  margin-top: .3125rem;
}

.i-content-toggle__header {
  --font-family: Noto Serif, serif;
  font-family: var(--font-family);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.i-content-toggle__content {
  display: none;
}

.i-content-toggle__check-visual {
  width: 1rem;
  height: 1rem;
  background-color: #fff;
  border: .0625rem solid #292934;
  justify-content: center;
  align-items: center;
  transition: background-color .3s;
  display: flex;
}

.i-content-toggle__check-visual svg {
  opacity: 0;
  transition: opacity .3s, transform .3s;
  transform: scale(.2);
}

.i-content-toggle.--active .i-content-toggle__content {
  display: block;
}

.i-content-toggle.--active .i-content-toggle__check-visual {
  background-color: #292934;
}

.i-content-toggle.--active .i-content-toggle__check-visual svg {
  opacity: 1;
  transform: scale(1);
}

.i-deliverity.u-gray-box {
  --box-size: 1rem;
}

@media (min-width: 75em) and (max-width: 93.749em) {
  .i-deliverity {
    gap: .5rem;
    padding: .5rem;
  }
}

@media (min-width: 62em) and (max-width: 74.999em) {
  .i-deliverity {
    gap: .5rem;
    padding: .5rem;
  }
}

@media (min-width: 36em) and (max-width: 47.999em) {
  .i-deliverity {
    gap: .5rem;
    padding: .5rem;
  }
}

@media (max-width: 35.999em) {
  .i-deliverity {
    width: 100%;
    justify-content: center;
    display: flex;
  }
}

.i-deliverity.--hidden {
  visibility: hidden;
}

@media (max-width: 35.999em) {
  .i-deliverity.--hidden {
    display: none;
  }
}

@media (min-width: 75em) and (max-width: 93.749em) {
  .i-deliverity__icon {
    margin-left: 0;
  }
}

@media (min-width: 62em) and (max-width: 74.999em) {
  .i-deliverity__icon {
    margin-left: 0;
  }
}

.i-deliverity__content {
  gap: .25rem;
}

.i-deliverity__icon-decoration {
  pointer-events: none;
  top: 50%;
  right: 100%;
  transform: translateY(-50%)translateX(-.5rem);
}

@media (min-width: 75em) and (max-width: 93.749em) {
  .i-deliverity__icon-decoration {
    display: none;
  }
}

@media (min-width: 62em) and (max-width: 74.999em) {
  .i-deliverity__icon-decoration {
    display: none;
  }
}

.i-deliverity__tooltip {
  z-index: 1;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}

.i-deliverity__tooltip:before {
  content: "";
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -1;
  opacity: 0;
  background-color: #ececec;
  border-radius: 50%;
  transition: opacity .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.i-deliverity__tooltip:hover:before {
  opacity: 1;
}

.i-delivery-provider__logo {
  max-width: 2.5rem;
  flex-basis: 2.5rem;
}

.i-discount__text {
  font-size: .625rem;
}

.i-discount {
  --size: 4rem;
  aspect-ratio: 1 / 1;
  min-width: var(--size);
  min-height: var(--size);
  background-color: #ffccb9;
  border-radius: 50%;
  font-weight: 800;
}

.i-discount.--compact {
  --size: 2.5rem;
  display: inline-flex;
}

.i-discount.--compact .i-discount__value {
  font-size: .875rem;
}

.i-discount.--compact .i-discount__text {
  display: none;
}

.i-dropdown-product__title {
  max-width: 13rem;
}

.i-dropdown-product__action {
  min-height: 3rem;
  min-width: 3rem;
  cursor: pointer;
}

.i-dropdown-product__action .icon {
  z-index: 1;
}

.i-dropdown-product__action:after {
  content: "";
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  background-color: #ececec;
  border-radius: 50%;
  transition: opacity .3s;
  display: block;
  position: absolute;
}

.i-dropdown-product__action:hover:after {
  opacity: 1;
}

.i-dropdown-product__image {
  --size: 2rem;
  flex: 0 0 var(--size);
  max-width: var(--size);
  height: var(--size);
  display: block;
}

.i-gift__body {
  --visual-opacity: 0;
  --visual-opacity-checked: 1;
  --visual-scale: .5;
  --visual-scale-checked: 1;
  --visual-before-scale: 0 1;
  --visual-before-scale-checked: 1 1;
}

.i-gift__body:before {
  content: "";
  height: calc(100% - .5rem);
  width: .1875rem;
  transform: translateY(-50%);
  scale: var(--visual-before-scale);
  transform-origin: center;
  background-color: #0066b3;
  transition: scale .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
}

.i-gift__input {
  pointer-events: none;
  bottom: 0;
  left: 0;
}

.i-gift__input:checked ~ .i-gift__body {
  --visual-opacity: var(--visual-opacity-checked);
  --visual-scale: var(--visual-scale-checked);
  --visual-before-scale: var(--visual-before-scale-checked);
}

.i-gift__visual {
  width: 1rem;
  height: 1rem;
  border: .0625rem solid #292934;
  border-radius: .5rem;
}

.i-gift__visual:before {
  content: "";
  width: .625rem;
  height: .625rem;
  opacity: var(--visual-opacity);
  scale: var(--visual-scale);
  background-color: #292934;
  border-radius: .3125rem;
  transition: opacity .3s, scale .3s;
  display: block;
  top: 50%;
  left: 50%;
}

.i-gift__description span {
  color: #f93a3a;
  font-weight: 700;
}

.i-gift__image-col {
  --size: 3.5rem;
  max-width: var(--size);
  flex: 0 0 var(--size);
  height: var(--size);
}

.i-gift {
  border: .0625rem solid #ececec;
  transition: background-color .3s, opacity .3s;
}

.i-gift:hover:not(.--disabled) {
  background-color: #fafafa;
}

.i-gift.--disabled {
  opacity: .5;
}

.i-grader-slide {
  height: 100%;
  border: .0625rem solid #ececec;
}

.i-grader-slide__parameters {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.i-grader-slide__param {
  border-top: .0625rem solid #ececec;
}

.i-grader-slide__param:first-of-type {
  border-top: none;
}

.i-grader-slide__title {
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.i-grader-tab {
  min-height: 4rem;
  cursor: pointer;
  max-width: 25%;
  background-color: #fafafa;
  border: .0625rem solid #ececec;
  flex: 0 0 25%;
}

@media (max-width: 74.999em) {
  .i-grader-tab {
    max-width: 33%;
    flex: 0 0 33%;
  }
}

@media (max-width: 61.999em) {
  .i-grader-tab {
    max-width: none;
    flex: none;
  }
}

.i-grader-tab.--active {
  background-color: #fff;
  border-bottom-color: #fff;
}

.i-grader-tab:after {
  content: "";
  width: 1.0625rem;
  height: .0625rem;
  background-color: #ececec;
  display: block;
  position: absolute;
  bottom: -.0625rem;
  left: 100%;
}

.i-grader-tab:last-of-type:after {
  display: none;
}

.i-grader-tab__count {
  min-height: 1.5rem;
  background-color: #0000;
  border: .0625rem solid #ececec;
  padding-inline: .5rem;
}

.i-grader-tab__count svg {
  width: .5rem;
  height: .5rem;
}

.i-header-dropdown__list {
  z-index: 5;
  min-width: 30rem;
  max-width: 100%;
  pointer-events: none;
  opacity: 0;
  cursor: auto;
  transition: opacity .3s, transform .3s;
  position: absolute;
  top: 100%;
  right: 0;
  transform: translateY(1rem);
}

@media (max-width: 47.999em) {
  .i-header-dropdown__list {
    display: none;
  }
}

.i-header-dropdown {
  --size: 3.5rem;
  min-width: var(--size);
  min-height: var(--size);
  cursor: pointer;
  color: #292934;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

@media (min-width: 48em) and (max-width: 61.999em) {
  .i-header-dropdown {
    --size: 3rem;
  }
}

@media (max-width: 35.999em) {
  .i-header-dropdown {
    --size: 3rem;
  }
}

.i-header-dropdown:before {
  content: "";
  width: 2.875rem;
  height: 2.875rem;
  opacity: 0;
  pointer-events: none;
  background-color: #ececec;
  border-radius: 50%;
  transition: opacity .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.i-header-dropdown:after {
  content: "";
  width: .5rem;
  height: .5rem;
  background-color: var(--c-primary);
  opacity: 0;
  pointer-events: none;
  border-radius: .25rem;
  transition: opacity .3s, transform .3s;
  display: block;
  position: absolute;
  top: .5rem;
  right: .5rem;
  transform: scale(0);
}

.i-header-dropdown:hover:before, .i-header-dropdown.--open:before {
  opacity: 1;
}

.i-header-dropdown:hover .i-header-dropdown__list, .i-header-dropdown.--open .i-header-dropdown__list {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
}

.i-header-dropdown.--fill:after {
  opacity: 1;
  transform: scale(1);
}

.i-header-dropdown__link {
  z-index: 1;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (max-width: 35.999em) {
  .i-header-dropdown__link .icon {
    padding: .125rem;
  }
}

.i-hero {
  --content-max-width: 23.75rem;
  --font-color: #fff;
  color: var(--font-color);
  grid-template-columns: repeat(9, 1fr);
  align-items: center;
  display: grid;
}

@media (max-width: 93.749em) {
  .i-hero {
    --content-max-width: 20rem;
  }
}

@media (max-width: 61.999em) {
  .i-hero {
    --content-max-width: 100%;
    max-width: 33.5rem;
    margin-inline: auto;
    display: block;
  }
}

.i-hero__body {
  width: 100%;
  grid-area: 1 / 1 / 2 / 6;
  justify-content: center;
  margin-right: .5rem;
  padding: 3.5rem 3rem;
  display: flex;
}

@media (min-width: 62em) {
  .i-hero__body {
    min-height: 19.5rem;
  }
}

@media (max-width: 35.999em) {
  .i-hero__body {
    padding: 2rem;
  }
}

@media (max-width: 22.438em) {
  .i-hero__body {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.i-hero__description {
  max-width: var(--content-max-width);
  padding-left: 1rem;
}

@media (max-width: 35.999em) {
  .i-hero__description {
    font-size: .875rem;
  }
}

.i-hero__description:before {
  content: "";
  width: .125rem;
  height: 100%;
  background-color: var(--font-color);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.i-hero__image {
  max-width: 33.5rem;
  order: 2;
  grid-area: 1 / 5 / 2 / -1;
}

@media (min-width: 75em) and (max-width: 93.749em) {
  .i-hero__image {
    padding-left: 2rem;
  }
}

@media (min-width: 62em) {
  .i-hero__image {
    margin-inline-start: auto;
  }
}

.i-hero__heading {
  max-width: var(--content-max-width);
}

.i-hero__body-pattern {
  --bg-color: var(--c-primary);
  --pattern-color: var(--c-primary-500);
  background-color: var(--bg-color);
  z-index: -1;
  overflow: hidden;
}

.i-hero-slide {
  --content-max-width: 23.5rem;
  --font-color: #fff;
  align-items: center;
  display: block;
}

@media (max-width: 93.749em) {
  .i-hero-slide {
    --content-max-width: 20rem;
  }
}

@media (max-width: 61.999em) {
  .i-hero-slide {
    --content-max-width: 100%;
  }
}

@media (min-width: 62em) {
  .i-hero-slide {
    grid-template-columns: repeat(9, 1fr);
    display: grid;
  }
}

.i-hero-slide__body {
  color: var(--font-color);
  flex-direction: column;
  padding: 4.5rem 3.5rem;
  display: flex;
}

@media (min-width: 62em) {
  .i-hero-slide__body {
    min-height: 26.25rem;
    grid-area: 1 / 1 / 2 / 6;
  }
}

@media (max-width: 61.999em) {
  .i-hero-slide__body {
    max-width: 34.5rem;
    margin-inline: auto;
    padding: 2rem 1rem;
  }
}

.i-hero-slide__button {
  margin-top: 3.5rem;
}

@media (max-width: 35.999em) {
  .i-hero-slide__button {
    margin-top: 2rem;
  }
}

.i-hero-slide__description {
  max-width: var(--content-max-width);
  padding-left: 1rem;
  position: relative;
}

@media (max-width: 35.999em) {
  .i-hero-slide__description {
    font-size: .875rem;
  }
}

.i-hero-slide__description:before {
  content: "";
  width: .125rem;
  height: 100%;
  background-color: var(--font-color);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.i-hero-slide__heading {
  color: var(--font-color);
  max-width: var(--content-max-width);
  margin-bottom: 1.5rem;
}

@media (max-width: 35.999em) {
  .i-hero-slide__heading {
    margin-bottom: 1rem;
    font-size: 1.75rem;
  }
}

.i-hero-slide__image {
  z-index: 2;
  max-width: 34.5rem;
  margin-inline-start: auto;
}

.i-hero-slide__image img {
  transition-property: opacity, transform;
  transform: scale(1);
}

.i-hero-slide__image img[data-src]:not(.loaded) {
  opacity: 0;
  transform: scale(.95);
}

@media (min-width: 62em) {
  .i-hero-slide__image {
    grid-area: 1 / 5 / 2 / -1;
    padding-left: 1rem;
  }
}

@media (max-width: 61.999em) {
  .i-hero-slide__image {
    margin-inline: auto;
  }
}

.i-hero-slider__category {
  text-transform: uppercase;
  max-width: var(--content-max-width);
}

.i-hero-slide__body-pattern {
  --bg-color: var(--c-primary);
  --pattern-color: var(--c-primary-500);
  background-color: var(--bg-color);
  z-index: -1;
  overflow: hidden;
}

.i-image-box.u-gray-box {
  --background: #fff;
}

@media (min-width: 36em) {
  .i-information__label {
    width: min(100%, 7rem);
    flex-shrink: 0;
  }
}

.i-information__item {
  row-gap: .5rem;
}

@media (max-width: 35.999em) {
  .i-information__item {
    flex-direction: column;
  }
}

.i-information__content {
  max-width: 80ch;
}

.i-order {
  outline: .0625rem solid #ececec;
  grid-template-columns: 1fr 1fr 1fr 1fr 3rem;
  grid-template-areas: "id date price status action";
  gap: 1rem;
  display: grid;
}

@media (max-width: 47.999em) {
  .i-order {
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas: "id id date date"
                         "price price status status"
                         "action action action action";
  }
}

.i-order__action {
  grid-area: action;
}

.i-order__date {
  grid-area: date;
}

@media (max-width: 47.999em) {
  .i-order__date {
    justify-content: flex-end;
    display: flex;
  }
}

.i-order__id {
  grid-area: id;
}

.i-order__price {
  grid-area: price;
}

.i-order__status {
  grid-area: status;
}

@media (max-width: 47.999em) {
  .i-order__status {
    justify-content: flex-end;
  }
}

.i-overview__image {
  max-width: 2.5rem;
  height: 1.5rem;
  flex: 0 0 2.5rem;
}

.i-overview:after, .i-overview:before {
  content: "";
  height: .0625rem;
  width: calc(100% + 2rem);
  background-color: #ececec;
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.i-overview:before {
  top: 0;
}

.i-overview:after {
  background-color: #0000;
  bottom: 0;
}

.i-overview:last-of-type:after {
  background-color: #ececec;
}

.i-overview.--default {
  flex-wrap: wrap;
  align-items: center;
}

.i-overview.--default .i-overview__title {
  font-size: .75rem;
}

.i-overview.--default .i-overview__label {
  width: 100%;
  flex-grow: 1;
}

.i-overview.--compact {
  justify-content: space-between;
}

.i-overview.--compact .i-overview__title {
  font-size: .875rem;
}

.i-overview.--hidden {
  display: none;
}

.i-person-inline__image {
  height: 4rem;
  max-width: 4rem;
  outline: .0625rem solid #292934;
  flex: 0 0 4rem;
  padding: .25rem;
}

.i-person-inline__body {
  height: 100%;
  gap: .25rem;
}

.i-person__image {
  width: 6.5rem;
  outline: .0625rem solid #fff;
}

.i-person__caption {
  color: #a9a9ae;
}

.i-person .d-side-stripes {
  --offset: 1.5rem;
}

.i-person svg {
  width: 2.5rem;
  height: 2.1875rem;
}

.i-person path {
  fill: #fff;
}

.i-post.--column {
  outline: .0625rem solid #ececec;
  flex-direction: column-reverse;
  justify-content: space-between;
  gap: 1rem;
  padding: 2rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media (hover: hover) {
  .i-post.--column:hover .i-post__button {
    opacity: 1;
    transform: translateX(0);
  }
}

.i-post.--column .i-post__button {
  padding: 1rem;
  transition: opacity .3s cubic-bezier(.68, -.55, .27, 1.55), transform .3s cubic-bezier(.68, -.55, .27, 1.55);
  position: absolute;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

@media (hover: hover) {
  .i-post.--column .i-post__button {
    opacity: 0;
    transform: translateX(2rem);
  }
}

.i-post.--column .i-post__button:after {
  content: "";
  width: 100%;
  height: 100%;
  opacity: .8;
  background-color: #fff;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.i-post.--column .i-post__perex {
  -webkit-line-clamp: 4;
  line-height: 1.5rem;
}

@media (min-width: 62em) and (max-width: 74.999em) {
  .i-post.--column {
    padding: 1rem;
  }
}

@media (max-width: 47.999em) {
  .i-post.--column {
    max-width: 22.0625rem;
    margin-inline: auto;
  }
}

.i-post.--default {
  outline: .0625rem solid #ececec;
  padding: 2rem;
}

.i-post.--default .i-post__content {
  max-width: 70ch;
}

@media (min-width: 62em) and (max-width: 74.999em) {
  .i-post.--default {
    padding: 1rem;
  }

  .i-post.--default .i-post__perex {
    -webkit-line-clamp: 3;
  }
}

@media (max-width: 74.999em) {
  .i-post.--default .i-post__heading-link {
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }
}

@media (max-width: 47.999em) {
  .i-post.--default {
    max-width: 22.0625rem;
    flex-direction: column;
    gap: 1rem;
    margin-inline: auto;
    padding: 1rem;
    display: flex;
  }

  .i-post.--default .i-post__button {
    justify-content: flex-end;
  }
}

.i-post.--hero {
  grid-template-columns: repeat(2, 1fr);
  gap: .5rem;
  position: relative;
}

.i-post.--hero:after, .i-post.--hero:before {
  content: "";
  width: 100vw;
  height: .0625rem;
  background-color: #ececec;
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.i-post.--hero:before {
  top: -.0625rem;
}

.i-post.--hero:after {
  bottom: 0;
}

@media (max-width: 74.999em) {
  .i-post.--hero {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 61.999em) {
  .i-post.--hero {
    max-width: 41rem;
    flex-direction: column;
    margin-inline: auto;
    padding-bottom: 1rem;
    display: flex;
  }
}

.i-post.--hero .i-post__content {
  justify-content: center;
  padding-block: .5rem;
}

.i-post.--hero .i-post__button {
  justify-content: flex-start;
  margin-top: 2.5rem;
}

@media (max-width: 93.749em) {
  .i-post.--hero .i-post__button {
    margin-top: .5rem;
  }
}

@media (max-width: 61.999em) {
  .i-post.--hero .i-post__button {
    justify-content: flex-end;
  }
}

.i-post.--hero .i-post__heading-link {
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.i-post.--hero .i-post__heading {
  font-size: 2rem;
}

@media (max-width: 74.999em) {
  .i-post.--hero .i-post__heading {
    font-size: 1.75rem;
  }
}

@media (max-width: 61.999em) {
  .i-post.--hero .i-post__heading {
    font-size: 1.5rem;
  }
}

.i-post.--hero .i-post__image {
  max-width: 41rem;
  margin-right: 2rem;
}

@media (max-width: 74.999em) {
  .i-post.--hero .i-post__image {
    margin-right: 1rem;
  }
}

@media (max-width: 61.999em) {
  .i-post.--hero .i-post__image {
    margin-right: 0;
  }
}

.i-post.--hero .i-post__perex {
  -webkit-line-clamp: 7;
  font-size: 1rem;
  line-height: 1.5rem;
}

@media (max-width: 93.749em) {
  .i-post.--hero .i-post__perex {
    -webkit-line-clamp: 5;
  }
}

@media (max-width: 74.999em) {
  .i-post.--hero .i-post__perex {
    -webkit-line-clamp: 3;
    font-size: .875rem;
  }
}

.i-post__image-col {
  grid-area: image;
}

.i-post__content-col {
  grid-area: content;
}

.i-post__heading {
  font-family: Noto Serif, serif;
  font-size: 1.125rem;
  font-weight: bold;
}

.i-post__perex {
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  margin-bottom: 0;
  font-size: .875rem;
  font-weight: 500;
  display: -webkit-box;
  overflow: hidden;
}

.i-post__header {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.i-post__heading-link {
  color: #292934;
  text-decoration-color: #0000;
  display: block;
  overflow: hidden;
}

.i-post__heading-link:hover {
  color: #292934;
  text-decoration-color: #292934;
}

.i-post__footer {
  justify-content: flex-end;
  margin-top: .5rem;
  display: flex;
}

.i-post__content {
  height: 100%;
  flex-direction: column;
  row-gap: 1rem;
  display: flex;
}

.i-post-image-link {
  width: 100%;
  height: 100%;
  border-radius: 0;
  display: block;
  position: relative;
}

.i-post-image-link img {
  transition-property: opacity, transform;
  transform: scale(1);
}

.i-post-image-link img[data-src]:not(.loaded) {
  opacity: 0;
  transform: scale(.95);
}

.i-post__date {
  align-items: center;
  column-gap: .5rem;
  margin-right: auto;
  font-size: .875rem;
  font-weight: 800;
  display: flex;
}

.i-post__button {
  display: flex;
}

.i-post {
  background-color: #fff;
  grid-template-columns: 18rem auto;
  grid-template-areas: "image content";
  column-gap: 1rem;
  display: grid;
}

@media (max-width: 47.999em) {
  .i-post {
    grid-template-columns: 12rem auto;
  }
}

@media (max-width: 35.999em) {
  .i-post {
    max-width: 21rem;
    flex-direction: column;
    margin-inline: auto;
    display: flex;
  }
}

.i-price__value {
  white-space: nowrap;
}

.i-price__base {
  color: #292934;
  white-space: nowrap;
  gap: .5rem;
  font-size: 1.5rem;
  display: flex;
}

.i-price__before {
  color: #9999a5;
  white-space: nowrap;
  gap: .25rem;
  text-decoration: line-through;
  display: flex;
}

.i-price {
  text-align: right;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: .25rem;
  display: flex;
}

.i-price.--size-xs .i-price__base {
  gap: .25rem;
  font-size: .875rem;
}

.i-price.--size-xs .i-price__before {
  font-size: .75rem;
}

.i-price.--size-sm .i-price__base {
  gap: .25rem;
  font-size: 1rem;
}

@media (max-width: 35.999em) {
  .i-price.--size-sm .i-price__base {
    font-size: .875rem;
  }
}

.i-price.--size-sm .i-price__before {
  font-size: .875rem;
}

@media (max-width: 35.999em) {
  .i-price.--size-sm .i-price__before {
    font-size: .75rem;
  }
}

.i-price.--size-md .i-price__base {
  gap: .25rem;
  font-size: 1.25rem;
}

@media (min-width: 62em) and (max-width: 74.999em) {
  .i-price.--size-md .i-price__base {
    font-size: 1rem;
  }
}

@media (max-width: 35.999em) {
  .i-price.--size-md .i-price__base {
    font-size: 1rem;
  }
}

.i-price.--align-center {
  align-items: center;
}

.i-product {
  box-shadow: 0 0 0 .0625rem #ececec;
}

.i-product__title {
  font-family: Noto Serif, serif;
}

.i-product__title-link {
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.i-product__warranty {
  pointer-events: none;
}

.i-product-modal:after, .i-product-modal:before {
  content: "";
  width: calc(100% + 3rem);
  height: .0625rem;
  background-color: #ececec;
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.i-product-modal:before {
  top: 0;
}

.i-product-modal:after {
  bottom: 0;
}

.i-product-modal__image {
  flex-basis: 3.5rem;
}

.i-product-overview {
  grid-template-columns: 2.5rem 13rem auto 1fr;
  grid-template-areas: "image content count price";
  align-items: center;
  gap: 1rem;
  display: grid;
}

@media (min-width: 62em) and (max-width: 93.749em) {
  .i-product-overview {
    grid-template-columns: 2.5rem 11.5rem auto 1fr;
    grid-template-areas: "image content count price";
    gap: .5rem;
  }
}

@media (max-width: 61.999em) {
  .i-product-overview {
    grid-template-columns: 2.5rem 11.5rem 1fr 1fr;
    grid-template-areas: "image content count price";
  }
}

@media (max-width: 35.999em) {
  .i-product-overview {
    grid-template-columns: 2.5rem 1fr 1fr;
    grid-template-areas: "image count price"
                         "content content content";
    row-gap: .5rem;
  }
}

.i-product-overview__count {
  white-space: nowrap;
  grid-area: count;
  margin-left: auto;
}

@media (min-width: 62em) and (max-width: 93.749em) {
  .i-product-overview__count {
    font-size: .75rem;
  }
}

@media (max-width: 35.999em) {
  .i-product-overview__count {
    margin-left: 0;
  }
}

.i-product-overview__title {
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.i-product-overview__content {
  grid-area: content;
}

@media (max-width: 35.999em) {
  .i-product-overview__content {
    order: 3;
  }
}

.i-product-overview__image {
  outline: .0625rem solid #ececec;
  grid-area: image;
}

.i-product-overview__price {
  grid-area: price;
}

.i-progress__icon {
  width: 2rem;
}

.i-progress__input {
  -webkit-appearance: none;
  appearance: none;
  height: .5rem;
  background-color: #ececec;
  border: none;
  border-radius: .25rem;
  overflow: hidden;
}

.i-progress__input::-webkit-progress-bar {
  background-color: #ececec;
}

.i-progress__input::-webkit-progress-value {
  background-color: #292934;
}

.i-progress__input::-moz-progress-bar {
  background-color: #292934;
}

.i-progress__icon-decoration {
  margin-right: -.5rem;
}

.i-progress__gratis-text {
  font-weight: 800;
  display: none;
}

.i-progress {
  max-width: 45rem;
}

.i-progress strong {
  font-weight: 800;
}

.i-progress.--gratis .i-progress__gratis-text {
  display: block;
}

.i-progress.--gratis .i-progress__text {
  display: none;
}

.i-search-suggest__image {
  width: 2rem;
  height: 2rem;
}

.i-search-suggest {
  text-decoration: none;
}

.i-search-suggest:hover {
  color: #292934;
  background-color: #ececec;
}

.i-search-suggest__code {
  font-size: .625rem;
}

.i-selected-filter {
  min-height: 1.5rem;
  cursor: pointer;
  background-color: #fafafa;
  border: .0625rem solid #ececec;
  align-items: center;
  padding: .125rem .5rem;
}

.i-selected-filter__remove {
  --size: .5rem;
}

.i-selected-filter__remove svg {
  width: var(--size);
  height: var(--size);
}

.i-set__heading {
  font-family: Noto Serif, serif;
}

.i-set__heading .link__title {
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.i-set {
  box-shadow: 0 0 0 .0625rem #ececec;
}

@media (min-width: 48em) and (max-width: 61.999em) {
  .i-set__footer {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 35.999em) {
  .i-set__footer {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (min-width: 48em) and (max-width: 61.999em) {
  .i-set__button-link {
    width: 100%;
  }
}

.i-set-product {
  background-color: #fff;
  outline: .0625rem solid #ececec;
}

.i-set-product__header {
  grid-template-columns: 4rem 1fr;
  display: grid;
}

.i-set-product__title {
  font-family: Noto Serif, serif;
}

.i-set-product__title .link__title {
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.i-set-product__image {
  height: 4rem;
}

.i-set-product__table {
  display: none;
}

.i-set-product__table.--active {
  display: block;
}

.i-set-product__table tbody {
  border-bottom: .0625rem solid #ececec;
  flex-direction: column;
  gap: .5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
}

.i-set-product__table tr {
  grid-template-columns: 17fr 20fr;
  gap: 1rem;
  display: grid;
}

.i-set-product__parameters-heading {
  cursor: pointer;
  padding: 0 .5rem;
}

.i-set-product__parameters-header {
  -webkit-user-select: none;
  user-select: none;
  justify-content: space-between;
  align-items: center;
}

.i-set-product__parameters-header svg {
  will-change: scale;
  transition: scale .3s;
}

.i-set-product__parameters-header.--active svg {
  scale: -1;
}

.i-set-product__parameters-header:after, .i-set-product__parameters-header:before {
  content: "";
  height: .0625rem;
  width: 100%;
  background-color: #ececec;
  display: block;
}

@media (min-width: 62em) and (max-width: 74.999em) {
  .i-set-product__buttons {
    flex-direction: column;
  }

  .i-set-product__buttons .btn-compare {
    width: 100%;
    min-width: auto;
    max-width: 100%;
  }
}

.i-showroom-gallery {
  cursor: pointer;
  outline: .0625rem solid #ececec;
}

@media (max-width: 35.999em) {
  .i-showroom-gallery {
    padding: .5rem;
  }
}

.i-specialist__header {
  max-width: 11rem;
}

.i-specialist:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 1.5625rem solid #34343e;
  border-bottom: 0 solid #0000;
  border-left: 0 solid #0000;
  border-right: 1.5625rem solid #0000;
  transition: transform .3s cubic-bezier(.68, -.55, .27, 1.55);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-.375rem)rotate(-4deg);
}

.i-specialist__body {
  color: #fff;
  -webkit-clip-path: polygon(0 2.625rem, 0 1.5rem, 1.5rem 0, 100% 0, 100% 100%, 0 100%, 0 3rem, 1.5rem 1rem);
  clip-path: polygon(0 2.625rem, 0 1.5rem, 1.5rem 0, 100% 0, 100% 100%, 0 100%, 0 3rem, 1.5rem 1rem);
  background-color: #34343e;
}

.i-status {
  --font-color: colors. $ black;
  color: var(--font-color);
  min-height: 2rem;
  background-color: #9eeaf9;
  padding: .375rem;
}

.i-status.--success {
  --font-color: #fff;
  background-color: #51c87c;
}

.i-status.--error {
  --font-color: #fff;
  background-color: #f93a3a;
}

.i-status.--info {
  --font-color: #292934;
  background-color: #9eeaf9;
}

.i-status.--warning {
  --font-color: #292934;
  background-color: #ffc107;
}

.i-tag {
  --font-color: colors. $ black;
  --padding-y: .125rem;
  padding-block: var(--padding-y);
  color: var(--font-color);
  min-height: 1.5rem;
  background-color: #ececec;
}

.i-tag path {
  fill: var(--font-color);
}

.i-tag.--blue {
  --font-color: #292934;
  background-color: #d0e1f9;
}

.i-tag.--orange {
  --font-color: #292934;
  background-color: #f9e6d9;
}

.i-tag.--primary {
  --font-color: #fff;
  background-color: var(--c-primary);
}

.i-tag.--black {
  --font-color: #fff;
  background-color: #292934;
}

.i-tag.--custom {
  background-color: var(--background);
}

a.i-tag {
  text-decoration-color: #0000;
}

a.i-tag:hover {
  color: var(--font-color);
  -webkit-text-decoration-color: var(--font-color);
  text-decoration-color: var(--font-color);
}

.i-total-price {
  gap: .125rem;
}

@media (max-width: 35.999em) {
  .i-total-price__row.--main .i-total-price__label {
    font-size: 1rem;
  }

  .i-total-price__row.--main .i-total-price__amount {
    font-size: 1.125rem;
  }
}

.i-usp__button {
  opacity: 0;
  pointer-events: none;
  transition: opacity .3s;
}

.i-usp__icon {
  margin-bottom: .25rem;
}

.i-usp__body {
  gap: .25rem;
  transition: filter .3s;
}

.i-usp:has(.i-usp__button):hover .i-usp__body {
  filter: blur(.25rem);
}

.i-usp:has(.i-usp__button):hover .i-usp__button {
  opacity: 1;
  pointer-events: auto;
}

.i-variant {
  outline: .0625rem solid #ececec;
  transition: outline .3s;
}

.i-variant:hover, .i-variant.--active {
  outline: .0625rem solid #292934;
}

.i-warranty {
  min-width: 3rem;
  min-height: 3rem;
  aspect-ratio: 1 / 1;
  font-size: .5625rem;
}

.i-warranty > svg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.i-warranty__value {
  z-index: 1;
  line-height: 1rem;
}

.i-warranty__value:first-letter {
  font-size: 1rem;
}

.i-warranty__text {
  z-index: 1;
}

.l-aside__grid {
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;
  display: flex;
}

@media (min-width: 75em) {
  .l-aside__grid {
    grid-template-columns: repeat(12, 1fr);
    display: grid;
  }

  .l-aside__aside {
    order: -1;
    grid-column: auto / span 3;
  }
}

@media (min-width: 48em) and (max-width: 74.999em) {
  .l-aside__aside {
    max-width: 43rem;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
    margin-inline: auto;
    display: grid;
  }
}

@media (max-width: 47.999em) {
  .l-aside__aside {
    max-width: 20.5rem;
    margin-inline: auto;
  }
}

@media (min-width: 75em) {
  .l-aside__content {
    grid-column: auto / span 9;
  }
}

.l-cart {
  --display: flex;
  --aside-cols: auto / span 4;
  --content-cols: auto / span 8;
  display: var(--display);
  flex-direction: column;
  grid-template-columns: repeat(12, 1fr);
  gap: 3.5rem 2rem;
}

@media (min-width: 62em) {
  .l-cart {
    --display: grid;
  }
}

.l-cart__content {
  grid-column: var(--content-cols);
}

@media (max-width: 93.749em) {
  .l-cart__content {
    --content-cols: auto / span 7;
  }
}

.l-cart__aside {
  grid-column: var(--aside-cols);
}

@media (max-width: 93.749em) {
  .l-cart__aside {
    --aside-cols: auto / span 5;
  }
}

.l-user__grid {
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;
  display: flex;
}

@media (min-width: 75em) {
  .l-user__grid {
    grid-template-columns: 3fr 9fr;
    display: grid;
  }
}

.l-user__aside {
  order: -1;
}

.link__icon {
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  transform: translateY(.1875rem);
}

.link__icon svg {
  will-change: transform;
  transition: transform .3s;
}

.link {
  align-items: baseline;
  column-gap: .5rem;
  text-decoration: underline;
  transition: text-decoration-color .3s, color .3s;
  display: inline-flex;
}

.link.--black {
  color: #292934;
  text-decoration-color: #0000;
}

.link.--black:hover, .link.--black:focus {
  color: #292934;
  text-decoration-color: #292934;
}

.link.--primary {
  color: var(--c-primary);
  text-decoration-color: #0000;
}

.link.--primary:hover, .link.--primary:focus {
  color: var(--c-primary);
  -webkit-text-decoration-color: var(--c-primary);
  text-decoration-color: var(--c-primary);
}

.link.--white {
  color: #fff;
  text-decoration-color: #0000;
}

.link.--white:hover, .link.--white:focus {
  color: #fff;
  text-decoration-color: #fff;
}

.link.--icon-before .link__icon {
  order: -1;
}

.link.--animation-right svg {
  transition-timing-function: cubic-bezier(.68, -.55, .27, 1.55);
}

.link.--animation-right:hover svg {
  transform: translateX(.5rem);
}

.link.--animation-zoom:hover svg {
  transform: scale(1.1);
}

.link.--underline-initial {
  -webkit-text-decoration-color: inherit;
  text-decoration-color: inherit;
}

.link.--underline-initial:hover, .link.--underline-initial:focus {
  text-decoration-color: #0000;
}

.main-footer-column__heading {
  color: #fff;
  font-family: Noto Serif, serif;
}

@media (max-width: 35.999em) {
  .main-footer-column {
    text-align: center;
  }
}

.main-footer-column__list-item {
  display: block;
}

.main-footer-column__list-item-link {
  color: #a9a9ae;
  text-decoration-color: #0000;
  display: block;
}

.main-footer-column__list-item-link:hover {
  color: #a9a9ae;
  text-decoration-color: #a9a9ae;
}

@media (min-width: 75em) {
  .main-footer-column__socials {
    justify-content: center;
  }
}

@media (max-width: 35.999em) {
  .main-footer-column__socials {
    justify-content: center;
  }

  .main-footer-column__map {
    margin-inline: auto;
  }
}

.main-footer-column__logo {
  margin-bottom: 1.5rem;
}

@media (min-width: 75em) {
  .main-footer-column__logo {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    margin-inline: auto;
  }
}

@media (max-width: 35.999em) {
  .main-footer-column__logo {
    margin-inline: auto;
  }
}

.footer-map {
  max-width: 14rem;
}

.footer-map svg {
  width: 3rem;
  height: 3rem;
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.footer-map__link {
  max-width: 100%;
  overflow: hidden;
}

.footer-map__link img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition-property: transform, opacity;
}

.footer-map__link:hover img {
  transform: scale(1.1);
}

.main-footer {
  --border-color: var(--c-primary);
  color: #a9a9ae;
  border-bottom: .25rem solid var(--border-color);
  margin-top: 3.5rem;
}

.main-footer__top {
  background-color: #34343e;
  padding: 3.5rem 0;
}

.main-footer__bottom {
  text-align: center;
  background-color: #292934;
  padding: 2rem 0;
}

.main-footer__grid {
  gap: 2rem;
  display: grid;
}

@media (min-width: 36em) {
  .main-footer__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 75em) {
  .main-footer__grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

.main-footer__bottom-group {
  align-items: center;
}

.m-header-bottom {
  background-color: #fafafa;
  border-bottom: .0625rem solid #ececec;
}

.m-header-bottom__grid {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  display: flex;
}

@media (min-width: 48em) {
  .m-header-bottom__grid {
    grid-template-columns: 2fr 6fr 3fr;
    gap: 2rem;
    display: grid;
  }
}

@media (min-width: 62em) {
  .m-header-bottom__grid {
    grid-template-columns: 3fr 6fr 3fr;
  }
}

@media (min-width: 75em) {
  .m-header-bottom__grid {
    grid-template-columns: 3fr 7fr 2fr;
  }
}

@media (max-width: 47.999em) {
  .m-header-bottom__search {
    flex: 100%;
    order: 2;
  }
}

.m-header-bottom__ham {
  --size: 3.5rem;
  min-width: var(--size);
  min-height: var(--size);
  cursor: pointer;
  background-color: #0000;
}

@media (min-width: 75em) {
  .m-header-bottom__ham {
    display: none;
  }
}

@media (min-width: 48em) and (max-width: 61.999em) {
  .m-header-bottom__ham {
    --size: 3rem;
  }
}

@media (max-width: 35.999em) {
  .m-header-bottom__ham {
    --size: 3rem;
  }

  .m-header-bottom__ham .icon {
    padding: .125rem;
  }

  .m-header-logo {
    max-width: 7.5rem;
  }
}

.m-header-top {
  --border-color: var(--c-primary);
  color: #a9a9ae;
  border-top: .25rem solid var(--border-color);
  background-color: #34343e;
}

.m-header-top .link {
  font-weight: bold;
}

@media (max-width: 74.999em) {
  .m-header-top {
    padding: .5rem 0;
  }
}

.m-header-top__grid {
  justify-content: space-between;
  display: flex;
}

@media (max-width: 74.999em) {
  .m-header-top__grid {
    flex-direction: column;
    gap: 2rem;
  }

  .g-base.m-header-top__contact-group, .g-base.m-header-top__side-menu {
    --gap: 1rem;
    flex-direction: column;
  }

  .m-header-top__container {
    display: none;
  }
}

.main-nav {
  max-width: 26.25rem;
  z-index: 100;
  transition: transform .3s;
  transform: translateX(100%);
}

.main-nav.--open {
  transform: translateX(0);
}

@media (min-width: 75em) {
  .main-nav {
    display: none;
  }
}

.main-nav__wrapper {
  overflow-x: hidden;
  overflow-y: scroll;
}

.main-nav__header {
  padding-right: 0;
}

.main-nav__closer {
  --size: 3.5rem;
  min-width: var(--size);
  min-height: var(--size);
  background-color: #0000;
  margin-left: auto;
}

.main-nav__bottom {
  color: #a9a9ae;
  background-color: #34343ef2;
}

.main-nav__bottom .link {
  font-weight: bold;
}

.m-category-item__link {
  color: #292934;
  padding-block: .3125rem;
  text-decoration: none;
  transition: background-color .3s;
}

.m-category-item__link:hover {
  color: #292934;
  background-color: #ececec;
}

@media (max-width: 74.999em) {
  .m-category-item__link {
    padding-block: .8125rem;
  }
}

.m-category-item__dropdown {
  width: 2rem;
  pointer-events: none;
  transition: transform .3s;
}

@media (max-width: 74.999em) {
  .m-category-item__dropdown {
    width: 3rem;
    height: 3rem;
    pointer-events: auto;
    cursor: pointer;
  }

  .m-category-item__dropdown svg {
    transform: rotate(90deg);
  }
}

.m-category-item.--active .m-category-item__link {
  position: relative;
}

.m-category-item.--active .m-category-item__link:before {
  content: "";
  height: 100%;
  background-color: var(--c-primary);
  width: .1875rem;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 75em) {
  .m-category-item:hover .m-category-sub-list {
    opacity: 1;
    pointer-events: auto;
    transform: translateX(100%);
  }
}

.m-category-item:hover .m-category-item__dropdown {
  transform: translateY(.25rem);
}

@media (min-width: 75em) {
  .m-category-item:hover .m-category-item__dropdown {
    transform: translateX(.25rem);
  }
}

@media (max-width: 74.999em) {
  .m-category-item.--open .m-category-sub-list {
    display: flex;
  }
}

.m-category-sub-item__link {
  color: #292934;
  padding-block: .3125rem;
  text-decoration: none;
  display: block;
}

.m-category-sub-item__link:hover {
  color: #292934;
  background-color: #fafafa;
}

@media (max-width: 74.999em) {
  .m-category-sub-item__link {
    padding-block: .5625rem;
  }
}

.m-category-sub-list {
  transition: opacity .3s, transform .3s;
}

.m-category-sub-list:before {
  content: "";
  height: 100%;
  width: .1875rem;
  background-color: #ececec;
  display: block;
  position: absolute;
  top: 0;
  left: .75rem;
}

@media (min-width: 75em) {
  .m-category-sub-list {
    z-index: 5;
    width: 100%;
    opacity: 0;
    pointer-events: none;
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(calc(100% - 1rem));
  }
}

@media (max-width: 74.999em) {
  .m-category {
    display: none;
  }
}

.m-category:after {
  content: "";
  width: 100%;
  height: calc(100% + .125rem);
  z-index: 2;
  pointer-events: none;
  opacity: 0;
  background-color: #fafafa;
  border: .0625rem solid #ececec;
  transition: opacity .3s, transform .3s;
  display: block;
  position: absolute;
  top: -.0625rem;
  right: 0;
  transform: scaleX(.85)translateX(100%);
}

.m-category.--active:after {
  opacity: .97;
  transform: scaleX(1)translateX(100%);
}

.c-modal__dialog {
  width: auto;
  pointer-events: none;
  cursor: cell;
  margin: 2rem auto;
  position: relative;
}

.c-modal__content {
  width: min(100% - 2rem, 57.5rem);
  pointer-events: auto;
  cursor: auto;
  transition: transform var(--transtion-length) ease;
  background-color: #fff;
  background-clip: padding-box;
  border: .0625rem solid #ececec;
  outline: 0;
  margin-inline: auto;
  position: relative;
  transform: translateY(2rem);
  box-shadow: .25rem .25rem 1.5rem #0f26391a;
}

.c-modal__content.u-gray-box {
  --background: #fff;
}

.c-modal__body {
  flex: auto;
  padding: 2rem 1.5rem;
  position: relative;
}

.c-modal__header {
  padding: 1.5rem 1.5rem 0;
  flex-shrink: 0;
  align-items: center;
  gap: 1rem;
  padding-right: 4.5rem;
  display: flex;
}

.c-modal__close-cross {
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  top: 1.25rem;
  right: 1.25rem;
}

.c-modal__close-cross svg {
  width: 1rem;
  height: 1rem;
  z-index: 2;
}

.c-modal__close-cross:before {
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  background-color: #ececec;
  border-radius: 50%;
  transition: opacity .3s;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.c-modal__close-cross:hover:before {
  opacity: 1;
}

.c-modal__footer {
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  gap: 1rem;
  padding: 0 1.5rem 2rem;
  display: flex;
}

.c-modal__footer.--right {
  justify-content: flex-end;
}

.c-modal__footer.--center {
  justify-content: center;
}

.c-modal__footer.--between {
  justify-content: space-between;
}

.c-modal__heading {
  font-size: 1.375rem;
}

.c-modal__inner {
  flex-direction: column;
  display: flex;
}

.c-modal {
  --transtion-length: $ transitionLength;
  z-index: 9000;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  cursor: pointer;
  transition: opacity var(--transtion-length) ease;
  background-color: #34343ef2;
  outline: 0;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.c-modal.--open {
  opacity: 1;
  pointer-events: all;
  visibility: visible;
}

.c-modal.--open .c-modal__content {
  transform: translateY(0);
}

.c-modal.--hidden {
  visibility: hidden;
}

.c-modal.--size-sm .c-modal__content {
  width: min(100% - 2rem, 30rem);
}

.c-modal.--size-xl .c-modal__content {
  width: min(100% - 2rem, 71.25rem);
}

.c-modal.--height-stretch .c-modal__dialog {
  height: calc(100% - 4rem);
}

.c-modal.--height-stretch .c-modal__content, .c-modal.--height-stretch .c-modal__inner {
  height: 100%;
}

.c-overlay {
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
  opacity: 0;
  cursor: pointer;
  background-color: #292934;
  transition: opacity .3s;
  position: fixed;
  inset: 0;
}

.c-overlay.--show {
  opacity: .75;
  pointer-events: all;
}

.c-pagination__bar {
  --bar-width: 0;
  height: .25rem;
  width: 8rem;
  background-color: #a9a9ae;
}

.c-pagination__bar:after {
  content: "";
  height: 100%;
  width: 100%;
  transform-origin: 0;
  transform: scaleX(var(--bar-width));
  will-change: trasnform;
  background-color: #292934;
  transition: transform .3s;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.c-pagination__divider:before {
  content: "/";
}

.c-pagination__fraction {
  color: #a9a9ae;
  gap: .25rem;
}

.c-pagination__current {
  color: #292934;
}

.pd-content {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.pd-content__price-group {
  row-gap: 1rem;
}

.pd-content__buttons {
  border-top: .0625rem solid #ececec;
  padding-top: 1rem;
}

@media (max-width: 35.999em) {
  .pd-content__buttons-group.g-base {
    flex-direction: column;
    display: flex;
  }

  .pd-content__buttons-group.g-base .c-counter {
    max-width: 100%;
    width: auto;
  }

  .pd-content__buttons-group.g-base .btn.--outline {
    min-width: auto;
    width: 100%;
    max-width: 100%;
  }

  .pd-content__buttons-group.g-base .btn.--primary {
    grid-column: span 2;
  }

  .pd-content__buttons-group.g-base.--full {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }
}

@media (min-width: 36em) {
  .pd-content__links-group.g-base {
    padding: 0 1.5rem;
  }
}

@media (min-width: 62em) and (max-width: 93.749em) {
  .pd-content__links-group.g-base {
    column-gap: 1rem;
    padding: 0;
  }
}

@media (max-width: 35.999em) {
  .pd-content__links-group.g-base {
    flex-direction: column;
  }
}

@media (min-width: 62em) {
  .pd-description {
    padding-left: 2rem;
  }
}

.pd-description__header {
  padding: 1.5rem 0;
}

.pd-description__body {
  max-width: 70ch;
}

.pd-gallery, .pd-gallery__body {
  flex-direction: column;
  display: flex;
  position: relative;
  overflow: hidden;
}

.pd-gallery__hero-slider {
  max-width: 42rem;
}

@media (min-width: 48em) {
  .pd-gallery__hero-slider {
    margin-left: 7rem;
  }

  .pd-gallery__thumbnails {
    width: 7rem;
    height: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

@media (max-width: 47.999em) {
  .pd-gallery__thumbnails {
    order: 2;
  }
}

.g-heros {
  width: 100%;
}

.g-thumbnails {
  width: 100%;
  max-height: 100%;
}

@media (max-width: 47.999em) {
  .g-thumbnails .swiper-slide {
    width: 7rem;
  }
}

.g-thumbnails .swiper-slide .u-product-image {
  will-change: outline-color;
  transition: outline-color .3s;
}

.g-thumbnails .swiper-buttons {
  z-index: 2;
  pointer-events: none;
  justify-content: space-between;
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@media (min-width: 48em) {
  .g-thumbnails .swiper-buttons {
    height: 100%;
    flex-direction: column;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: 47.999em) {
  .g-thumbnails .swiper-buttons {
    width: 100%;
  }
}

.g-thumbnails .swiper-button {
  pointer-events: auto;
  transition: opacity .3s;
}

.g-thumbnails .swiper-button.swiper-button-disabled {
  opacity: 0;
}

.g-thumbnails .swiper-button-prev {
  transform: translateY(-.0625rem)rotate3d(0, 0, 1, 90deg);
}

.g-thumbnails .swiper-button-prev.--hidden {
  opacity: 0;
  pointer-events: none;
}

@media (min-width: 48em) {
  .g-thumbnails .swiper-button-prev {
    rotate: 90deg;
  }
}

.g-thumbnails .swiper-button-next {
  transform: translateY(-.0625rem);
}

@media (max-width: 47.999em) {
  .g-thumbnails .swiper-button-next {
    rotate: -90deg;
  }
}

.g-thumbnails .swiper-slide-thumb-active .u-product-image {
  outline-color: #292934;
}

.pd-info-box {
  border-top: .0625rem solid #ececec;
  border-bottom: .0625rem solid #ececec;
}

.pd-info-box__body {
  gap: .875rem;
}

@media (max-width: 35.999em) {
  .pd-info-box__body {
    padding: 1.5rem .5rem;
  }
}

.pd-i-hero {
  border: .0625rem solid #ececec;
  outline: none;
}

.pd-i-info__label {
  flex: 0 0 10rem;
}

@media (max-width: 35.999em) {
  .pd-i-info__label {
    flex: 0 0 6rem;
    gap: .5rem;
  }
}

@media (max-width: 22.438em) {
  .pd-i-info__label {
    flex: 0 0 5.5rem;
  }
}

.pd-i-info__label:after {
  content: ":";
}

.pd-i-thumbnail {
  cursor: pointer;
  border: .0625rem solid #ececec;
  border-top: 0;
  padding: 1rem;
}

.pd-i-thumbnail:first-of-type {
  border-top: .0625rem solid #ececec;
}

.pd-parameters.u-gray-box {
  --background: #fff;
}

.pd-parameters__header {
  padding: 1.5rem 2rem;
}

.pd-parameters__body {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

@media (max-width: 35.999em) {
  .pd-parameters__body {
    overflow: visible;
  }
}

.pd-parameters__table {
  width: 100%;
  border-collapse: collapse;
}

@media (min-width: 36em) {
  .pd-parameters__table {
    table-layout: auto;
  }
}

.pd-parameters__table td {
  padding: .75rem 0;
}

.pd-parameters__table td:nth-child(2) {
  padding-right: 2rem;
}

.pd-parameters__table td:first-child {
  width: 13rem;
  padding-left: 2rem;
}

.pd-parameters__table td:first-child:after {
  content: ":";
}

.pd-parameters__table tr:nth-of-type(odd) {
  background-color: #fafafa;
  border-top: .0625rem solid #ececec;
  border-bottom: .0625rem solid #ececec;
}

.pd-layout__top {
  grid-template-columns: 7fr 5fr;
  gap: 2rem;
  display: grid;
}

@media (min-width: 75em) {
  .pd-layout__top {
    gap: 3rem;
  }
}

@media (max-width: 74.999em) {
  .pd-layout__top {
    grid-template-columns: 6fr 6fr;
  }
}

@media (max-width: 61.999em) {
  .pd-layout__top {
    flex-direction: column;
    display: flex;
  }
}

.pd-layout__bottom {
  gap: 2rem;
  display: grid;
}

@media (min-width: 62em) {
  .pd-layout__bottom {
    grid-template-columns: repeat(2, 1fr);
  }
}

.pd-layout {
  padding-bottom: 3.5rem;
}

.pd-layout__container {
  flex-direction: column;
  gap: 7rem;
  display: flex;
}

.pd-set-products__header {
  padding: 1.5rem 2rem;
}

.pd-set-products__header.u-gray-box {
  background-color: #fff;
}

.scroll-up {
  width: 3rem;
  height: 3rem;
  background-color: var(--c-primary);
  z-index: 50;
  will-change: opacity, transform, background-color;
  pointer-events: none;
  opacity: 0;
  cursor: pointer;
  border-radius: 0;
  justify-content: center;
  align-items: center;
  transition: background-color .3s, opacity .3s cubic-bezier(.68, -.55, .27, 1.55), transform .3s cubic-bezier(.68, -.55, .27, 1.55);
  display: flex;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  transform: translateY(2rem)scale(.8);
}

@media (min-width: 36em) {
  .scroll-up {
    display: none;
  }
}

.scroll-up:after {
  content: "";
  width: 100%;
  height: 100%;
  opacity: 0;
  border-radius: 0;
  transition: opacity .5s, box-shadow .5s;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 .625rem 2.5rem #fff;
}

.scroll-up:active:after {
  opacity: 1;
  transition: all;
  position: absolute;
  box-shadow: 0 0 #fff;
}

.scroll-up svg {
  width: 1rem;
  height: 1rem;
}

.scroll-up path {
  fill: #fff;
}

.scroll-up.--active {
  opacity: 1;
  pointer-events: all;
  transform: translateY(0)scale(1);
}

.s-breadcrumbs {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.s-breadcrumbs.--top-0, .s-breadcrumbs.--bottom-0 {
  margin-top: 0;
}

.s-breadcrumbs.--y-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.s-cart__aside-layout {
  grid-template-columns: 2fr 1fr;
  gap: 2rem;
  display: grid;
}

@media (max-width: 74.999em) {
  .s-cart__aside-layout {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 61.999em) {
  .s-cart__aside-layout {
    flex-direction: column;
    display: flex;
  }
}

.s-cart__aside-layout .n-cart {
  grid-column-start: 2;
}

.s-cart {
  --empty-display: none;
  --content-display: flex;
}

.s-cart.--empty {
  --empty-display: block;
  --content-display: none;
}

.s-cart__content {
  display: var(--content-display);
}

.s-cart__empty {
  display: var(--empty-display);
}

.s-contact-form__row {
  justify-content: center;
}

.s-contact__grid {
  grid-template-columns: 7fr 5fr;
  gap: 2rem;
  display: grid;
}

@media (max-width: 61.999em) {
  .s-contact__grid {
    grid-template-columns: 1fr;
  }
}

.s-contact__image, .s-contact__image .img {
  outline: .0625rem solid #ececec;
}

.s-contact__image svg {
  width: 3rem;
  height: 3rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

@media (max-width: 61.999em) {
  .s-contact__image {
    max-width: 33.75rem;
    margin-inline: auto;
  }
}

.s-dropdown-product__header {
  padding-bottom: 1.5rem;
  position: relative;
}

.s-dropdown-product__header:after {
  content: "";
  width: calc(100% + 2 * var(--offset));
  height: .0625rem;
  transform: translateX(calc(-1 * var(--offset)));
  background-color: #ececec;
  display: block;
  position: absolute;
  bottom: .75rem;
  left: 0;
}

.s-dropdown-product__heading {
  font-family: Noto Serif, serif;
  font-size: 1rem;
  font-weight: bold;
}

.s-dropdown-product__buttons {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
  font-size: .875rem;
  font-weight: bold;
  display: flex;
}

.s-dropdown-product__empty {
  font-size: .875rem;
  display: none;
}

.s-dropdown-product {
  --offset: 1rem;
  padding: var(--offset);
  color: #292934;
  position: relative;
  transform: translateY(.5rem);
  box-shadow: 0 0 2.5rem #2929340d;
}

.s-dropdown-product.u-gray-box {
  --background: #fff;
}

.s-dropdown-product:after {
  content: "";
  width: 0;
  height: 0;
  border: .5rem solid #0000;
  border-top-width: 0;
  border-bottom-color: #ececec;
  display: block;
  position: absolute;
  bottom: 100%;
  right: 1.1875rem;
}

.s-dropdown-product.--empty .s-dropdown-product__empty {
  display: block;
}

.s-dropdown-product.--empty .s-dropdown-product__buttons {
  display: none;
}

.s-dropdown-product__content {
  max-height: 11.25rem;
}

.s-error-404 {
  text-align: center;
}

.s-error-404__perex {
  max-width: 37rem;
  margin-inline: auto;
  margin-bottom: 0;
}

.s-error-404__sub-heading {
  max-width: 45rem;
  margin-inline: auto;
  margin-bottom: 2rem;
}

.s-error-404__heading {
  color: var(--c-primary);
  font-size: 4.5rem;
}

@media (min-width: 48em) {
  .s-error-404__heading {
    font-size: 6.5rem;
  }
}

.s-filters__footer {
  align-items: center;
}

@media (max-width: 35.999em) {
  .s-filters__footer {
    flex-direction: column;
  }
}

.s-filters__footer-line {
  height: .0625rem;
  width: 100%;
  background-color: #ececec;
  flex-grow: 1;
}

.s-filters {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.s-filters__body {
  border-top: .0625rem solid #ececec;
  padding-top: 2rem;
}

.s-filters__body.--hidden {
  display: none;
}

@media (max-width: 35.999em) {
  .s-filters__header {
    flex-direction: column;
  }
}

.s-gifts {
  --remaining-display: none;
  --reached-display: block;
}

.s-gifts.--disabled {
  --remaining-display: block;
  --reached-display: none;
}

.s-gifts__heading.--remaining {
  display: var(--remaining-display);
}

.s-gifts__heading.--reached {
  display: var(--reached-display);
}

.s-grader__empty-note {
  display: none;
}

.s-grader.--empty .s-grader__empty-note {
  display: block;
}

.i-intro__grid {
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;
  display: flex;
}

@media (min-width: 75em) {
  .i-intro__grid {
    grid-template-columns: repeat(12, 1fr);
    display: grid;
  }
}

.i-intro__content {
  flex-direction: column;
  gap: 3rem;
  display: flex;
}

@media (min-width: 75em) {
  .i-intro__content {
    grid-column: auto / span 9;
  }
}

.i-intro__aside {
  order: -1;
}

@media (min-width: 75em) {
  .i-intro__aside {
    grid-column: auto / span 3;
  }
}

.s-post-detail__meta {
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem 2rem;
  display: flex;
}

.s-post-detail__entry-content {
  margin-top: 2rem;
}

.s-seo__grid {
  border-top: .0625rem solid #ececec;
  border-bottom: .0625rem solid #ececec;
  gap: 2rem 3.5rem;
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

@media (max-width: 61.999em) {
  .s-seo__grid {
    align-items: initial;
    flex-direction: column;
  }
}

.s-seo__content-full {
  display: none;
}

.s-seo__button .btn__icon {
  position: absolute;
  top: calc(50% + .875rem);
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.s-seo__button .btn__icon svg {
  width: .5625rem;
}

.s-seo {
  padding-top: 0;
}

.s-seo.--active .s-seo__content-short {
  display: none;
}

.s-seo.--active .s-seo__content-full {
  display: block;
}

.s-seo.--active .s-seo__button svg {
  scale: -1;
}

.s-showroom__main {
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 2rem;
  display: grid;
}

@media (max-width: 61.999em) {
  .s-showroom__main {
    grid-template-columns: 1fr;
  }
}

.s-showroom__left .img {
  max-width: 41rem;
}

@media (min-width: 62em) and (max-width: 93.749em) {
  .s-showroom__heading {
    font-size: 2rem;
  }
}

@media (min-width: 62em) {
  .s-showroom__header .base-header__paragraph {
    -webkit-line-clamp: 5;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }
}

@media (min-width: 62em) and (max-width: 93.749em) {
  .s-showroom__header .base-header__paragraph {
    -webkit-line-clamp: 5;
  }
}

@media (min-width: 62em) and (max-width: 74.999em) {
  .s-showroom__header .base-header__paragraph {
    -webkit-line-clamp: 2;
  }
}

@media (max-width: 93.749em) {
  .s-showroom__header {
    margin-bottom: 0;
  }
}

.language-select__active {
  color: #fff;
  min-height: 2.5rem;
  justify-content: center;
  align-items: center;
  gap: .25rem;
  display: flex;
  position: relative;
}

@media (max-width: 74.999em) {
  .language-select__active {
    background-color: #292934;
  }
}

.language-select__drowpdown {
  width: 100%;
  background-color: #292934;
  flex-direction: column;
  align-items: center;
  padding-bottom: .5rem;
  transition: opacity .3s;
  display: flex;
}

@media (min-width: 75em) {
  .language-select__drowpdown {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 0;
  }
}

.language-select__dropdown-link {
  color: #a9a9ae;
  padding: .25rem 0;
  text-decoration: none;
  transition: color .3s;
}

.language-select__dropdown-link:hover {
  color: #fff;
}

.language-select {
  min-width: 4rem;
  z-index: 10;
  position: relative;
}

@media (min-width: 75em) {
  .language-select.--has-items:hover .language-select__drowpdown {
    opacity: 1;
    pointer-events: auto;
  }
}

.language-select.--has-items:hover .language-select__active {
  background-color: #292934;
}

.se-multi-checkbox__header {
  min-height: 3rem;
  -webkit-user-select: none;
  user-select: none;
  background-color: var(--header-background);
  border: .0625rem solid #ececec;
  align-items: center;
  padding: .4375rem .5rem;
}

.se-multi-checkbox__clear {
  cursor: pointer;
  display: none;
}

.se-multi-checkbox__clear.--active {
  display: flex;
}

.se-multi-checkbox__toggler {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  transition: background-color .3s;
  display: flex;
}

.se-multi-checkbox__toggler svg {
  width: .875rem;
  height: .875rem;
}

.se-multi-checkbox__toggler:hover {
  background-color: #ececec;
}

.se-multi-checkbox__toggler.--active svg {
  scale: -1;
}

.se-multi-checkbox__label {
  --end: 0;
  height: 100%;
  height: 2rem;
  cursor: pointer;
  flex: 1;
  margin-left: .5rem;
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
}

.se-multi-checkbox__label > span {
  white-space: nowrap;
  transition: left 2s linear;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.se-multi-checkbox__label:after {
  content: "";
  pointer-events: none;
  width: 1rem;
  height: 100%;
  background: transparent linear-gradient(270deg, var(--header-background) 0%, #fff0 100%) 0% 0% no-repeat padding-box;
  z-index: 1;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

.se-multi-checkbox__label:hover > span {
  left: var(--end);
}

.se-multi-checkbox__body {
  transform-origin: top;
  width: calc(100% - .0625rem);
  z-index: 5;
  opacity: 0;
  pointer-events: none;
  will-change: opacity, transform;
  background-color: #fff;
  border: .0625rem solid #ececec;
  transition: opacity .3s, transform .3s;
  top: calc(100% - .0625rem);
  left: 50%;
  transform: translateX(-50%)scaleY(.25);
}

.se-multi-checkbox__body-inner {
  max-height: 10rem;
  padding-bottom: 1rem;
}

.se-multi-checkbox {
  --header-background: #fff;
  min-width: 0;
}

.se-multi-checkbox.--active {
  --header-background: #fafafa;
}

.se-multi-checkbox.--active .se-multi-checkbox__body {
  opacity: 1;
  pointer-events: auto;
  transform: translateX(-50%)scaleY(1);
}

.se-single__select {
  font-weight: 700;
}

.se-single__select.slim-select {
  --ss-primary-color: var(--c-primary);
  --ss-bg-color: #fff;
  --ss-font-color: #292934;
  --ss-border-color: #ececec;
  --ss-success-color: #51c87c;
  --ss-error-color: #f93a3a;
  --ss-main-height: 4rem;
  --ss-content-height: 18.75rem;
  --ss-animation-timing: .3s;
  --ss-border-radius: 0;
}

.se-single__select.slim-select .ss-list .ss-option {
  padding: .5rem 1.5rem;
}

.se-single__select.slim-select.ss-open-above, .se-single__select.slim-select.ss-open-below {
  --ss-bg-color: #fafafa;
}

.se-single__select.slim-select.ss-content {
  border-top: 0;
}

.se-single__select.slim-select.ss-main {
  padding: 0 1.5rem;
  font-weight: 700;
}

.se-single__select.slim-select.ss-main .ss-arrow {
  width: .875rem;
  margin: auto 0;
}

.se-single__select.slim-select.ss-main:focus {
  box-shadow: none;
}

.se-single.--native {
  grid-template-areas: "select";
  align-items: center;
  display: grid;
}

.se-single.--native:after {
  content: "";
  width: .875rem;
  height: .5rem;
  background-image: url("arrow-down.dfae1854.svg");
  background-repeat: no-repeat;
  background-size: contain;
  grid-area: select;
  justify-self: end;
  margin-right: 1.5rem;
  display: block;
}

.se-single.--native .se-single__select {
  width: 100%;
  min-height: 4rem;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  border: .0625rem solid #ececec;
  outline: none;
  grid-area: select;
  margin: 0;
  padding-left: 1.5rem;
}

.se-slim-single__select.slim-select {
  --ss-primary-color: var(--c-primary);
  --ss-bg-color: #fff;
  --ss-font-color: #292934;
  --ss-border-color: #ececec;
  --ss-success-color: #51c87c;
  --ss-error-color: #f93a3a;
  --ss-main-height: 2rem;
  --ss-content-height: 18.75rem;
  --ss-spacing-l: .4375rem;
  --ss-spacing-m: .3125rem;
  --ss-spacing-s: .1875rem;
  --ss-animation-timing: .3s;
  --ss-border-radius: 0;
  font-size: .875rem;
}

.se-slim-single__select.slim-select.ss-open-above, .se-slim-single__select.slim-select.ss-open-below {
  --ss-bg-color: #fafafa;
}

.se-slim-single__select.slim-select.ss-content {
  width: -moz-fit-content;
  width: fit-content;
  border-top: 0;
  translate: -100%;
}

.se-slim-single__select.slim-select.ss-main {
  border-color: #0000;
  font-weight: 700;
}

.se-slim-single__select.slim-select.ss-main:focus {
  box-shadow: none;
}

.se-slim-single__select.slim-select.ss-main.ss-open-above, .se-slim-single__select.slim-select.ss-main.ss-open-below {
  border-color: var(--ss-border-color);
  border-bottom-color: #0000;
}

.se-slim-single.--native {
  grid-template-areas: "select";
  align-items: center;
  font-size: .875rem;
  display: grid;
}

.se-slim-single.--native:after {
  content: "";
  width: .625rem;
  height: .625rem;
  background-image: url("arrow-down.dfae1854.svg");
  background-repeat: no-repeat;
  background-size: contain;
  grid-area: select;
  justify-self: end;
  display: block;
}

.se-slim-single.--native .se-slim-single__select {
  width: 100%;
  min-height: 2rem;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  border: none;
  outline: none;
  grid-area: select;
  margin: 0;
  padding-right: 1.5rem;
  font-weight: 700;
}

.se-slim-single.--native .se-slim-single__select.slim-select {
  padding-right: 0;
}

.tippy-box[data-animation="fade"][data-state="hidden"] {
  opacity: 0;
}

[data-tippy-root] {
  max-width: calc(100vw - .625rem);
}

.tippy-arrow {
  width: 1rem;
  height: 1rem;
  color: #ececec;
}

.tippy-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.tippy-box {
  white-space: normal;
  background-color: #fff;
  border: .0625rem solid #ececec;
  outline: 0;
  font-size: .875rem;
  transition-property: transform, visibility, opacity;
  position: relative;
  box-shadow: 0 0 2.5rem #2929340d;
}

.tippy-box[data-placement^="top"] > .tippy-arrow {
  bottom: 0;
}

.tippy-box[data-placement^="top"] > .tippy-arrow:before {
  border-width: .5rem .5rem 0;
  border-top-color: initial;
  transform-origin: top;
  bottom: -.5rem;
  left: 0;
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow {
  top: 0;
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
  border-width: 0 .5rem .5rem;
  border-bottom-color: initial;
  transform-origin: bottom;
  top: -.5rem;
  left: 0;
}

.tippy-box[data-placement^="left"] > .tippy-arrow {
  right: 0;
}

.tippy-box[data-placement^="left"] > .tippy-arrow:before {
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: initial;
  transform-origin: 0;
  right: -.5rem;
}

.tippy-box[data-placement^="right"] > .tippy-arrow {
  left: 0;
}

.tippy-box[data-placement^="right"] > .tippy-arrow:before {
  border-width: .5rem .5rem .5rem 0;
  border-right-color: initial;
  transform-origin: 100%;
  left: -.5rem;
}

.tippy-box[data-inertia][data-state="visible"] {
  transition-timing-function: cubic-bezier(.54, 1.5, .38, 1.11);
}

.tippy-content {
  padding: 1rem;
}

.w-navigation__link {
  border-bottom: .0625rem solid #ececec;
}

.w-navigation__link:last-of-type {
  border-bottom: none;
}

.w-widgets__navigation-item {
  transition-property: background-color, text-decoration-color;
}

.w-widgets__navigation-item:hover {
  background-color: #ececec;
}

.w-widgets__navigation-item.--active:before {
  content: "";
  height: 100%;
  width: .125rem;
  background-color: var(--c-primary);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.entry-content {
  text-align: left;
  max-width: 85ch;
  padding: 0;
}

.entry-content strong:empty, .entry-content b:empty, .entry-content p:empty {
  display: none;
}

.entry-content hr {
  height: .0625rem;
  background: #fafafa;
  border: none;
  margin: 3rem 0;
  transform: translateY(.375rem);
}

.entry-content .wp-caption {
  text-align: center;
  max-width: 100%;
  border: 0;
  padding: 0;
}

.entry-content .wp-caption-text {
  color: #292934;
  text-align: center;
  background: #ebebeb63;
  margin-bottom: 0;
  margin-left: 0;
  padding: .25rem .625rem;
  font-size: 1rem;
  line-height: 1.5rem;
  transform: none;
}

.entry-content blockquote {
  border: .0625rem solid #ececec;
  margin: 1.5rem 0;
  padding: 1.5rem;
  font-size: 1rem;
  font-style: italic;
  font-weight: 400;
  position: relative;
}

.entry-content blockquote p {
  margin-bottom: 0;
  font-style: italic;
}

@media (max-width: 35.999em) {
  .entry-content {
    margin-top: 1rem;
  }
}

.entry-content h1, .entry-content h2, .entry-content h3, .entry-content h4, .entry-content h5, .entry-content h6 {
  --font-family: Noto Serif, serif;
  color: #292934;
  font-family: var(--font-family);
  margin-top: 2.5rem;
  margin-bottom: .5rem;
  font-weight: bold;
}

@media (max-width: 35.999em) {
  .entry-content h1, .entry-content h2, .entry-content h3, .entry-content h4, .entry-content h5, .entry-content h6 {
    margin-bottom: 1.5rem !important;
  }
}

.entry-content h1 {
  margin-top: 0;
  font-size: 2.5rem;
  line-height: 3rem;
  position: relative;
}

.entry-content h2 {
  font-size: 2.5rem;
  line-height: 3rem;
  position: relative;
}

.entry-content h3 {
  font-size: 2rem;
  line-height: 2.5rem;
}

.entry-content h4 {
  font-size: 1.5rem;
  line-height: 2rem;
}

.entry-content h5 {
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.entry-content > h2:first-child, .entry-content > h3:first-child {
  margin-top: 0;
}

.entry-content img {
  max-width: 100%;
  margin-block: 1rem;
}

.entry-content ol > li {
  counter-increment: item;
  padding-left: 2.1875rem;
  position: relative;
}

.entry-content ol > li:before {
  content: counter(item) ".";
  width: 1.75rem;
  height: 1.75rem;
  text-align: center;
  color: #292934;
  border-radius: 100%;
  margin-left: -2.1875rem;
  margin-right: .5rem;
  padding: .0625rem .25rem .0625rem .375rem;
  font-size: 1.125rem;
  font-weight: 700;
  display: inline-block;
  position: relative;
  top: .0625rem;
}

.entry-content ol > li ol > li {
  counter-increment: sub-item;
}

.entry-content ol > li ol > li:before {
  content: counter(sub-item) ".";
}

.entry-content ol > li ol > li ol > li {
  counter-increment: sub-sub-item;
}

.entry-content ol > li ol > li ol > li:before {
  content: counter(sub-sub-item) ".";
}

.entry-content ol[style*="list-style-type: lower-alpha"] li:before {
  content: counter(item, lower-alpha) ")";
}

.entry-content ol[style*="list-style-type: upper-alpha"] li:before {
  content: counter(item, upper-alpha) ")";
}

.entry-content ol[style*="list-style-type: lower-latin"] li:before {
  content: counter(item, lower-latin) ")";
}

.entry-content ol[style*="list-style-type: upper-latin"] li:before {
  content: counter(item, upper-latin) ")";
}

.entry-content ol[style*="list-style-type: lower-greek"] li:before {
  content: counter(item, lower-greek) ")";
}

.entry-content ol[style*="list-style-type: upper-greek"] li:before {
  content: counter(item, upper-greek) ")";
}

.entry-content ol[style*="list-style-type: lower-roman"] li:before {
  content: counter(item, lower-roman) ")";
}

.entry-content ol[style*="list-style-type: upper-roman"] li:before {
  content: counter(item, upper-roman) ")";
}

.entry-content ul > li {
  padding-left: 1.25rem;
}

.entry-content ul > li:before {
  content: "";
  width: .5rem;
  height: .5rem;
  background-color: #292934;
  border-radius: 100%;
  margin-left: -1rem;
  margin-right: .625rem;
  display: inline-block;
  position: relative;
  top: 50%;
  left: 0;
  transform: translateY(-.125rem);
}

.entry-content ul, .entry-content ol {
  color: #292934;
  margin: 0;
  padding: 0;
  margin-bottom: 1.5rem !important;
  list-style: none !important;
}

.entry-content ul ul, .entry-content ul ol, .entry-content ol ul, .entry-content ol ol {
  margin-top: .5rem;
  margin-bottom: 0 !important;
}

.entry-content ul li, .entry-content ol li {
  margin-bottom: .5rem;
}

.entry-content p {
  color: #292934;
  margin-bottom: 1.5rem;
  line-height: 1.5rem;
}

.entry-content .table-responsive {
  margin: 1rem 0;
  overflow: auto;
  box-shadow: 0 0 0 .0625rem #fafafa;
}

.entry-content th, .entry-content td, .entry-content tr {
  border: .0625rem solid #ececec;
}

.entry-content td, .entry-content th {
  text-align: center;
  padding: 1rem;
  position: relative;
}

.entry-content thead {
  color: #292934;
  font-family: Noto Serif, serif;
  font-size: 1.125rem;
  font-weight: bold;
}

.entry-content tbody {
  color: #292934;
  background-color: #fff;
  font-size: .875rem;
}

.entry-content tbody tr:nth-child(odd) {
  background-color: #fafafa;
}

.entry-content tbody td, .entry-content tbody th {
  padding: .5rem;
}

.entry-content tbody td:before, .entry-content tbody th:before {
  content: "";
  height: 100%;
  width: .0625rem;
  background: #fafafa;
  position: absolute;
  top: 0;
  right: 0;
}

.entry-content tbody td:last-child:before, .entry-content tbody th:last-child:before {
  display: none;
}

.entry-content table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 0 !important;
}

.entry-content table p {
  font-size: 1rem;
  transform: none;
  margin-bottom: 0 !important;
}

.g-search-results {
  flex-direction: column;
  gap: 2rem;
  padding-top: 5rem;
  display: flex;
}

.i-search-result {
  max-width: 80ch;
  flex-direction: column;
  display: flex;
  position: relative;
}

.i-search-result__heading {
  margin-bottom: 1rem;
}

.i-search-result__heading-link {
  text-decoration-color: #0000;
}

.i-search-result__heading-link:hover {
  -webkit-text-decoration-color: var(--c-primary);
  text-decoration-color: var(--c-primary);
}

.i-search-result__description {
  color: #292934;
  font-size: 1.25rem;
}

.i-search-result__link {
  margin-bottom: .5rem;
  text-decoration-color: #0000;
  transition-property: color, text-decoration-color;
}

.i-search-result__link:hover {
  color: var(--c-primary);
  -webkit-text-decoration-color: var(--c-primary);
  text-decoration-color: var(--c-primary);
}

.search-form__wrap {
  max-width: 80ch;
  align-items: center;
  gap: 1rem 2rem;
  display: flex;
  position: relative;
}

@media (max-width: 61.999em) {
  .search-form__wrap {
    flex-wrap: wrap;
  }
}

.search-form__heading {
  flex-shrink: 0;
}

.search-form {
  background-color: #fff;
  flex-grow: 1;
  display: flex;
  position: relative;
  box-shadow: inset 0 0 0 .0625rem #fafafa;
}

.search-form__input {
  color: #292934;
  background: none;
  border: 0;
  outline: 0;
  flex-grow: 1;
  padding: 0 1rem;
}

@media (max-width: 35.999em) {
  .search-form__input {
    min-width: 0;
  }
}

.search-form__submit {
  height: 3rem;
  width: 3rem;
  cursor: pointer;
  background-color: #0000;
  border: none;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  transition: background-color .2s;
  display: flex;
}

.search-form__submit svg {
  width: 1.125rem;
  height: 1.125rem;
  fill: var(--c-primary);
  transition: fill .2s;
}

.search-form__submit:hover {
  background-color: var(--c-primary);
}

.search-form__submit:hover svg {
  fill: #fff;
}

.search-form__submit:active, .search-form__submit:focus {
  outline: 0;
}

/*# sourceMappingURL=index.css.map */
